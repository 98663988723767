import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Homepage from "./Pages/Homepage";
import Launchpadpage from "./Pages/Launchpadpage";
import Stakingpage from "./Pages/Stakingpage";
import Allpools from "./Pages/allpoolstable";
import Profile from "./Pages/profilepage";
import Mytier from "./Pages/mytier";
import Cardinfo from "./Pages/cardinfo";
import Mypools from "./Pages/mypools"
import Tokenrequest from "./Pages/tokenrequest";
import Privacy from "./Pages/privacy";
import Terms from "./Pages/terms";
import Infoedit from "./Pages/infoedit";
import NativeCardinfo from "./Pages/nativecardinfo";

function App() {
  return (

    <>

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/launchpad" element={<Launchpadpage />} />
        <Route path="/staking" element={<Stakingpage />} />
        <Route path="/allpools" element={<Allpools />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/mytier" element={<Mytier />} />
        <Route path="/mypools" element={<Mypools />} />
        <Route path="/info" element={<Cardinfo />} />
        <Route path="/info/:id" element={<Cardinfo />} />
        <Route path="/infoedit/:id/:id" element={<Infoedit />} />
        <Route path="/tokenrequest" element={<Tokenrequest />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/native-card-info/:id/:id/"  element={<NativeCardinfo/>}/>
        <Route path="*" element={<Navigate replace to={"/"} />} />
       
      </Routes>
    </BrowserRouter>

  </>
  );
}

export default App;

// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import Web3 from "web3";

import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from "./UseWeb3";
import toast, { Toaster } from 'react-hot-toast';
import { createuserhook, getuserdatahook } from "./useBackend";
import { iconTheme, position, style } from "./useToast";


export const MetamaskWallet = async (e) => {

    try {
        if (window.ethereum) {
      console.log("window.ethereum",window.ethereum);
            const web3 = new Web3(window.ethereum);
            try {
                await window.ethereum.enable().then(async () => {
                    const accounts = await web3.eth.getAccounts();
                    console.log("accounts", accounts);
                    const account = accounts[0].toString();
                    console.log("accountInfo", account);
                    localStorage.setItem("accountInfo", account)
                    let user = await createuserhook(account.toLowerCase())
                    console.log("user", user);
                    return web3;
                });
            } catch (e) {
                console.log("error", e);
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
      console.log("window.web3",window.web3);

            const web3 = new Web3(window.web3.currentProvider);
            const chainId = await web3.eth.getChainId();

            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString();
            localStorage.setItem("accountInfo", account)
            let user = await createuserhook(account.toLowerCase())
            console.log("user", user);
            await getuserdata(account)

            return web3;
        }
        // Non-DApp Browsers
        else {
            //alert('No Dapp Supported Wallet Found');

            toast.error(`No Dapp Supported Wallet Found !`,
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
    } catch (e) {
        toast.error(`Error : ${e}`,
            {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    }


}

export const BinanceWallet = async (e) => {


    try {
        if (window.BinanceChain) {
      console.log("window.BinanceChain",window.BinanceChain);

            const web3 = new Web3(window.BinanceChain);


            try {


                await window.BinanceChain.enable().then(async () => {

                    const accounts = await web3.eth.getAccounts();
                    const account = accounts[0].toString();

                    localStorage.setItem("accountInfo", account)
                    let user = await createuserhook(account.toLowerCase())
                    console.log("user", user);
                    await getuserdata(account)
                    return web3;
                });
            } catch (e) {
                console.log("error", e);

                // User has denied account access to DApp...
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            console.log("window.web3",window.web3);

            const web3 = new Web3(window.web3.currentProvider);
            const chainId = await web3.eth.getChainId();

            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString();
            localStorage.setItem("accountInfo", account)
            let user = await createuserhook(account.toLowerCase())
            console.log("user", user);
            await getuserdata(account)
            return web3;
        }
        // Non-DApp Browsers
        else {
            //alert('No Dapp Supported Wallet Found');

            toast.error(`No Dapp Supported Wallet Found !`,
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
    } catch (e) {
        toast.error(`Error : ${e}`,
            {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    }


}


// export const WalletConnect = async (e) => {
//     try {
//         const RPC_URL = CURRENT_RPC_URL();
//         const CHAIN_ID = (CURRENT_CHAIN_ID());
//         //Create WalletConnect Provider

//         const RPC_DATA = {};
//         RPC_DATA[CHAIN_ID] = RPC_URL
//         const provider = new WalletConnectProvider({
//             rpc: RPC_DATA,
//             network: 'binance',
//             chainId: CHAIN_ID,
//             // infuraId: "69de03b5c7194095980c9233f9cf71df",
//         }
//         );
//         await provider.enable().then(function (error, result) {


//         })
//             .catch(e => {
//                 //try again
//                 toast.error(`Error : ${e}`,
//                     {
//                         style: {
//                             minWidth: '300px',
//                             minHeight: '55px'
//                         }
//                     });
//             });
//         const web3 = new Web3(provider);
//         // const accounts = await web3.eth.getAccounts();
//         const accounts = await provider.request({
//             method: 'eth_requestAccounts',
//           });
//         const account = accounts[0].toString();
//         localStorage.setItem("accountInfo", account)
//         // localStorage.setItem("provider",JSON.stringify(provider))
//         // let user = await createuserhook(account.toLowerCase())
//         // await getuserdata(account)


//         return provider;
//     } catch (error) {
//         console.log("error", error)
//     }

// }
export const WalletConnect = async (e) => {
    try {
        debugger
      const RPC_URL = CURRENT_RPC_URL();
      const CHAIN_ID = (CURRENT_CHAIN_ID());
      //Create WalletConnect Provider
      console.log("Wallet connect");
      const RPC_DATA = {};
      RPC_DATA[CHAIN_ID] = RPC_URL
      const provider = await EthereumProvider.init({
        projectId: "fb3f447fa6416dec832ec196072edd7b", // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      // await provider.enable()
      await provider.connect().then(function (error, result) {
        console.log('error: ' + error);
        console.log("res in walletconnet" , result);
  
      })
        .catch(e => {
          //try again
          toast.error(`Error : ${e}`,
            {
              position: position.position,
              style: style,
              iconTheme: iconTheme
            });
        });
    //   const web3 = new Web3(provider);
      // const accounts = await web3.eth.getAccounts();
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      console.log("Account : ", accounts[0]);
      const account = accounts[0].toString();
  
      localStorage.setItem("accountInfo", accounts[0])
      sessionStorage.setItem("accountInfo", accounts[0])
      console.log("user", account.toLowerCase());
      let user = await createuserhook(account.toLowerCase())
      await getuserdata(account)
      // window.location.href = window.location.origin + "/wallet"
      // localStorage.setItem("provider",JSON.stringify(provider))
  
  
      return provider;
    } catch (err) {
      console.log(err, "WalletConnect__err")
    }
  }
  
const getuserdata = async (account) => {
    console.log("account",account);
    try {
        let userdata = await getuserdatahook(account.toLowerCase());
        console.log("userdata", userdata.data.data);

    } catch (err) {
        console.log(err, "getuserdata__err")
    }
}
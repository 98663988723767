import React, { useState, useEffect } from "react";
import { Accordion, Container, Button, Dropdown } from "react-bootstrap";
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Subheaders from "../Layouts/homesubheader";
import Switch from "react-switch";
import { IoSearchOutline } from "react-icons/io5";
import Accoriontable from "../Components/accordiontable";
//sonia
import { getPoolInfoLimit, getPoolLength, getUser } from "../Hooks/useStake";
import { getAccount } from "../Hooks/useAccounts";
function Stakingpage() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [poolLimit, setPoolLimit] = useState([]);
  console.log("poollimit",poolLimit);
  const [accountInfo, setAccountInfo] = useState(getAccount());
  console.log("accountInfo", accountInfo);

  //dev muruga
  const [poolsize , setPoolsize] = useState(0);
  const [initialloading , setInitialloading] = useState(false);
  const [loading , setLoading] = useState(false);
  const [loader , setLoader] = useState(false)

  useEffect(() => {
    getData();
  },[]);

  const getData = async () => {
    setInitialloading(true);
    let data = await getPoolLength();
    setPoolsize(data);
    console.log("data", data);
    const pool = await getPoolInfoLimit(0, data);
    console.log("pool", pool);
    if (pool) {
      setPoolLimit(pool);
    }

    setInitialloading(false);
  };

  const Loadmore = async() => {
    setLoading(true);
    const pool = await getPoolInfoLimit(poolLimit?.length , parseInt(poolsize) > (poolLimit?.length+10) ? (poolLimit?.length+10) : parseInt(poolsize));
    setPoolLimit(poolLimit?.concat(pool));
    setLoading(false);
  }

  return (
    <div className="all">
      <Header />
      <Subheaders />
      <div className="innerpages pb-5">
        <div className="container">
          <div className="stakingback px-4 px-md-5">
            <div className="row justify-content-center px-xxl-4">
              <div className="col-lg-8 col-xl-6 d-block d-sm-flex align-items-center mt-4">
                <ul className="tab-list">
                  <li
                    className={activeTab === "tab1" ? "active" : "tabtext"}
                    onClick={() => handleTabClick("tab1")}
                  >
                    <span>Live</span>
                  </li>
                  {/* <li
                    className={activeTab === "tab2" ? "active" : "tabtext"}
                    onClick={() => handleTabClick("tab2")}
                  >
                    <span>Finished</span>
                  </li> */}
                </ul>
                {/* <div className="d-flex ms-2 mt-4 mt-sm-0">
                  <Switch
                    onChange={handleChange}
                    checked={checked}
                    offColor="#162019"
                    onColor="#25C852"
                    offHandleColor="#ffff"
                    onHandleColor="#fff"
                    height={20}
                    width={36}
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    className="react-switch"
                    id="material-switch"
                  />
                  <p className="fname ms-2">My Staking Pools</p>
                </div> */}
              </div>

              <div className="col-lg-8 col-xl-6 d-block d-sm-flex align-items-center justify-content-xl-end mt-4">
                {/* <Dropdown className="text-center text-sm-start">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    All Pools
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-3">
                    <Dropdown.Item href="#/action-1">All pools</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      with IDO pools
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      without IDO pools
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="tokensale ms-0 ms-sm-4 mt-4 mt-sm-0">
                  <div className="pos">
                    <IoSearchOutline className="searchicon" />
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search by pool name, token symbol..."
                    />
                  </div>
                </div> */}
              </div>
            </div>
            {initialloading && <div className='d-flex justify-content-center'>
                    <span class="loader"></span>
                    </div>}

            {activeTab === "tab1" && (
              <>
              
              {!initialloading &&  <div className="accord_table mt-5 py-3 px-2 px-xxl-3 mx-xxl-3">
                {/* {poolLimit && poolLimit.map((val, index) =>
                 <Accoriontable />
                )} */}
                                 {/* <Accoriontable  accountInfo={accountInfo} pool={poolLimit}/> */}

                                 <div className='overauto'>
                                
                                  
            <Accordion>
                {!initialloading && poolLimit&&poolLimit.map((e, i) =>
                    // <Accordion.Item eventKey={i + 1}>
                     <Accoriontable   datas={e} pid={i}  accountInfo={accountInfo}/> 
                    // </Accordion.Item>
                )}
            </Accordion>

        </div>

              </div>}
              </>
             
            )}

            {activeTab === "tab2" && <div className="smallheight"></div>}

            {}

            <div></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Stakingpage;

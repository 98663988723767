import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown } from 'react-bootstrap';
import logo from '../images/spropad/logo.png';
import connectbtn from '../images/spropad/connectbtn.png';
import { IoPersonCircleSharp } from "react-icons/io5";
import Walletmodal from '../Components/walletmodal';
import { Link } from 'react-router-dom';
import { MdOutlineMenu } from "react-icons/md";
import { SlMenu } from "react-icons/sl";
import Mobileheader from './mobileheader';
import binance from "../images/spropad/binance.png";
import { CiLogout } from 'react-icons/ci';
//sonia
import { useWeb3 } from "../Hooks/UseWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../Hooks/useWallet';
import { getAccount } from "../Hooks/useAccounts";
import { Getbalance, sleep } from "../Hooks/useContract";
import {style,iconTheme,position} from '../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import { UseProvider } from '../Hooks/UseWeb3';
import { GetTotalPoints, getUserBalance } from '../Hooks/useStake';
import { GetAllocation } from '../Hooks/UseBackenduser';
function Header() {

    const [walletmodal, setWalletmodal] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobheader, setMobheader] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown1, setShowDropdown1] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

//sonia
const [accountInfo, setAccountInfo] = useState(getAccount());
const [balance , setBalance] = useState(0);
const [userpoint , setUserpoint] = useState(0);
const [allocation , setAllocation] = useState()
const [usertier , setUsertier] = useState();

useEffect(() => {
    getbal();
    fetchdata();
} , [])

const getbal = async() => {
    let bal = await Getbalance()
    setBalance(bal)
}

const fetchdata = async() => {
    if(getAccount()){
        let points = await GetTotalPoints(getAccount());
        setUserpoint(parseFloat(points)/10**18);
        const Proxydata = await GetAllocation();
        console.log("Proxydata", Proxydata);
        setAllocation(Proxydata?.data?.record);
        Proxydata?.data?.record?.map((data , i) => {
            if(parseFloat(data?.min) <= (parseFloat(points)/10**18)){
                setUsertier(data?.name)
            }
        })
    }
}


const logOut = async () => {
    setAccountInfo("");
    localStorage.removeItem("accountInfo")
    if (localStorage.getItem("walletconnect") != null) {
        const provider = await UseProvider();
        await provider.disconnect()
    }
    toast.success("Wallet Disconnected successfully", {
      position:position.position,
      style:style,
      iconTheme: iconTheme,
  })
  setTimeout(() => {
      
      window.location.reload()
  }, 1000);
}

    return (
        <>
            {walletmodal && <Walletmodal onDismiss={() => setWalletmodal(false)} />}
            {mobheader && <Mobileheader onDismiss={() => setMobheader(false)} />}

            <div className='newhead'>
                <Container>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <Link to="/"><img src={logo} className='img-fluid logoo' alt='images' /></Link>
                        </div>

                        {(accountInfo == "" || accountInfo == null)?
                            <div className='d-flex align-items-center'>
                                <Button className='themesbtn ms-2 ms-md-4' onClick={() => { setWalletmodal(true) }}><span>Connect Wallet</span></Button>
                                <div className='d-block d-lg-none ms-2' onClick={() => setMobheader(true)}><SlMenu className='menu' /></div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                <Dropdown className='d-none d-lg-block' onMouseLeave={() => setShowDropdown1(false)}
                                    onMouseOver={() => setShowDropdown1(true)}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {windowWidth > 575 ? `My account -  ${usertier ? usertier :"no tier" }` : <IoPersonCircleSharp className='proficon' />}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='py-3' show={showDropdown1}>
                                        <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                                        <Dropdown.Item href="/mytier">My Tier</Dropdown.Item>
                                        <Dropdown.Item href="/mypools">My Pools</Dropdown.Item>
                                        <Dropdown.Item href="/tokenrequest">Token Requests</Dropdown.Item>
                                        <Dropdown.Item onClick={()=>logOut()}><CiLogout/> Disconnect</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className='addressdrop ms-2 ms-md-4' onMouseLeave={() => setShowDropdown(false)}
                                    onMouseOver={() => setShowDropdown(true)}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className='themesbtn'><span>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38,42)}`}</span> </Dropdown.Toggle>
                                    <Dropdown.Menu className='py-4 px-3' show={showDropdown}>
                                        <div>
                                        <p className='grayclr mb-0'>Wallet Address</p>    
                                        <p className='fname'>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38,42)}`}</p>
                                        </div>    
                                        <div>
                                        <p className='grayclr mb-0'>Balance</p>    
                                        <p className='fname'>{parseFloat(balance).toFixed(3)} BNB</p>
                                        </div>
                                        <div>
                                        <p className='grayclr mb-0'>Network</p>    
                                        <p className='fname'><img src={binance} className='bnbimg' /> BSC Mainnet</p>
                                        </div> 
                                        <h6 className='whtsclr mb-0 discon'  onClick={()=>logOut()}><CiLogout/> Disconnect</h6>  
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <Button className='themesbtn ms-2 ms-md-4'><span>0x8648swfwef...</span></Button> */}
                                <div className='d-block d-lg-none ms-2' onClick={() => setMobheader(true)}><SlMenu className='menu' /></div>
                            </div>
                        }
                    </div>
                </Container>

            </div>
        </>
    )
}

export default Header
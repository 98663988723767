import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import { Link } from 'react-router-dom';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { CKEditor } from 'ckeditor4-react';
import { IoArrowBack } from "react-icons/io5";
import { EditLaunchpad, EditNativeLaunchpad, GetSingleNativeSaleHooks, GetSingleSaleHooks } from '../Hooks/UseBackenduser';
import toast from 'react-hot-toast';



function Infoedit() {
    const navigate = useNavigate()
    
    const [value, setValue] = useState('');
    const [intro , setIntro] = useState("");
    const [roadmap , setRoadmap] = useState("");
    const [team , setTeam] = useState("");
    const [parenersinvestors , setPartnersinvestors] = useState("");
    const [tokenmetric , setTokenmetric] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        fetchdata()
    }, []);

    const fetchdata = async() => {
        if(window.location.href?.split("/")[5] == "sale"){
            let saleres = await GetSingleSaleHooks({ saleaddress: window.location.href?.split("/")[4] })
            // setProinfo(saleres?.data?.record);
            setIntro(saleres?.data?.record?.intro);
            setRoadmap(saleres?.data?.record?.roadmap);
            setTeam(saleres?.data?.record?.team);
            setPartnersinvestors(saleres?.data?.record?.partnerandinvestor);
            setTokenmetric(saleres?.data?.record?.tokenmetric);
        }
        else if(window.location.href?.split("/")[5] == "nativesale"){
            let saleres = await GetSingleNativeSaleHooks({ saleaddress: window.location.href?.split("/")[4] })
            // setProinfo(saleres?.data?.record);
            setIntro(saleres?.data?.record?.intro);
            setRoadmap(saleres?.data?.record?.roadmap);
            setTeam(saleres?.data?.record?.team);
            setPartnersinvestors(saleres?.data?.record?.partnerandinvestor);
            setTokenmetric(saleres?.data?.record?.tokenmetric);
        }
    }
    const handleEditorChange = (event) => {
        // console.log("Event" , event?.editor?.getData());
        const data = event.editor.getData();
        setIntro(data);
        // console.log("Data" , data);
      };

      const handleEditorChangeroadmap = (event) => {
        // console.log("Event" , event?.editor?.getData());
        const data = event.editor.getData();
        setRoadmap(data);
        // console.log("Data" , data);
      };

      const handleEditorChangeteam = (event) => {
        // console.log("Event" , event?.editor?.getData());
        const data = event.editor.getData();
        setTeam(data);
        // console.log("Data" , data);
      };

      const handleEditorChangepart = (event) => {
        // console.log("Event" , event?.editor?.getData());
        const data = event.editor.getData();
        setPartnersinvestors(data);
        // console.log("Data" , data);
      };

      const handleEditorChangetoken = (event) => {
        // console.log("Event" , event?.editor?.getData());
        const data = event.editor.getData();
        setTokenmetric(data);
        // console.log("Data" , data);
      };


      const handlesubmit = async() => {
        if(intro && roadmap && team && parenersinvestors && tokenmetric){
            let payload = {
                intro : intro,
                roadmap : roadmap,
                team : team,
                partnerandinvestor : parenersinvestors,
                tokenmetric : tokenmetric,
                id : window.location.href.split("/")[4]
            }
            if(window.location.href?.split("/")[5] == "sale"){
                let result = await EditLaunchpad(payload);
                if(result?.data?.type == "Success"){
                    toast.success("Updated Successfully");
                    navigate("/info/" + window.location.href.split("/")[4]);
                    // window.location.reload();
                }
            }
            else if(window.location.href?.split("/")[5] == "nativesale"){
                let result = await EditNativeLaunchpad(payload);
                if(result?.data?.type == "Success"){
                    toast.success("Updated Successfully");
                    navigate("/native-card-info/" +  window.location.href.split("/")[4]+"/public");
                    // window.location.reload();
                }
            }
            
        }
        else {
            toast.error("Fill all the fields")
        }
      }

    return (
        <>


            <div className='all'>

                <Header />
                <Subheaders />

                <div className='innerpages pb-5'>
                    <div className='container'>
                        <div className='pb-4'><h1 className='mainhead text-center alltext mb-4'>Info <span className='mainsub'> Detail</span></h1></div>
                        
                        <div className='row'>
                            <div className='col-lg-10 col-xl-10 col-xxl-10 mx-auto'>
                            <div className='mb-4'><Link to={window.location.href?.split("/")[5] == "sale" ? "/info/" + window.location.href.split("/")[4] : "/native-card-info/" +  window.location.href.split("/")[4]+"/public"}><button className='btn nbtn'><IoArrowBack/> Back</button></Link></div>
                                <div className='editborder px-4 py-4'>
                                    <div className='pb-3'>
                                        <h4 class="fname mt-3 mb-3">1. Intro</h4>
                                        <CKEditor initData={intro}
                                        onChange={handleEditorChange}/>
                                    </div>
                                    <div className='pb-3'>
                                        <h4 class="fname mt-3 mb-3">2. Roadmap</h4>
                                        <CKEditor initData={roadmap}
                                        onInstanceReady={(e) => console.log("E" , e)}
                                        onChange={handleEditorChangeroadmap}
                                        />
                                    </div>
                                    <div className='pb-3'>
                                        <h4 class="fname mt-3 mb-3">3. Team</h4>
                                        <CKEditor initData={team}
                                        onChange={handleEditorChangeteam}
                                        />
                                    </div>
                                    <div className='pb-3'>
                                        <h4 class="fname mt-3 mb-3">4. Partner & Investors</h4>
                                        <CKEditor initData={parenersinvestors}
                                        onChange={handleEditorChangepart}
                                        />
                                    </div>
                                    <div className='pb-3'>
                                        <h4 class="fname mt-3 mb-3">1. Token Metrics</h4>
                                        <CKEditor initData={tokenmetric}
                                        onChange={handleEditorChangetoken}
                                        />
                                    </div>

                                    <div className='d-flex justify-content-center mt-3 mb-3 py-4'>
                                        <button className='btn themesbtn' onClick={handlesubmit}>Submit</button></div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>

                <Footer />

            </div>
        </>
    )

}
export default Infoedit;
import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import { getfaqlist } from '../landingaction/landing';

function Terms() {
const [terms,setTerms] =useState({})
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
useEffect(()=>{
    FetchCms()
},[])
    const FetchCms = async () => {
        let Resp = await getfaqlist({ data: "termsandcondition" })
        console.log("termsandcondition", Resp.data.data.data);
        if (Resp?.data.status) {
            setTerms(Resp.data.data.data)

        }

    }
    return (

        <div className='all'>
            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>
                <div className='container termss'>
                <h1 className='mainhead text-center alltext mb-4'>Terms & <span className='mainsub'>Conditions</span></h1>
                <div className='row pt-5 pb-5'>
                    <div className='col-lg-11 col-xl-10 col-xxl-9 mx-auto'>
                        <p className='fname f-16'>
                            {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. */}
                            <div dangerouslySetInnerHTML={{__html:terms?.description}}></div>
                        </p>
                      {/* <h4 className='fname mt-4'>1. Terms</h4>
                      <p className='fname f-16'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                      <h4 className='fname mt-4'>2. Types </h4>
                      <p className='fname f-16'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                      <h4 className='fname mt-4'>3. Miscellaneous </h4>
                      <p className='fname f-16'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                    </div>
                </div>

                </div>
            </div>

            <Footer />

        </div>
    )
}

export default Terms
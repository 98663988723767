import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import binance from "../images/spropad/binance.png"
import ScrollspyNav from "react-scrollspy-nav";
import grayline from "../images/spropad/grayline.png";
import introimg from "../images/spropad/introimg.png";
import team1 from "../images/spropad/team1.png";
import team2 from "../images/spropad/team1.png";
import roadmap from "../images/spropad/roadmap.png";
import cryptoimg from "../images/spropad/cryptoimg.png";
import pngwing1 from "../images/spropad/pngwing 1.png";
import pngwing2 from "../images/spropad/pngwing 2.png";
import pngwing3 from "../images/spropad/pngwing 3.png";
import pngwing4 from "../images/spropad/pngwing 4.png";
import { useLocation } from 'react-router';
import { GetRegisteredUsers, GetSalePerAccount, Getbalance, UseERC20, checkbalnceTokens, getAllocationInfo, getSaleInfoCard, getTotalSalesInfo } from '../Hooks/useContract';
import { UseTokenInfo } from '../Hooks/useStake';
import { CHAINS } from '../Config/env';
import { getChainId } from '../Hooks/useAccounts';
import { GetSingleSaleHooks } from '../Hooks/UseBackenduser';
// import { login } from '../../../Admin_panel/src/Actions/LoginActions';

function Projectinfo() {
    // console.log("propsinfo",props);
    const location = useLocation()
    console.log("locatins", location);
    const records = location.state
    console.log("records", records);
const [salecard,setSalecard] = useState([])
const [buytoken,setBuytoken] = useState('')
console.log("buytoken", buytoken);

const [decimal,setDecimal] = useState('')

const [saledetail , setSaledetail] = useState({});
console.log("decimal", decimal);

const [proinfo , setProinfo] = useState({});
const [balance , setBalance] = useState(0);
const [userdata , setUserdata] = useState({});
const [registeruser , setRegisteruser] = useState(0);

// const [checkbalance, setCheckbalance] = useState(0)

// const [charttokendecimal, setCharttokendecimal] = useState(18)

    const [partner, setPartner] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }, { id: 11 }, { id: 12 }
    ]);


    // const lockDaysCal = () => {
    //     if (parseFloat(records?.lpUnlockon) == 0) {
    //       return 0
    //     }
    //     let diffTime = Math.abs(new Date(parseFloat(records?.endTime) * 1000).getTime() - new Date(parseFloat(saledata?.lpUnlockon) * 1000).getTime());
    //     let days = diffTime / (24 * 60 * 60 * 1000);
    //     console.log("days", days)
    //     return isNaN(days) ? 0 : days.toFixed(0)
    //   }
    
    useEffect(()=>{
    //     saledata()
    //     refreshData()
    claimabledata();
    tokenInfo();
    getbal()
    },[])

    const getbal = async() => {
        let bal = await Getbalance()
        setBalance(parseFloat(bal).toFixed(4))
    }


    
    console.log(" CHAINS[getChainId()].CHAIN_ID,", CHAINS[getChainId()].NAME,)

       const isSaleLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }
    const isUpcoming = (start, end) => {
        try {
            return (Date.now() < (start * 1000));
        }
        catch (e) {
            console.log("isUpcoming", e);
        }
      }
      const claimabledata = async () => {
        if (localStorage.getItem("accountInfo")) {
            console.log("localStorage", localStorage.getItem("accountInfo"));
            const users = await GetSalePerAccount(localStorage.getItem("accountInfo"), records?.saleAddress);
            console.log("users", users);

            setUserdata(users);

            // if (records?.isClaimable) {
            //     console.log("isClaimable", records?.isClaimable);

            //     const allocated = await getAllocationInfo(userdata.actualBalance, userdata.userClaimbale, records.vestingInterval, records.vestingPercent, userdata.initialClaim);
            //     console.log("allocated", allocated);
            //     setAllocatedata(allocated);

            // }
            // if (!records?.buytype) {
            //     console.log("buytype", !records?.buytype);

            //     approveFunction(localStorage.getItem("accountInfo"), records?.useWithToken, records?.saleAddress)
            //     console.log("sdfsdfsdfr", localStorage.getItem("accountInfo"), records?.useWithToken, records?.saleAddress);

            // }

        }
    }
      const tokenInfo = async () =>{
        let usetokenaddress = await UseTokenInfo(records?.useWithToken)
        console.log("usetokenaddress",usetokenaddress);
        GetBuytokeninfo(records?.useWithToken)

        let result = await getSaleInfoCard(window.location.href?.split("/")[4])
        setSaledetail(result);

        let saleres = await GetSingleSaleHooks({saleaddress : window.location.href?.split("/")[4]})
        setProinfo(saleres?.data?.record);

        let registerusers = await GetRegisteredUsers(window.location.href?.split("/")[4]);
        setRegisteruser(registerusers?.length? registerusers?.length : 0)
      }
   

      const GetBuytokeninfo = async (value) => {
        console.log("value",value);

        if (value === "0x0000000000000000000000000000000000000000") {
          console.log("kxcnvjxch");
          setBuytoken('BNB')
          setDecimal('18')

        } else {
          const token = await UseTokenInfo(records && records?.useWithToken);
          console.log("UseTokenInfo", token);
          setBuytoken(token?.symbol)
          setDecimal(token?.decimals)

    
        }
      }



     


    return (

        <div className='mt-4'>

            <div className='row'>
                <div className='col-lg-12 col-xl-6 mt-3'>
                    <div className='projectback pt-4 pb-3 px-3 px-xxl-4'>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Price per {saledetail?.symbol} token</p><p className='whtsclr'>{saledetail && (1/(parseFloat(saledetail.presaleRate)/10**18))?.toFixed(4)}{" "}{buytoken}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>User Invested</p><p className='whtsclr'>{parseFloat(userdata?.userInvested)/10**18} {"  "}{buytoken}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Total Participants</p><p className='grnclr'>{saledetail?.participants}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Total Raise</p><p className='grnclr'>{parseFloat(saledetail?.earnedCap)/10**18}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Status</p><p className='whtsclr'>{saledetail && isSaleLive(saledetail.startTime, saledetail.endTime, saledetail.isPresaleOpen) ?
                        


                            <span className="badge badge_live badge_finish">

                                <span className='stats_txt'><h5>Sales Live</h5></span>
                            </span> :
                            (records && isUpcoming(records.startTime) ?
                                <span className="badge  badge_finish">


                                    <span className='stats_txt'><h5>Upcoming</h5></span>
                                </span> :


                                <span className="badge badge_finish">

                                    <span className='stats_txt'><h5>Sales End</h5></span>
                                </span>
                            )
                        }</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Interest User Count</p><p className='grnclr'>{registeruser}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Refund Terms</p><p className='grnclr'>7 days</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Refund</p><p className='grnclr'>---</p></div> */}
                    </div>
                </div>
                <div className='col-lg-12 col-xl-6 mt-3'>
                    <div className='projectback pt-4 pb-3 px-3 px-xxl-4'>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Accepted Currency</p><p className='whtsclr'>{buytoken}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Network</p><p className='whtsclr'><img src={binance} className='bnbimg' /> {CHAINS[getChainId()].NAME}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Token Claim Network</p><p className='whtsclr'><img src={binance} className='bnbimg' /> {CHAINS[getChainId()].NAME}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Vesting Schedule</p><p className='whtsclr'>{records?.vestingInterval && records?.vestingInterval > 0 ? "Enable" : "Disable"}</p></div>
                    </div>
                </div>
            </div>

            <div className='mt-5 pb-3'>
                <ScrollspyNav
                    scrollTargetIds={["section_0", "section_1", "section_2", "section_3", "section_4"]}
                    offset={-100}
                    activeNavClass="is-active"
                    scrollDuration="1000"
                >
                    <ul className='infonav'>
                        <li><a href="#section_0" className='active'><span>Intro</span></a></li>
                        <li><a href="#section_1"><span>Roadmap</span></a></li>
                        <li><a href="#section_2"><span>Team</span></a></li>
                        <li><a href="#section_3"><span>Partner & Investors</span></a></li>
                        <li><a href="#section_4"><span>Token metrics</span></a></li>
                    </ul>
                </ScrollspyNav>
            </div>

            <div className='infodetail'>
                <div>
                    {/* <div id="section_0" className='section pb-3'>
                        <h3 className='fname mt-3'>Intro</h3>
                        <img src={grayline} className='img-fluid' />
                        <p className='grayclr mt-3'>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet. Nullam dictumst euismod dictumst amet sed lacus. Iaculis odio rhoncus eu purus scelerisque etiam morbi. Vitae consectetur tincidunt purus lobortis. Netus rhoncus laoreet turpis tortor. Egestas gravida cursus nunc id ullamcorper purus non pretium. Eget tortor fames viverra nulla. Vulputate nisi laoreet lectus neque facilisis sagittis. Orci semper in nunc odio euismod.
                            Magna amet lacus ac nascetur neque pulvinar at. Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique velit cras volutpat mattis. Sodales turpis pretium amet imperdiet sagittis posuere nisl ut cursus. Ornare et imperdiet at semper proin massa. Ullamcorper congue blandit cras vitae justo.
                        </p>
                        <img src={introimg} className='img-fluid mt-2' />
                        <h3 className='fname mt-4'>What is Account Abstraction?</h3>
                        <p className='grayclr mt-3'>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet. Nullam dictumst euismod dictumst amet sed lacus. Iaculis odio rhoncus eu purus scelerisque etiam morbi. Vitae consectetur tincidunt purus lobortis. Netus rhoncus laoreet turpis tortor. Egestas gravida cursus nunc id ullamcorper purus non pretium. Eget tortor fames viverra nulla. Vulputate nisi laoreet lectus neque facilisis sagittis. Orci semper in nunc odio euismod.
                            Magna amet lacus ac nascetur neque pulvinar at. Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique velit cras volutpat mattis. Sodales turpis pretium amet imperdiet sagittis posuere nisl ut cursus. Ornare et imperdiet at semper proin massa. Ullamcorper congue blandit cras vitae justo.
                        </p>
                        <img src={introimg} className='img-fluid mt-2' />
                        <p className='grayclr mt-3'>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet. Nullam dictumst euismod dictumst amet sed lacus. Iaculis odio rhoncus eu purus scelerisque etiam morbi. Vitae consectetur tincidunt purus lobortis. Netus rhoncus laoreet turpis tortor. Egestas gravida cursus nunc id ullamcorper purus non pretium. Eget tortor fames viverra nulla. Vulputate nisi laoreet lectus neque facilisis sagittis. Orci semper in nunc odio euismod.
                            Magna amet lacus ac nascetur neque pulvinar at. Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique velit cras volutpat mattis. Sodales turpis pretium amet imperdiet sagittis posuere nisl ut cursus. Ornare et imperdiet at semper proin massa. Ullamcorper congue blandit cras vitae justo.
                        </p>
                    </div>
                    <div id="section_1" className='section pt-3 pb-3'>
                        <div>
                            <h3 className='fname mt-3'>Roadmap</h3>
                            <img src={grayline} className='img-fluid' />
                            <img src={roadmap} className='img-fluid mt-4' />
                            <p className='grayclr mt-3'>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet. Nullam dictumst euismod dictumst amet sed lacus. Iaculis odio rhoncus eu purus scelerisque etiam morbi. Vitae consectetur tincidunt purus lobortis. Netus rhoncus laoreet turpis tortor. Egestas gravida cursus nunc id ullamcorper purus non pretium. Eget tortor fames viverra nulla. Vulputate nisi laoreet lectus neque facilisis sagittis. Orci semper in nunc odio euismod.
                                Magna amet lacus ac nascetur neque pulvinar at. Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique velit cras volutpat mattis. Sodales turpis pretium amet imperdiet sagittis posuere nisl ut cursus. Ornare et imperdiet at semper proin massa. Ullamcorper congue blandit cras vitae justo.
                            </p>
                            <h4 className='fname mb-2 mt-3'>2024 New Chain Integration</h4>
                            <ul>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                            </ul>
                            <h4 className='fname mb-2 mt-3'>Q3. 2023 Multi Currency</h4>
                            <ul>
                                <li>Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique.</li>
                                <li>Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique.</li>
                                <li>Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique.</li>
                                <li>Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique.</li>
                                <li>Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique.</li>
                            </ul>
                            <h4 className='fname mb-2 mt-3'>Q2. 2022 Chat & Pay</h4>
                            <ul>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                                <li>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet.</li>
                            </ul>
                        </div>

                    </div>
                    <div id="section_2" className='section pt-3 pb-3'>
                        <div>
                            <h3 className='fname mt-3'>Team</h3>
                            <img src={grayline} className='img-fluid' />
                            <div className='row justify-content-center'>
                                <div className='col-11 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mt-4 text-center'>
                                    <div className='teampath'>
                                        <img src={team1} className='img-fluid teamimg team1' />
                                    </div>
                                    <h4 className='fname mt-3'>Lorem</h4>
                                    <p className='grayclr'>Lorem Ipsum Lorem Ipsum</p>
                                </div>
                                <div className='col-11 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mt-4 text-center'>
                                    <div className='teampath'>
                                        <img src={team2} className='img-fluid teamimg team1' />
                                    </div>
                                    <h4 className='fname mt-3'>Lorem</h4>
                                    <p className='grayclr'>Lorem Ipsum Lorem Ipsum</p>
                                </div>
                                <div className='col-11 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mt-4 text-center'>
                                    <div className='teampath'>
                                        <img src={team1} className='img-fluid teamimg team1' />
                                    </div>
                                    <h4 className='fname mt-3'>Lorem</h4>
                                    <p className='grayclr'>Lorem Ipsum Lorem Ipsum</p>
                                </div>
                                <div className='col-11 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mt-4 text-center'>
                                    <div className='teampath'>
                                        <img src={team1} className='img-fluid teamimg team1' />
                                    </div>
                                    <h4 className='fname mt-3'>Lorem</h4>
                                    <p className='grayclr'>Lorem Ipsum Lorem Ipsum</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="section_3" className='section pt-3 pb-3'>
                        <div>
                            <h3 className='fname mt-3'>Partner & Investors</h3>
                            <img src={grayline} className='img-fluid' />
                            <div className='partnerback pt-4 pb-5 px-4 mt-4'>
                                <div className='row'>
                                    {partner.map((e, i) =>
                                        <div className='col-6 col-sm-4 col-md-4 col-lg-3 mt-4 text-center'>
                                            <img src={pngwing1} className='img-fluid wing' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="section_4" className='section pt-3 pb-3'>
                        <div>
                            <h3 className='fname mt-3'>Token Metrics </h3>
                            <img src={grayline} className='img-fluid' />
                            <p className='grayclr mt-3'>Lorem ipsum dolor sit amet consectetur. Viverra diam enim neque est amet. Nullam dictumst euismod dictumst amet sed lacus. Iaculis odio rhoncus eu purus scelerisque etiam morbi. Vitae consectetur tincidunt purus lobortis. Netus rhoncus laoreet turpis tortor. Egestas gravida cursus nunc id ullamcorper purus non pretium. Eget tortor fames viverra nulla. Vulputate nisi laoreet lectus neque facilisis sagittis. Orci semper in nunc odio euismod.
                                Magna amet lacus ac nascetur neque pulvinar at. Etiam sed ultrices nisi sapien pharetra aliquet praesent scelerisque. Diam eget vitae tristique velit cras volutpat mattis. Sodales turpis pretium amet imperdiet sagittis posuere nisl ut cursus. Ornare et imperdiet at semper proin massa. Ullamcorper congue blandit cras vitae justo.
                            </p>
                            <img src={cryptoimg} className='img-fluid mt-4' />
                        </div>
                    </div> */}

                    <div id="section_0" className='section pb-3'>
                        {proinfo?.intro && <h3 className='fname mt-3'>Intro</h3>}
                   { ReactHtmlParser(proinfo?.intro)}
                    </div>

                    <div id="section_1" className='section pb-3 pb-3'>
                        {proinfo?.roadmap && <h3 className='fname mt-3'>Roadmap</h3>}
                   { ReactHtmlParser(proinfo?.roadmap)}
                    </div>

                    <div id="section_2" className='section pb-3 pb-3'>
                        {proinfo?.team && <h3 className='fname mt-3'>Team</h3>}
                   { ReactHtmlParser(proinfo?.team)}
                    </div>

                    <div id="section_3" className='section pb-3 pb-3'>
                        {proinfo?.partnerandinvestor && <h3 className='fname mt-3'>Partner & Investors</h3>}
                   { ReactHtmlParser(proinfo?.partnerandinvestor)}
                    </div>

                    <div id="section_4" className='section pb-3 pb-3'>
                    {proinfo?.tokenmetric && <h3 className='fname mt-3'>Token Metrics </h3>}
                   { ReactHtmlParser(proinfo?.tokenmetric)}
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Projectinfo;
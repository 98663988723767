import React, { useState } from 'react';
import tableprof from '../images/spropad/tableprof.png';
import { Link } from 'react-router-dom';

const DataTable = ({ data, rowsPerPage ,proxy , endedsale , loadmore}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(proxy.length / rowsPerPage);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        loadmore();
    };

    const padToDigits = (num) => {
        return num.toString().padStart(2, '0')
    }

    const startIndex = (currentPage - 1) * rowsPerPage;
    const selectedData = data.slice(startIndex, startIndex + rowsPerPage);
    console.log("endedsale" , endedsale);

    return (
        <div>

            <div className='alltableoverflow'>
                {selectedData?.map((row) => (
                    <Link to={"/info/"+row?.saleaddress}>
                    <div className='salestablefilter'>
                        <div className='salestable mt-4'>
                            <div className='salestlist'>
                                <div> 
                                    <img src={row?.logo} className='tableprof img-fluid' />
                                    <p className='whtsclr mt-2 mb-0'>{row?.name}</p>
                                    </div>                               
                                {/* <div>
                                    <p className='grayclr'>Lorem Ipsum</p>
                                    <p className='whtsclr mb-0'>$582,000 inVIA Fr..</p>
                                </div> */}
                                <div>
                                    <p className='grayclr'>Total raise</p>
                                    <p className='whtsclr mb-0'>{row?.earnedCap/10**18}</p>
                                </div>
                                <div>
                                    <p className='grayclr'>Participants</p>
                                    <p className='whtsclr mb-0'>{row?.participants}</p>
                                </div>
                                <div>
                                    <p className='grayclr'>Start Time</p>
                                    <p className='whtsclr mb-0'>{(padToDigits(new Date(parseFloat(row?.startTime) * 1000).toLocaleDateString()) + "  "
                                + padToDigits(new Date(parseFloat(row?.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(row?.startTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(row?.startTime) * 1000).getSeconds()) + "(GMT)")}</p>
                                </div>
                                <div>
                                    <p className='grayclr'>End Time</p>
                                    <p className='whtsclr mb-0'>{(padToDigits(new Date(parseFloat(row?.endTime) * 1000).toLocaleDateString()) + "  "
                                + padToDigits(new Date(parseFloat(row?.endTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(row?.endTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(row?.endTime) * 1000).getSeconds()) + "(GMT)")}</p>
                                </div>
                                {/* <div>
                                    <p className='grayclr'>Current ROI</p>
                                    <p className='whtsclr mb-0'>12X</p>
                                </div> */}
                                <div>
                                    <p className='grayclr'>Status</p>
                                    <p className='grnclr mb-0'>Ended</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                ))}
            </div>

            <div className="pagination mt-4">
                <button
                    className='pauseleftbtn ml-2'
                    onClick={() => handlePageClick(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    {/* &#8592; */}
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : 'btns'}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className='pauserightbtn'
                    onClick={() => handlePageClick(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    {/* &#8594; */}
                </button>
            </div>




        </div>
    );
};

function App(props) {
    console.log("props?" , props?.endedsale);
    const data = Array.from({ length: 35 }, (_, index) => ({
        id: index + 1,
        name: `Item ${index + 1}`,
    }));

    return (
        <div className="App">
            <DataTable data={props?.endedsale} proxy = {props?.proxy} rowsPerPage={5} loadmore={() => props?.loadmore()}/>
        </div>
    );
}

export default App;




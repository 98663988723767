import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import headertop from "../images/spropad/headtop.png";
import unders from "../images/spropad/underline.png";


function Homesubheader() {


    return (

        <>
        
          <div className='subheaders'>
          <img src={headertop} className='headertop'/>
            <div className='leftrotate'>               
                <NavLink to="/launchpad">Launchpad
                 <img src={unders} className='unders'/>  
                </NavLink>
                <NavLink to="/staking">Staking <img src={unders} className='unders'/> </NavLink>
                <NavLink target = "_blank"to="https://spropad.gitbook.io/smart-pro-pad-whitepaper">whitepaper  <img src={unders} className='unders'/>  </NavLink>
            </div>

          </div>
                
        </>

    )
}


export default Homesubheader
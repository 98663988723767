import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown, Offcanvas } from 'react-bootstrap';
import logo from '../images/spropad/logo.png';
import connectbtn from '../images/spropad/connectbtn.png';
import { IoPersonCircleSharp } from "react-icons/io5";
import { Link, NavLink } from 'react-router-dom';
import unders from "../images/spropad/underline.png";
import Walletmodal from '../Components/walletmodal';
import { CiLogout } from 'react-icons/ci';
import binance from "../images/spropad/binance.png";
import { Getbalance } from '../Hooks/useContract';
import { getAccount } from '../Hooks/useAccounts';
import { GetTotalPoints } from '../Hooks/useStake';
import { GetAllocation } from '../Hooks/UseBackenduser';
import { UseProvider } from '../Hooks/UseWeb3';
import { iconTheme, position, style } from '../Hooks/useToast';
import toast from 'react-hot-toast';

function Mobileheader(props) {

  const [mobheader, setMobheader] = useState(true);
  const [walletmodal, setWalletmodal] = useState(false);




  const [accountInfo, setAccountInfo] = useState(getAccount());
const [balance , setBalance] = useState(0);
const [userpoint , setUserpoint] = useState(0);
const [allocation , setAllocation] = useState()
const [usertier , setUsertier] = useState();

  useEffect(() => {
    getbal();
    fetchdata();
} , [])

const getbal = async() => {
    let bal = await Getbalance()
    setBalance(bal)
}

const fetchdata = async() => {
    if(getAccount()){
        let points = await GetTotalPoints(getAccount());
        setUserpoint(parseFloat(points)/10**18);
        const Proxydata = await GetAllocation();
        console.log("Proxydata", Proxydata);
        setAllocation(Proxydata?.data?.record);
        Proxydata?.data?.record?.map((data , i) => {
            if(parseFloat(data?.min) <= (parseFloat(points)/10**18)){
                setUsertier(data?.name)
            }
        })
    }
}


const logOut = async () => {
    setAccountInfo("");
    localStorage.removeItem("accountInfo")
    if (localStorage.getItem("walletconnect") != null) {
        const provider = await UseProvider();
        await provider.disconnect()
    }
    toast.success("Wallet Disconnected successfully", {
      position:position.position,
      style:style,
      iconTheme: iconTheme,
  })
  setTimeout(() => {
      
      window.location.reload()
  }, 1000);
}

  return (
    <>
      {walletmodal && <Walletmodal onDismiss={() => setWalletmodal(false)} />}
      <Offcanvas show={mobheader} placement="end">
        <Offcanvas.Header closeButton onClick={props.onDismiss}>
          <Offcanvas.Title><Link to="/" onClick={props.onDismiss}><img src={logo} className='img-fluid logoo' alt='images' /></Link> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='py-5 px-4'>
          <NavLink to="/" onClick={props.onDismiss}>Launchpad </NavLink>
          <NavLink to="/" onClick={props.onDismiss}>Staking </NavLink>
          <NavLink target = "_blank" to="https://spropad.gitbook.io/smart-pro-pad-whitepaper" onClick={props.onDismiss}>Whitepaper </NavLink>

          {localStorage.getItem("metamask") !== "true" ?
            <Button className='themesbtn mt-4' onClick={() => { setWalletmodal(true) }}><span>Connect Wallet</span></Button>
            :
            <>
              {/* <Button className='themesbtn mt-4'><span><span>0x8648swfwef...</span></span></Button> */}
              <Dropdown className='d-block d-lg-none mt-3 mb-3'>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className='ps-0'>
                  My account - no tier
                </Dropdown.Toggle>
                <Dropdown.Menu className='py-3 mobiledropmenu'>
                  <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                  <Dropdown.Item href="/mytier">My Tier</Dropdown.Item>
                  <Dropdown.Item href="/mypools">My Pools</Dropdown.Item>
                  <Dropdown.Item href="/tokenrequest">Token Requests</Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => { localStorage.clear(); window.location.reload() }}><CiLogout /> Disconnect</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            {accountInfo && <>
              <div>
                <p className='grayclr mb-0'>Wallet Address</p>
                <p className='fname'>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38,42)}`}</p>
              </div>
              <div>
                <p className='grayclr mb-0'>Balance</p>
                <p className='fname'>{parseFloat(balance).toFixed(3)} BNB</p>
              </div>
              <div>
                <p className='grayclr mb-0'>Network</p>
                <p className='fname'><img src={binance} className='bnbimg' /> BSC Mainnet</p>
              </div>
              <h6 className='whtsclr mb-0 discon pt-3'  onClick={()=>logOut()}><CiLogout/> Disconnect</h6>  </>
}
          
            </>
          }

        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Mobileheader

import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import smallrect from "../images/spropad/smallrectangle.png";
import Countdown from 'react-countdown';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Upcoming(props) {

    const renderer = ({ hours, minutes, seconds, completed, days }) => {
        if (completed) {
            // Render a completed state
            return <span>Waiting for Owner To Accept</span>;
        } else {
            // Render a countdown
            return (
                <div className='d-flex mt-3'>
                    <div>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(days)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Days</p>
                    </div>
                    <p className='whtsclr mb-0 ms-1 ms-sm-2'>:</p>
                    <div className='ms-2 ms-xxl-3'>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(hours)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Hrs</p>
                    </div>
                    <p className='whtsclr mb-0 ms-1 ms-sm-2'>:</p>
                    <div className='ms-2 ms-xxl-3'>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(minutes)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Min</p>
                    </div>
                    <p className='whtsclr mb-0 ms-1 ms-sm-2'>:</p>
                    <div className='ms-2 ms-xxl-3'>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(seconds)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Sec</p>
                    </div>
                </div>
            );
        }
    };

    const formatTime = (time) => {
        return String(time).padStart(2, "0");
    };

    const [volumee, setvolumee] = useState(60)
    const handleOnChange = (value) => {
        setvolumee(value)
    };

    const padToDigits = (num) => {
        return num.toString().padStart(2, '0')
    }

    const UpcomingDiffernce = (start) => {
        console.log("starttss", start);
        try {
            console.log("returnvaluess", start, (start * 1000) - Date.now());

            return ((start * 1000) - Date.now());
        }
        catch (e) {
            console.log("UpcomingDiffernce", e);
        }
    }

    const isSaleLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }

    const Salediffernce = (end) => {
        try {
            return ((end * 1000) - Date.now());
        }
        catch (e) {
            console.log("Salediffernce", e);
        }
    }

    const isUpcoming = (start, end) => {
        try {
            return (Date.now() < (start * 1000));
        }
        catch (e) {
            console.log("isUpcoming", e);
        }
    }


    const sliderStyles = {
        pointerEvents: 'none', // Disables all pointer events
        opacity: 0.9 // Optionally, reduce opacity to visually indicate it's non-interactive
    };

    const [interest, setInterset] = useState(true)
const register = () =>{
    setInterset(false)
    toast("Wow so easy!");
} 

    return (

        <div>
            <div className='timezone py-4 px-3 px-lg-2 px-xxl-3'>

                {/* {interest ?
                    <div>
                        <h4 className='fname'>Join Interest</h4>
                        <p className='grayclr smtxt'>The interset is not yet open.</p>
                        <Button className='themesbtn mx-auto' onClick={() =>register() }><span>Register interest</span></Button>
                    </div>
                    :
                    <div className='d-flex'>
                         <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div>
                        <h4 className='fname'>Registered Interest</h4>
                        <p className='grayclr smtxt mb-0'>Results will be announced.</p>
                        <p className='grayclr smtxt'>Wednesday, Mar 26,2024</p>
                        </div>
                    </div>
                } */}

                {/* <div>
                    <h4 className='fname'>Swap Progress</h4>
                    <div style={sliderStyles}>
                        <Slider
                            min={0}
                            max={100}
                            value={volumee}
                            onChange={handleOnChange}
                            tooltip={false}
                        />
                        <div className='d-flex justify-content-between'>
                            <p className='whtsclr'>({volumee}%)</p>
                            <p className='whtsclr'>12853 / 19778</p>
                        </div>
                    </div>
                </div> */}



                {/* <p className='fname mt-3'>Time Zone : (GMT -12 : 00)</p> */}

                <div>
                    <div className='d-flex mb-3'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div className='upcoming py-3 px-3'>
                            <h5 className='fname'>{props?.salerecords && isSaleLive(props?.salerecords.startTime, props?.salerecords.endTime, props?.salerecords.isPresaleOpen) ?

                                <span className="badge badge_live badge_finish">

                                    <span className='stats_txt'><h5>Sales Live</h5></span>
                                </span> :
                                (props?.salerecords && isUpcoming(props?.salerecords.startTime) ?
                                    <span className="badge  badge_finish">


                                        <span className='stats_txt'><h5>Upcoming</h5></span>
                                    </span> :


                                    <span className="badge badge_finish">

                                        <span className='stats_txt'><h5>Sales Ended</h5></span>
                                    </span>
                                )
                            }</h5>
                            {/* <p className='grayclr f-16 mb-2'>Interest Period:</p> */}
                            <span><span className='grayclr f-16 mb-2'>Start Time</span><span className='whtsclr f-16 ms-3'>{(padToDigits(new Date(parseFloat(props?.salerecords?.startTime) * 1000).toLocaleDateString()) + "  "
                                + padToDigits(new Date(parseFloat(props?.salerecords?.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(props?.salerecords?.startTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(props?.salerecords?.startTime) * 1000).getSeconds()) + "(GMT)")}</span></span> <br />
                            <span><span className='grayclr f-16 me-2'>End Time</span><span className='whtsclr f-16 ms-4'>{(padToDigits(new Date(parseFloat(props?.salerecords?.endTime) * 1000).toLocaleDateString()) + "  "
                                + padToDigits(new Date(parseFloat(props?.salerecords?.endTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(props?.salerecords?.endTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(props?.salerecords?.endTime) * 1000).getSeconds()) + "(GMT)")}</span></span>
                            {/* <h5 className='fname mt-4'>Token Swap starts in</h5> */}
                            <div className='mt-4'>
                                {props?.salerecords && isUpcoming(props?.salerecords.startTime) ?
                                    <p class="mt-2 countup countbtn ml-sm-0 mb-2">
                                        <span class="badge  badge_finish">
                                            <div className='countdown'>
                                                <span className='stats_txt'>Sale Starts in</span> <br /> <Countdown date={Date.now() + (UpcomingDiffernce(props?.salerecords.startTime))} renderer={renderer} className="countdown_grey" />
                                            </div>
                                        </span>
                                    </p> :
                                    (props?.salerecords && isSaleLive(props?.salerecords.startTime, props?.salerecords.endTime, props?.salerecords.isPresaleOpen) ?
                                        <p class="mt-2 countbtn mb-2">
                                            <span class="badge  badge_finish">
                                                {/* <span class=""> */}
                                                    <div className='countdown countdown_grey_cen'>
                                                        <span className='stats_txt'>Sale Ends in</span> <br /> <Countdown date={Date.now() + Salediffernce( props?.salerecords.endTime)} renderer={renderer} className="countdown_grey" />
                                                    </div>
                                                </span>
                                        </p> :
                                        <p className='countdown_grey mt-2 countdown_grey_cen'>
                                            <span class="badge  badge_finish">
                                            <span className='stats_txt'>The Sale Is Ended</span> <br />
                                            </span>
                                        </p>)
                                }</div>
                        </div>
                    </div>

                    {/* <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>Swap</h5>  <p className='grayclr f-16 mb-2'>00 : 00, 30 mar 2024</p></div>
                    </div>

                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>Filled</h5></div>
                    </div>

                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>Claimable</h5> <p className='grayclr f-16 mb-2'>TBA</p></div>
                    </div>

                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>Ended</h5></div>
                    </div> */}

                </div>




            </div>

        </div>
    )
}

export default Upcoming
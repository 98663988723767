import React, { useState, useEffect } from 'react';
import { Container, Button, Tab, Nav, Col, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import config from "../Config/config"
import { getAccount } from "../Hooks/useAccounts";
import { ONEDAYINSECONDS } from '../Config/env'
//sonia
import { OnStake, UseTokenInfo, approveContract, checkIsApproved, getUser, getUserBalance, getUserReward } from '../Hooks/useStake';

function Stakemodal(props) {
    console.log("propsvalues", props)
    const [stakemodal, setStakemodal] = useState(true);
    const [volumess, setvolumess] = useState(20)
    console.log("volumess", volumess)
    const [userTokenBalance, setUserTokenBalance] = useState("");
    console.log("userTokenBalance", userTokenBalance)

    const [accountInfo, setAccountInfo] = useState(getAccount());
    const [isApproved, setIsApproved] = useState(false);
    const [tokenInfo, setTokenInfo] = useState(null);
    const [pooldays, setpoolDays] = useState('')
    const [pending, setPending] = useState(0)
    const [status, setStatus] = useState(false)
    const [userStakedAmount, setUserStakedAmount] = useState(0);
    const [isMax, setIsMax] = useState(false);
    const [deposit, setDeposit] = useState("0");
    // const [Maxdeposit, setmaxDeposit] = useState("0");
    console.log("alldatas", pooldays, pending, status, userStakedAmount);

    useEffect(() => {
        if (accountInfo) {
            getData()

            if (props.datas.lpToken && props.datas.lpToken) {
                approveFunction(props.datas.lpToken);
            }
            getUserTokenBalance();

        }



    }, [accountInfo])

    useEffect(() => {
        setAccountInfo(getAccount());
        // setmaxDeposit(userTokenBalance);
    }, []);

    // const maxDeposit = () => {
    //     if (parseFloat(userTokenBalance) > 0) {
    //       setIsMax(true);
    //       setmaxDeposit(userTokenBalance);
    //     }
    //   }

    const depositTokens = async () => {
        const pid = props.pid;
        const decimal = props.tokenInfo.decimals;
        await OnStake(getAccount(), pid, deposit, decimal, isMax)

    }


    const handleOnChange = (value) => {
        console.log("valoues", value);
        setvolumess(value)
    }
    const sliderStyles = {
        pointerEvents: 'none', // Disables all pointer events
        opacity: 0.01 // Optionally, reduce opacity to visually indicate it's non-interactive
    };
    //sonia
    const MinStakeAmt = config.MinStakeAmt;
    const [Error, setError] = useState({});
    const [Stakeamt, setStakeamt] = useState("");
    console.log("Stakeamt", Stakeamt)
    function handlePercentageClick(percent, val) {
        setError({});
        console.log("percent,val", percent, val);
        if (parseFloat(percent) >= parseFloat(MinStakeAmt)) {
            setDeposit(parseFloat(percent).toFixed(4))
            setError({});
        } else {
            setDeposit(percent)
            setError({ Stake: `Input amount should be greater than or equal to ${MinStakeAmt}` });
        }
        setvolumess(val)

    }
    const BalanceAmt = userTokenBalance;

    function OnInputChange(e) {
        console.log("eee", e)
        setError({});
        let inputValue = e.target.value;
        console.log("inrtgrtg", inputValue);
        if (parseFloat(inputValue) >= parseFloat(MinStakeAmt)) {

            setDeposit(inputValue)
            setError({});
        } else {
            setDeposit(inputValue)
            setError({ Stake: `Input amount should be greater than or equal to ${MinStakeAmt}` });
        }
    }

    // const approve = async () => {
    //     if (accountInfo) {

    //         await approveContract(accountInfo, props.datas.lpToken);
    //         setIsApproved(true);
    //     }

    // }
    const getUserTokenBalance = async () => {

        if (props.datas.lpToken) {
            var userbalance = await getUserBalance(props.datas.lpToken, accountInfo)

            setUserTokenBalance(userbalance);
        }
    }

    const approveFunction = async (data) => {
        if (data) {
            const approval = await checkIsApproved(accountInfo, data ? data : tokenInfo.addres);
            setIsApproved(approval);
        }
    }
    const getData = async () => {
        var poolday = ((parseInt(props?.datas?.lockedPeriod) / ONEDAYINSECONDS))
        setpoolDays(String(poolday).substring(0, 3));
        if (poolday <= 0) {
            setStatus(true)
        }


        var getuser = await getUser(props.pid, accountInfo)
        const datee = new Date(parseInt(getuser?.userLockedPeriod) * 1000);
        const unstakeDate = datee.toLocaleDateString();
        console.log("unstakeDate", unstakeDate)
        //   if(getuser.userLockedPeriod > 0)
        //   setuserstatedate(unstakeDate)

        var TokenInfo = await UseTokenInfo(props.datas.lpToken)
        setTokenInfo(TokenInfo);
        setUserStakedAmount(getuser.amount);

        var UserReward = await getUserReward(props.pid, accountInfo);
        const dataF = (parseInt(UserReward) + parseInt(getuser.pendingRewards)) / 10 ** parseInt(TokenInfo?.decimals)
        setPending(dataF.toFixed(5))
    }
    return (

        <div className='walletmodal'>
            {console.log("Error", Error, props)}
            <Modal show={stakemodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className='mt-5' onClick={() => props.onDismiss()}></Modal.Header>
                <Modal.Body className='px-3 px-sm-5 stakemodals'>
                    <h1 className='mainhead text-center alltext mb-4'>Sta<span className='mainsub'>king</span></h1>
                    <br />
                    <div className='row'>
                        <div className='col-lg-8 col-xl-7 col-xxl-7 mx-auto'>
                            <div className='d-flex justify-content-between'><p className='grayclr'>Token</p><p className='whtsclr'>{(props?.tokenInfo && props?.tokenInfo?.name) || ""}</p></div>
                            <div className='d-flex justify-content-between'><p className='grayclr'>Your COOL Token balance</p><p className='whtsclr'>{userTokenBalance} {(props?.tokenInfo && props?.tokenInfo?.symbol) || ""}</p></div>
                            <div className='d-flex justify-content-between'><p className='grayclr'>Minimum COOL Stake</p><p className='whtsclr'>{MinStakeAmt} {(props?.tokenInfo && props?.tokenInfo?.symbol) || ""}</p></div>


                            <div className='pos docback'>
                                <p className='fname max mt-2'>{(props?.tokenInfo && props?.tokenInfo?.name) || ""}</p>
                                {/* <input type='text' className='form-control' value={Stakeamt} onChange={(e) => { setDeposit(e.target.value)}} /> */}
                                <input type='text' className='form-control pr120' value={deposit} onChange={(e) => { OnInputChange(e) }} />

                                {Error?.Stake && <div className='error-msg' style={{ color: 'red' }}>{Error?.Stake}</div>}

                            </div><br />

                            <div className='d-flex justify-content-between pb-3'>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt * 0.25, 25)}>25%</Button>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt * 0.50, 50)}>50%</Button>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt * 0.75, 75)}>75%</Button>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt, 100)}>100%</Button>
                            </div>

                            <div style={sliderStyles}>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={deposit}
                                    onChange={handleOnChange}
                                    tooltip={false}
                                />
                                {/* <p className='fname'>({volumess}%)</p> */}
                            </div>

                            <div className='d-flex align-items-center justify-content-center pt-3 pb-5'>
                                <Button className='themesbtn' onClick={() => depositTokens()}><span>Stake</span></Button>
                                {/* <Button className='themesbtn ms-3'><span>Get CGPT</span></Button> */}
                            </div>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Stakemodal;
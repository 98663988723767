import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import Layerimg from "../images/spropad/layer.png";
import checkimg from "../images/spropad/checkbox.png";
import { Link } from 'react-router-dom';
import finalimg from '../images/spropad/finalimg.png';
import Datepickers from "../Components/datepickers"
import toast from 'react-hot-toast';
import { addtokenrequest } from '../landingaction/landing';

function Tokenrequest() {

    const [tokenstep, setTokenstep] = useState(1);
    const [ido , setIdo] = useState([]);
    const [name , setname] = useState("");
    const [website , setWebsite] = useState("");
    const [socialmedia , setSocialmedia] = useState("");
    const [description ,setDescription] = useState("");
    const [tokenname , settokenname] = useState("");
    const [tokensymbol , setTokensymbol] = useState("");
    const [tokenaddress , setTokenaddress] = useState("");
    const [tokenutility , setTokenutility] = useState("");
    const [tokenomics , setTokenomics] = useState("");
    const [date , setDate] = useState("");
    const [fundinggoal , setFundinggoal] = useState("");
    const [projectteam , setProjectteam] = useState("");
    const [projectadvisor , setProjectadvisor] =  useState("");
    const [investors , setInvestors] = useState("");
    const [fundraised , setFundraised] = useState("");
    const [projectroadmap , setProjectroadmap] = useState("");
    const [otherlaunchpads , setOtherlaunchpads] = useState("");
    const [marketmaker , setmarketmaket] = useState("");
    const [tokenlisting , setTokenlisting] = useState("");
    const [additionalsupport , setAdditionalsupport] = useState([]);
    const [contactinformation , setContactinformation] = useState("");
    const [additionalinformation , setAdditionalinformation] = useState("");
    const [error , setError] = useState({});
 
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [tokenstep]);

    const handlecheckbox = (e , val) => {console.log("handle checkbox");
        let nwarr = ido
        const index = nwarr.indexOf(val);
        if (index > -1) { // only splice array when item is found
            nwarr.splice(index, 1); // 2nd parameter means remove one item only
          }
        else{
            nwarr.push(val)
        }
        console.log("handle checkbox" , nwarr);
        setIdo(nwarr);
    }

    const handlecheckbox2 = (e , val) => {console.log("handle checkbox");
        let nwarr = additionalsupport
        const index = nwarr.indexOf(val);
        if (index > -1) { // only splice array when item is found
            nwarr.splice(index, 1); // 2nd parameter means remove one item only
          }
        else{
            nwarr.push(val)
        }
        console.log("handle checkbox" , nwarr);
        setAdditionalsupport(nwarr);
        if(nwarr?.length > 0){
            setError({...error ,  ["additionalsupport"] : ""})
        }
        else{
            setError({...error ,  ["additionalsupport"] : "Field is required"})
        }
    }

    const handlestep1 = () => {
        // debugger
        let errordata = error
        if(name){
           errordata = {...errordata ,  ["name"] : ""}
        }
        else{
            errordata = {...errordata ,  ["name"] : "Field is required"}
            // setError({...error ,  ["name"] : "Field is required"})
        }

        if(website){
            errordata = {...errordata ,  ["website"] : ""}
            // setError({...error ,  ["website"] : ""})
        }
        else{
            errordata = {...errordata ,  ["website"] : "Field is required"}
            // setError({...error ,  ["website"] : "Field is required"})
        }

        if(socialmedia){
            errordata = {...errordata ,  ["social"] : ""}
            // setError({...error ,  ["social"] : ""})
        }
        else{
            errordata = {...errordata ,  ["social"] : ""}
            // setError({...error ,  ["social"] : "Field is required"})
        }

        if(description){
            errordata = {...errordata ,  ["description"] : ""}
            // setError({...error ,  ["description"] : ""})
        }
        else{
            errordata = {...errordata ,  ["description"] : "Field is required"}
            // setError({...error ,  ["description"] : "Field is required"})
        }
        setError(errordata);
        if(name && website && socialmedia && description && !error?.name && !error?.website && !error?.social && !error?.descrtiption){
            setTokenstep(3)
        }
    }

    const handlestep2 = () => {
        let errordata = error
        if(tokenname){
           errordata = {...errordata ,  ["tokenname"] : ""}
        }
        else{
            errordata = {...errordata ,  ["tokenname"] : "Field is required"}
            // setError({...error ,  ["name"] : "Field is required"})
        }


        if(tokensymbol){
            errordata = {...errordata ,  ["tokensymbol"] : ""}
         }
         else{
             errordata = {...errordata ,  ["tokensymbol"] : "Field is required"}
             // setError({...error ,  ["name"] : "Field is required"})
         }

         if(tokenaddress){
            errordata = {...errordata ,  ["tokenaddress"] : ""}
         }
         else{
             errordata = {...errordata ,  ["tokenaddress"] : "Field is required"}
             // setError({...error ,  ["name"] : "Field is required"})
         }

         if(tokenutility){
            errordata = {...errordata ,  ["tokenutility"] : ""}
         }
         else{
             errordata = {...errordata ,  ["tokenutility"] : "Field is required"}
             // setError({...error ,  ["name"] : "Field is required"})
         }

         if(tokenomics){
            errordata = {...errordata ,  ["tokenomics"] : ""}
         }
         else{
             errordata = {...errordata ,  ["tokenomics"] : "Field is required"}
             // setError({...error ,  ["name"] : "Field is required"})
         }

        setError(errordata);
        if(tokenname && !error?.tokenname && tokensymbol && !error?.tokensymbol && tokenaddress && !error?.tokenaddress && tokenutility && !error?.tokenutility
            && tokenomics && !error?.tokenomics){
            setTokenstep(4)
        }
    }


    const handlestep3 = () => {
        let errordata = error
        if(date){
           errordata = {...errordata ,  ["date"] : ""}
        }
        else{
            errordata = {...errordata ,  ["date"] : "Field is required"}
        }

        if(fundinggoal){
            errordata = {...errordata ,  ["fundinggoal"] : ""}
         }
         else{
             errordata = {...errordata ,  ["fundinggoal"] : "Field is required"}
         }

         if(projectteam){
            errordata = {...errordata ,  ["projectteam"] : ""}
         }
         else{
             errordata = {...errordata ,  ["projectteam"] : "Field is required"}
         }

         if(projectadvisor){
            errordata = {...errordata ,  ["projectadvisor"] : ""}
         }
         else{
             errordata = {...errordata ,  ["projectadvisor"] : "Field is required"}
         }

         if(investors){
            errordata = {...errordata ,  ["investors"] : ""}
         }
         else{
             errordata = {...errordata ,  ["investors"] : "Field is required"}
         }



        setError(errordata);
        if(date && !error?.date && fundinggoal && !error?.fundinggoal && projectteam && !error?.projectteam && projectadvisor && !error?.projectadvisor && 
            investors && !error?.investors){
            setTokenstep(5)
        }
    }

    const handlestep4 = () => {
        let errordata = error
        if(fundraised){
           errordata = {...errordata ,  ["fundraised"] : ""}
        }
        else{
            errordata = {...errordata ,  ["fundraised"] : "Field is required"}
        }

        if(projectroadmap){
            errordata = {...errordata ,  ["projectroadmap"] : ""}
         }
         else{
             errordata = {...errordata ,  ["projectroadmap"] : "Field is required"}
         }

         if(otherlaunchpads){
            errordata = {...errordata ,  ["otherlaunchpads"] : ""}
         }
         else{
             errordata = {...errordata ,  ["otherlaunchpads"] : "Field is required"}
         }

         if(marketmaker){
            errordata = {...errordata ,  ["marketmaker"] : ""}
         }
         else{
             errordata = {...errordata ,  ["marketmaker"] : "Field is required"}
         }

         if(tokenlisting){
            errordata = {...errordata ,  ["tokenlisting"] : ""}
         }
         else{
             errordata = {...errordata ,  ["tokenlisting"] : "Field is required"}
         }

        setError(errordata);
        if(fundraised && !error?.fundraised && projectroadmap && !error?.projectroadmap && otherlaunchpads && !error?.otherlaunchpads && marketmaker && !error?.marketmaker && 
                tokenlisting && !error?.tokenlisting){
            setTokenstep(6)
        }
    }

    const handlestep5 = async() => {
        let errordata = error
        if(additionalsupport?.length > 0){
           errordata = {...errordata ,  ["additionalsupport"] : ""}
        }
        else{
            errordata = {...errordata ,  ["additionalsupport"] : "Field is required"}
        }

        if(contactinformation){
            errordata = {...errordata ,  ["contactinformation"] : ""}
         }
         else{
             errordata = {...errordata ,  ["contactinformation"] : "Field is required"}
         }

         if(additionalinformation){
            errordata = {...errordata ,  ["additionalinformation"] : ""}
         }
         else{
             errordata = {...errordata ,  ["additionalinformation"] : "Field is required"}
         }



         setError(errordata);
        if(additionalsupport?.length > 0 && !error?.additionalsupport && contactinformation && !error?.contactinformation && additionalinformation &&
            !error?.additionalinformation){
            // setTokenstep(6)
            await handlesubmit();
        }
    }


const handlesubmit = async() => { console.log("handle submit");
    let formDatas = {
        ido : ido,
        name : name,
        "website": website,
        socialmedia : socialmedia,
        "Description": description,
        "tokencontractaddress": tokenaddress,
        "tokenname": tokenname,
        "tokensymbol": tokensymbol,
        tokenutility : tokenutility,
        tokenomics : tokenomics,
        "starttime": date,
        fundinggoal : fundinggoal,
        projectteam : projectteam,
        projectadvisor : projectadvisor,
        investors : investors,
        fundraised : fundraised,
        projectroadmap : projectroadmap,
        otherlaunchpads : otherlaunchpads,
        marketmaker : marketmaker,
        tokenlisting : tokenlisting,
        additionalsupport :additionalsupport,
        contactinformation :contactinformation,
        additionalinformation:additionalinformation
    }
    let resp = await addtokenrequest(formDatas);
    console.log("reereee", resp);
    if (resp?.data?.data?.status) {
        toast.success(resp.data.data.message)

        setTokenstep(7)
        // setAlltokenrequest(resp.data.data.data)

    }
    else return toast.error(resp.data.message)
}


console.log("Error" , error);
    return (

        <div className='all'>
            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>
                <div className='container'>
                    <div className='profileback tokenreqpage headtext pos'>

                        {tokenstep == 1 &&
                            <>
                                <h1 className='mainhead text-left alltext mb-4'>Project <span className='mainsub'> onboarding form</span></h1>
                                <div className='row pt-5'>
                                    <div className='col-md-7'>
                                        <div className='docback px-3 px-sm-4 py-5'>

                                            <h5 className='fname'>IDO or/and incubation*</h5>
                                            <p className='grayclr f-16 mb-4'>Indicate in which service(s) you are interested to take part in.</p>

                                            <div className="form-check mb-4">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handlecheckbox(e , "IDO")} />
                                                <label className="form-check-label" for="flexCheckDefault">  IDO</label>
                                            </div>
                                            <div className="form-check mb-4">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                onChange={(e) => handlecheckbox(e , "INCUBATION")} 
                                                />
                                                <label className="form-check-label" for="flexCheckDefault"> Incubation</label>
                                            </div>
                                            <div className="form-check mb-4">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                onChange={(e) => handlecheckbox(e , "ACCELERATION")} 
                                                />
                                                <label className="form-check-label" for="flexCheckDefault"> Acceleration</label>
                                            </div>
                                            <div className="form-check mb-4">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                onChange={(e) => handlecheckbox(e , "MARKETING")} 
                                                />
                                                <label className="form-check-label" for="flexCheckDefault"> Marketing</label>
                                            </div>
                                            {/* <div className="form-check mb-4">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                    onChange={(e) => handlecheckbox(e , "MARKETING")} checked = {ido?.includes("MARKETING")}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault"> Other</label>
                                            </div> */}
                                        </div>



                                    </div>
                                    <div className='col-md-5'>
                                        <img src={Layerimg} className='img-fluid d-none d-md-block' />
                                    </div>
                                </div>
                                <Button className='themesbtn mx-auto mt-5' onClick={() => {
                                    if(ido?.length){
                                        setTokenstep(2)
                                    }
                                    else{
                                        toast.error("Please fill all the fields");
                                    }
                                }
                                    }><span>Next</span></Button>
                            </>
                        }

                        {tokenstep == 2 &&
                            <>
                                <h1 className='mainhead text-left alltext mb-4'>Project <span className='mainsub'> onboarding form</span></h1>

                                <div className='row pt-5 align-items-center'>
                                    <div className='col-md-7'>
                                        <div className='docback px-3 px-sm-4 py-5'>
                                            <div className='row'>
                                                <div className='col-xl-11 col-xxl-9'>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Name<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Enter the name of your project.</p>
                                                        <input type='text' className='form-control' placeholder='Abc'  value={name}
                                                        onChange={(e)=> {
                                                            setname(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["name"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["name"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.name}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Website<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Provide the website or official online presence of your project.</p>
                                                        <input type='text' className='form-control' placeholder='https://' 
                                                        value={website}
                                                        onChange={(e)=> {
                                                            setWebsite(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["website"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["website"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.website}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Social Media<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Provide links to your project's social media channels</p>
                                                        <input type='text' className='form-control' placeholder='https://' value={socialmedia}
                                                        onChange={(e)=> {
                                                            setSocialmedia(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["social"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["social"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.social}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Project Description<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Briefly describe your project, its purpose, and key features. Please add deck, whitepaper and other relevant material</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' 
                                                        value={description}
                                                        onChange={(e)=> {
                                                            setDescription(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["description"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["description"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                    
                                                        <span className="error_msg">{error?.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <img src={Layerimg} className='img-fluid d-none d-md-block' />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-center pt-5'>
                                    <Button className='themesbtn' onClick={() => setTokenstep(1)}><span>Back</span></Button>
                                    <Button className='themesbtn ms-3' onClick={() => handlestep1()}><span>Next</span></Button>
                                </div>

                            </>
                        }
                        {tokenstep == 3 &&
                            <>
                                <h1 className='mainhead text-left alltext mb-4'>Project <span className='mainsub'> onboarding form</span></h1>

                                <div className='row pt-5 align-items-center'>
                                    <div className='col-md-7'>
                                        <div className='docback px-3 px-sm-4 py-5'>
                                            <div className='row'>
                                                <div className='col-xl-11 col-xxl-9'>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Token Name<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Specify the name of your project's token</p>
                                                        <input type='text' className='form-control' placeholder='Abc' value={tokenname}
                                                        onChange={(e)=> {
                                                            settokenname(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["tokenname"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["tokenname"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.tokenname}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Token Symbol<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Provide the symbol or ticker associated with your token</p>
                                                        <input type='text' className='form-control' value={tokensymbol} 
                                                        onChange={(e)=> {
                                                            setTokensymbol(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["tokensymbol"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["tokensymbol"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.tokensymbol}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Token Contract Address</h5>
                                                        <p className='grayclr f-16 mb-2'>Provide liEnter the contract address of your token on the blockchain.(If available)</p>
                                                        <input type='text' className='form-control' value={tokenaddress} 
                                                        onChange={(e)=> {
                                                            setTokenaddress(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["tokenaddress"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["tokenaddress"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.tokenaddress}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Token Utility<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Define the use cases or utilities of your project token</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' value={tokenutility}
                                                        onChange={(e)=> {
                                                            setTokenutility(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["tokenutility"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["tokenutility"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.tokenutility}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Tokenomics<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Share Details About The Tokenomics Of Your Project, Including Token Supply, Distribution, And Any Token Locks Or Vesting Periods.[Link If Possible)</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' value={tokenomics}
                                                        onChange={(e)=> {
                                                            setTokenomics(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["tokenomics"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["tokenomics"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.tokenomics}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <img src={Layerimg} className='img-fluid d-none d-md-block' />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-center pt-5'>
                                    <Button className='themesbtn' onClick={() => setTokenstep(2)}><span>Back</span></Button>
                                    <Button className='themesbtn ms-3' onClick={() => handlestep2()}><span>Next</span></Button>
                                </div>
                            </>
                        }
                        {tokenstep == 4 &&
                            <>
                                <h1 className='mainhead text-left alltext mb-4'>Project <span className='mainsub'> onboarding form</span></h1>

                                <div className='row pt-5 align-items-center'>
                                    <div className='col-md-7'>
                                        <div className='docback px-3 px-sm-4 py-5'>
                                            <div className='row'>
                                                <div className='col-xl-11 col-xxl-9'>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>IDO Date<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Enter a preferred date for an Initial DEX Offering (IDO)</p>
                                                        <Datepickers onChange = {(date) => {
                                                            setDate(date);
                                                            if(date){
                                                                setError({...error ,  ["date"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["date"] : "Field is required"})
                                                            }
                                                            }}/> 
                                                            <span className="error_msg">{error?.date}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Funding Goals<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>State the desired funding goals for your IDO and how much you....</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text'
                                                        onChange={(e)=> {
                                                            setFundinggoal(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["fundinggoal"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["fundinggoal"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.fundinggoal}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Project Team<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Provide information about your core team members, including ........</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' value={projectteam}
                                                        onChange={(e)=> {
                                                            setProjectteam(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["projectteam"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["projectteam"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.projectteam}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Project Advisors<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Specify Any Notable Advisors Or Influencers Associated With Your Project</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' value={projectadvisor}
                                                        onChange={(e)=> {
                                                            setProjectadvisor(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["projectadvisor"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["projectadvisor"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                         <span className="error_msg">{error?.projectadvisor}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Investors</h5>
                                                        <p className='grayclr f-16 mb-2'>Provide a list of Current Investors</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' value={investors}
                                                        onChange={(e)=> {
                                                            setInvestors(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["investors"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["investors"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.investors}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <img src={Layerimg} className='img-fluid d-none d-md-block' />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-center pt-5'>
                                    <Button className='themesbtn' onClick={() => setTokenstep(3)}><span>Back</span></Button>
                                    <Button className='themesbtn ms-3' onClick={() => handlestep3()}><span>Next</span></Button>
                                </div>
                            </>
                        }

                        {tokenstep == 5 &&
                            <>
                                <h1 className='mainhead text-left alltext mb-4'>Project <span className='mainsub'> onboarding form</span></h1>

                                <div className='row pt-5 align-items-center'>
                                    <div className='col-md-7'>
                                        <div className='docback px-3 px-sm-4 py-5'>
                                            <div className='row'>
                                                <div className='col-xl-11 col-xxl-9'>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Funds Raised<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Specify the amount of funds your project has raised</p>
                                                        <input type='text' className='form-control' placeholder='' value={fundraised} 
                                                        onChange={(e)=> {
                                                            setFundraised(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["fundraised"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["fundraised"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                        <span className="error_msg">{error?.fundraised}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Project Roadmap<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Provide an overview of your project's development roadmap,</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' value={projectroadmap}
                                                        onChange={(e)=> {
                                                            setProjectroadmap(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["projectroadmap"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["projectroadmap"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.projectroadmap}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Other Launchpads</h5>
                                                        <p className='grayclr f-16 mb-2'>Provide a list of other launchpads if you have already confirmed them.</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' 
                                                        value={otherlaunchpads}
                                                        onChange={(e)=> {
                                                            setOtherlaunchpads(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["otherlaunchpads"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["otherlaunchpads"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.otherlaunchpads}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Market Maker</h5>
                                                        <p className='grayclr f-16 mb-2'>Specify Whether You Have A Confirmed Market Maker. If Yes, Please Prov....</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text'
                                                        value={marketmaker}
                                                        onChange={(e)=> {
                                                            setmarketmaket(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["marketmaker"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["marketmaker"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.marketmaker}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Token Listing<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Provide a list of Current Investors</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text' 
                                                        value={tokenlisting}
                                                        onChange={(e)=> {
                                                            setTokenlisting(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["tokenlisting"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["tokenlisting"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.tokenlisting}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <img src={Layerimg} className='img-fluid d-none d-md-block' />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-center pt-5'>
                                    <Button className='themesbtn' onClick={() => setTokenstep(4)}><span>Back</span></Button>
                                    <Button className='themesbtn ms-3' onClick={() => handlestep4()}><span>Next</span></Button>
                                </div>
                            </>
                        }

                        {tokenstep == 6 &&
                            <>
                                <h1 className='mainhead text-left alltext mb-4'>Project <span className='mainsub'> onboarding form</span></h1>

                                <div className='row pt-5'>
                                    <div className='col-md-7'>
                                        <div className='docback px-3 px-sm-4 py-5'>
                                            <div className='row'>
                                                <div className='col-xl-11 col-xxl-9'>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Additional Support</h5>
                                                        <p className='grayclr f-16 mb-4'>Let us know if you require any additional support by selecting the services listed</p>

                                                        <div className="form-check mb-4">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => handlecheckbox2(e , "Tokenomics")}/>
                                                            <label className="form-check-label" for="flexCheckDefault"> Tokenomics</label>
                                                        </div>
                                                        <div className="form-check mb-4">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                            onChange={(e) => handlecheckbox2(e , "Fundraising (Seed / Private Round)")}
                                                            />
                                                            <label className="form-check-label" for="flexCheckDefault"> Fundraising (Seed / Private Round)</label>
                                                        </div>
                                                        <div className="form-check mb-4">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                                            onChange={(e) => handlecheckbox2(e , "KOLs Marketing")}
                                                            />
                                                            <label className="form-check-label" for="flexCheckDefault"> KOLs Marketing</label>
                                                        </div>
                                                        <div className="form-check mb-4">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                            onChange={(e) => handlecheckbox2(e , "Advisory Services / Incubation")}
                                                            />
                                                            <label className="form-check-label" for="flexCheckDefault"> Advisory Services / Incubation</label>
                                                        </div>
                                                        {/* <div className="form-check mb-4">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
                                                            onChange={(e) => handlecheckbox2(e , "Advisory Services / Incubation")}
                                                            />
                                                            <label className="form-check-label" for="flexCheckDefault"> Other</label>
                                                        </div> */}
                                                        <span className="error_msg">{error?.additionalsupport}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Contact Information<span className='grnclr'>*</span></h5>
                                                        <p className='grayclr f-16 mb-2'>Share the contact details of the person responsible for project onboard...</p>
                                                        <input type='text' className='form-control' placeholder='' 
                                                        value={contactinformation}
                                                        onChange={(e)=> {
                                                            setContactinformation(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["contactinformation"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["contactinformation"] : "Field is required"})
                                                            }
                                                        }}
                                                        />
                                                         <span className="error_msg">{error?.contactinformation}</span>
                                                    </div>
                                                    <div className='mb-4'>
                                                        <h5 className='fname'>Additional Information</h5>
                                                        <p className='grayclr f-16 mb-2'>If There Is Any Other Information You Would Like To Share Or Specific...</p>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder='Text'
                                                        value={additionalinformation}
                                                        onChange={(e)=> {
                                                            setAdditionalinformation(e?.target?.value);
                                                            if(e?.target?.value?.length > 0){
                                                                setError({...error ,  ["additionalinformation"] : ""})
                                                            }
                                                            else{
                                                                setError({...error ,  ["additionalinformation"] : "Field is required"})
                                                            }
                                                        }}
                                                        ></textarea>
                                                        <span className="error_msg">{error?.additionalinformation}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='col-md-5'>
                                        <img src={Layerimg} className='img-fluid d-none d-md-block' />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-center pt-5'>
                                    <Button className='themesbtn' onClick={() => setTokenstep(5)}><span>Back</span></Button>
                                    <Button className='themesbtn ms-3' onClick={() => handlestep5()}><span>Submit</span></Button>
                                </div>
                            </>
                        }

                        {tokenstep == 7 &&
                            <>
                                <div className='row'>
                                    <div className='col-xl-10 col-xxl-8 text-center mx-auto'>
                                        <h1 className='ctext1 mb-4'>Thats it!</h1>
                                        <p className='grayclr f-16'>Lorem ipsum dolor sit amet consectetur. Cras accumsan placerat dolor amet dui mattis. Fringilla in faucibus et fermentum. Sit pellentesque sagittis donec ipsum pellentesque ullamcorper tempus quam. Odio proin commodo suscipit massa libero ut ac egestas nisi. Elementum sit eu elementum </p>
                                        <Link to="/"><Button className='themesbtn backwebsite mx-auto mt-5 mb-4'><span>Back to Website</span></Button></Link>
                                        <img src={finalimg} className='img-fluid'/>
                                    </div>
                                </div>


                            </>
                        }
                    </div>

                    <p className='fname my-5 mx-2 mx-sm-4 fw-300'>Disclaimer :  the information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. we accept no liability for any loss accessioned  to any person acting     or refraining from action as a result of any material provided or published. </p>


                </div>
            </div>
            <Footer />

        </div>

    )
}

export default Tokenrequest;
import React, { useState, useEffect } from 'react';
import { Container, Button, Tab, Nav, Col, Row, Accordion, Card } from 'react-bootstrap';
import minuss from "../images/spropad/minuss.png";
import plus from "../images/spropad/pluss.png";
import sideline from "../images/spropad/sideline.png";
import { FaqhideList, Faqtablist } from '../landingaction/landing';

function Faqhome() {


    const [accordionOpen, setAccordionOpen] = useState(1);

    const toggleAccordion = (i) => {
        setAccordionOpen(i);
    };

    const [accordionall, setAccordionall] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 },
    ])

    const [Allfaq, setAllfaq] = useState([])
    const [Faqtab, setFaqtab] = useState([])

    console.log("FaqtabFaqtab", Faqtab);

    useEffect(() => {
        getFaqall()
        Faqtabvalue('basic')
    }, [])

    const getFaqall = async () => {
        let get = await FaqhideList();
        console.log("setAllfaq", get, get?.data?.data?.data);
        if (get?.data?.data?.status == true) {
            setAllfaq(get?.data?.data?.data);
            console.log("faqdata", get?.data?.data?.data)
        }
    }

    const Faqtabvalue = async (data) => {
        console.log("dtasssss", data);
        if (data) {
            let get = await Faqtablist({ data });
            console.log("setFaqtab", get, get?.data?.data?.data);
            if (get?.data?.data?.status == true) {
                setFaqtab(get?.data?.data?.data);
                console.log("setFaqtab", get?.data?.data?.data)
            }
        }


    }

    return (

        <div className='pos'>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills" className="flex-row allflexrow justify-content-md-center  mb-5">
                            <Nav.Item className='mb-3 mb-sm-0'>
                                <Nav.Link eventKey="first" onClick={() => { Faqtabvalue(('Basic').toString().replace(/\s/g, '').toLowerCase()) }}><span>Basic</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='mb-3 mb-sm-0'>
                                <Nav.Link eventKey="second" onClick={() => { Faqtabvalue(('IDO Allocation').toString().replace(/\s/g, '').toLowerCase()) }}><span>IDO Allocation</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third" onClick={() => { Faqtabvalue(('Tiers & Staking').toString().replace(/\s/g, '').toLowerCase()) }}><span>Tiers & Staking</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="four" onClick={() => { Faqtabvalue(('For Projects').toString().replace(/\s/g, '').toLowerCase()) }}><span>For Projects</span></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={10} md={10} lg={9} className='mx-auto pos'>
                        <img src={sideline} className='sideline' />
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Accordion defaultActiveKey={1}>
                                    {Faqtab.map((val, i) =>
                                        <Accordion.Item onClick={() => toggleAccordion(i + 1)} eventKey={i + 1}>
                                            <Accordion.Header>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <p className='mb-0'>{val?.question}</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p className='subpara'><div dangerouslySetInnerHTML={{ __html: val?.answer }}></div></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                </Accordion>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Accordion defaultActiveKey={1}>
                                    {Faqtab.map((val, i) =>
                                        <Accordion.Item onClick={() => toggleAccordion(i + 1)} eventKey={i + 1}>
                                            <Accordion.Header>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <p className='mb-0'>{val?.question}</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p className='subpara'><div dangerouslySetInnerHTML={{ __html: val?.answer }}></div></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                </Accordion>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <Accordion defaultActiveKey={1}>
                                    {Faqtab.map((val, i) =>
                                        <Accordion.Item onClick={() => toggleAccordion(i + 1)} eventKey={i + 1}>
                                            <Accordion.Header>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <p className='mb-0'>{val?.question} </p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p className='subpara'><div dangerouslySetInnerHTML={{ __html: val?.answer }}></div></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                </Accordion>
                            </Tab.Pane>
                            <Tab.Pane eventKey="four">
                                <Accordion defaultActiveKey={1}>
                                    {Faqtab.map((val, i) =>
                                        <Accordion.Item onClick={() => toggleAccordion(i + 1)} eventKey={i + 1}>
                                            <Accordion.Header>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <p className='mb-0'>{val?.question}</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p className='subpara'><div dangerouslySetInnerHTML={{ __html: val?.answer }}></div></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                </Accordion>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>

    )
}

export default Faqhome




import React, { useState, useEffect } from 'react';
import { Container, Button, Tab, Nav, Col, Row, Modal } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import config from "../Config/config"
import { getAccount } from "../Hooks/useAccounts";

//sonia
import { UnStake, approveContract, getUserBalance } from '../Hooks/useStake';

function Unstakemodal(props) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("propsvaluessdssddd", props)
    const [unstakemodal, setUnstakemodal] = useState(true);
    const [volumess, setvolumess] = useState(20)
    console.log("volumess", volumess)

    const [accountInfo, setAccountInfo] = useState(getAccount());
    // const [isApproved, setIsApproved] = useState(false);
    // const [Withdraw, setWithdraw] = useState(true)
    // console.log("withdrawing", Withdraw);
    const [withdrawAmount, setwithdrawAmount] = useState("0");
    console.log("withdrawAmount", withdrawAmount)

    const [isMax, setIsMax] = useState(false);
    // const [Maxvalwithdraw, setmaxvalwithdraw] = useState("0");
    const [Maxwithdraw, setmaxwithdraw] = useState("0");
    
    const handleOnChange = (value) => {
        setvolumess(value)
    }
    const sliderStyles = {
        pointerEvents: 'none', // Disables all pointer events
        opacity: 0.01 // Optionally, reduce opacity to visually indicate it's non-interactive
    };
    //sonia
    const MinStakeAmt = config.MinStakeAmt;
    const [Error, setError] = useState({});
    const [Stakeamt, setStakeamt] = useState("");
    console.log("Stakeamt", Stakeamt)

    const [userTokenBalance, setUserTokenBalance] = useState("");
    console.log("userTokenBalance", userTokenBalance)

    useEffect(() => {
        if (accountInfo) {
            // getData()

            // if (props.datas.lpToken && props.datas.lpToken) {
            //     approveFunction(props.datas.lpToken);
            // }
            getUserTokenBalance();

        }
 


    }, [accountInfo])

    useEffect(() => {

        setAccountInfo(getAccount());

    }, []);

    const getUserTokenBalance = async () => {

        if (props.datas.lpToken) {
            var userbalance = await getUserBalance(props.datas.lpToken, accountInfo)

            setUserTokenBalance(userbalance);
        }
    }

    function handlePercentageClick(percent, val) {
        setError({});
        console.log("percent,val", percent, val);
        if (parseFloat(percent) >= parseFloat(MinStakeAmt)) {
            setwithdrawAmount(percent)
            setError({});
        } else {
            setwithdrawAmount(percent)
            setError({ Stake: `Input amount should be greater than or equal to ${MinStakeAmt}` });
        }
        setvolumess(val)

    }
    const BalanceAmt = (props.totalDeposit &&
        (
            parseInt(props.totalDeposit) /
            10 ** parseInt(props.tokenInfo && props.tokenInfo?.decimals)
        ).toFixed(2)) ||
        0;

    function OnInputChange(e) {
        setError({});
        let inputValue = e.target.value;
        if (parseFloat(inputValue) >= parseFloat(MinStakeAmt) &&  parseFloat(inputValue) <= (parseInt(props?.totalDeposit) /10 ** parseInt(props?.tokenInfo && props?.tokenInfo?.decimals)).toFixed(2)) {
            setwithdrawAmount(e.target.value);
            setIsMax(false);
            // setStakeamt(inputValue)0
            // setError({});

        } 
        
        else {
            setwithdrawAmount(e.target.value);
            console.log("ddddddd",e.target.value);
            if(parseFloat(inputValue) >= parseFloat(MinStakeAmt)){
                // setError({ Stake: `Input amount should be greater than or equal to ${MinStakeAmt}` });
                setError({ Stake: `Input amount should be lesser than or equal to ${(parseInt(props?.totalDeposit) /10 ** parseInt(props?.tokenInfo && props?.tokenInfo?.decimals)).toFixed(2)}` });

            }
            else if(parseFloat(inputValue) <= (parseInt(props?.totalDeposit) /10 ** parseInt(props?.tokenInfo && props?.tokenInfo?.decimals)).toFixed(2)){
                // setError({ Stake: `Input amount should be lesser than to ${parseFloat(inputValue) < (parseInt(props?.totalDeposit) /10 ** parseInt(props?.tokenInfo && props?.tokenInfo?.decimals)).toFixed(2)}` });
                setError({ Stake: `Input amount should be greater than or equal to ${MinStakeAmt}` });

            }
            setIsMax(false);
            // setStakeamt(inputValue)
            
        }
    }



    const UseWithdraw = async () => {
        const pid = props.pid;
        const decimal = props.tokenInfo.decimals;
        const amt = isMax ? Maxwithdraw : withdrawAmount;

        await UnStake(accountInfo, pid, amt, decimal, isMax).then(() => {

        })
    }

    return (

        <div className='walletmodal'>
            {console.log("Errortimezone",timezone)}
            <Modal show={unstakemodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>


                <Modal.Header closeButton className='mt-5' onClick={() => props.onDismiss()}></Modal.Header>

                <Modal.Body className='px-3 px-sm-5 stakemodals'>
                    <h1 className='mainhead text-center alltext mb-4'>Unsta<span className='mainsub'>king</span></h1>
                    <br />
                    <div className='row'>
                        <div className='col-lg-8 col-xl-7 col-xxl-7 mx-auto'>
                            <div className='d-flex justify-content-between'><p className='grayclr'>Token :</p><p className='whtsclr'>{(props?.tokenInfo && props?.tokenInfo?.name) || ""}</p></div>
                            {/* <div className='d-flex justify-content-between'><p className='grayclr'>balance Stake</p><p className='whtsclr'>{MinStakeAmt}</p></div> */}
                            <div className='d-flex justify-content-between'><p className='grayclr'>Your Withdraw Amount :</p><p className="ms-4 whtsclr mt-1 mb-0">
                                {(props?.totalDeposit &&
                                    (
                                        parseInt(props?.totalDeposit) /
                                        10 ** parseInt(props?.tokenInfo && props?.tokenInfo?.decimals)
                                    ).toFixed(2)) ||
                                    0} {(props?.tokenInfo && props?.tokenInfo?.symbol) || ""}
                            </p></div>
                            {
                                props?.totalDeposit > 0 &&
                                <div className='d-flex justify-content-between'><p className='grayclr'>Unstake available after :</p><p className="ms-4 whtsclr mt-1 mb-0">
                                    {props?.unstakeDate ? new Date(props?.unstakeDate).toString().slice(0 , 16) : new Date(props?.unstakeDate).toString().slice(0 , 16)}
                                </p></div>
                            }


                            {/* <p className="grayclr mb-0">Unstake available after : </p><p className="ms-4 whtsclr mt-1 mb-0">
                                {props.unstakeDate ? props.unstakeDate : unstakeDate}</p> */}

                            <div className='pos docback'>
                                <p className='fname max mt-2'>{(props?.tokenInfo && props?.tokenInfo?.name) || ""}</p>
                                <input type='text' className='form-control pr120' value={isMax ? Maxwithdraw / 10 ** props.tokenInfo.decimals : withdrawAmount}
                                    onChange={(e) => { OnInputChange(e) }} />
                                {/* onChange={(e) => { setwithdrawAmount(e.target.value); setIsMax(false); }} /> */}
                                {Error?.Stake && <div className='error-msg' style={{ color: 'red' }}>{Error?.Stake}</div>}

                            </div><br />
                            {/* <p className='grayclr f-16 mt-2'>{MinStakeAmt}</p> */}

                            <div className='d-flex justify-content-between pb-3'>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt * 0.25, 25)}>25%</Button>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt * 0.50, 50)}>50%</Button>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt * 0.75, 75)}>75%</Button>
                                <Button className='maxbtn' onClick={() => handlePercentageClick(BalanceAmt, 100)}>100%</Button>
                            </div>

                            <div style={sliderStyles}>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={withdrawAmount}
                                    onChange={handleOnChange}
                                    tooltip={false}
                                />
                                {/* <p className='fname'>({volumess}%)</p> */}
                            </div>

                            <div className='d-flex align-items-center justify-content-center pt-3 pb-5'>
                                {/* <Button className='themesbtn' onClick={() => approve()}><span>Unstake</span></Button> */}
                                {/* <div className='d-flex justify-content-around mb-4 mt-5'> */}
                                <Button className='themesbtn' onClick={() => props.onDismiss()}><span>Cancel</span></Button>
                                <Button className='themesbtn' onClick={() => UseWithdraw()}><span>Confirm</span></Button>
                                {/* </div> */}
                                {/* <Button className='themesbtn ms-3'><span>Get CGPT</span></Button> */}
                            </div>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>


        </div>

    )
}

export default Unstakemodal;
import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import cardsimg from "../images/spropad/cardsimg.png"
import strokeimg from "../images/spropad/cardimgvector.png"
import binance from "../images/spropad/binance.png";
import topvector from "../images/spropad/topvector.png";
import { Link } from 'react-router-dom';
//sonia
import { ONEDAYINSECONDS } from '../Config/env';
import { getAccount } from '../Hooks/useAccounts';
import { approveContract, checkIsApproved, Claim, getUser, getUserBalance, getUserReward, UseTokenInfo } from '../Hooks/useStake';

function Poolscard(props) {
console.log("propselement",props)
    const[poollist,setpoollist] = useState([
        {days:"45",apr:"1%",multi:"1x",total:"11,100,000"},{days:"90",apr:"3%",multi:"1.3x",total:"11,500,000"},{days:"180",apr:"5%",multi:"1.5x",total:"12,000,000"},{days:"365",apr:"8%",multi:"2x",total:"42,500,000"},
    ])
//sonia
const [pooldays, setpoolDays] = useState('')
console.log("pooldays",pooldays)

const[unlock, setUnlock] = useState(false)
const[Withdraw, setWithdraw] = useState(false)
const [status, setStatus] = useState(false)
const [accountInfo, setAccountInfo] = useState(getAccount());
const [userInfo,setUserInfo] = useState({});
const [userstatedate,setuserstatedate]  = useState("-")
const [tokenInfo, setTokenInfo] = useState(null);
const [userStakedAmount, setUserStakedAmount] = useState(0);
console.log("userStakedAmount",userStakedAmount)
const [pending,setPending] = useState(0)
const [yieldValue, setyield] =useState("0")

const [isApproved , setIsApproved] = useState(false); 
const [userTokenBalance, setUserTokenBalance] = useState("");

useEffect(()=>
{       
 if(accountInfo){

    getData()
        if(props?.datas?.lpToken && props?.datas?.lpToken){
        approveFunction(props?.datas?.lpToken)
      }
}

},[])

const getData = async() => {
       
    var poolday = ((parseInt(props?.datas?.lockedPeriod)/ONEDAYINSECONDS))
    console.log("pooldays",poolday)
    setpoolDays(String(poolday).substring(0,3));
    if(poolday <= 0){
        setStatus(true)
    }
    

    var getuser =  await getUser(props.pid,accountInfo)
    console.log("getuser",getuser)

    const datee = new Date(parseInt(getuser?.userLockedPeriod)*1000);
    console.log("dateee",datee)
      const unstakeDate = datee.toLocaleDateString();
      if(getuser?.userLockedPeriod > 0)
      setuserstatedate(unstakeDate)
    //   hideloader()

      var TokenInfo = await UseTokenInfo(props.datas.lpToken)
    console.log("TokenInfo",TokenInfo)

          setTokenInfo(TokenInfo);
          setUserStakedAmount(getuser.amount);

          var UserReward = await getUserReward(props.pid,accountInfo);
    console.log("UserReward",UserReward)

          const dataF = (parseInt(UserReward) + parseInt(getuser.pendingRewards)) /10**parseInt(TokenInfo?.decimals)
    console.log("dataF",dataF)

          setPending(dataF.toFixed(5))
 
          
const a = "0.05%"
const b ="2%"
const c = "5%"
const d = "0.05%"
const e ="2%"
const f = "5%"

if(props?.pid === 0)
{
setyield(a)

}if(props?.pid === 1){
setyield(b)

}if(props?.pid === 2){
setyield(c)

}
if(props?.pid === 3){
setyield(d)

}
if(props?.pid === 4){
setyield(e)

}
if(props?.pid === 5){
setyield(f)

}
}
  const approveFunction = async (data) => {
    if(data){
    const approval = await checkIsApproved(accountInfo,data?data:tokenInfo.addres);
       setIsApproved(approval);
    }
}
    return (

        <>
          <br/>
            {/* <div className='row pb-4'> */}
                {/* {poollist.map((e,i)=> */}
                <div className='col-md-6 col-xl-6 col-xxl-3 poolsmap px-xl-1'>
                <div className='poolscard tokencard pt-4 pb-4'>
                    <h2 className='whtclr text-center mt-4 pb-2'><span className='num'>{pooldays || 0} - </span><span className='dayclr'>Days Staking</span></h2>
                    <div className='padlr mt-3'>  
                        <p className='grayclr'>{Number(props?.datas?.allocPoint)/100}x Multiplier</p>                   
                        <div className='d-flex justify-content-between mt-3 borderbot'>
                            <p className='grayclr'>APR</p>
                            <p className='whtsclr'>{((props?.datas?.MetaPerBlock/10** Number(tokenInfo && tokenInfo?.decimals))*Number(pooldays)).toFixed(2)}</p>
                        </div>
                        <div className='d-flex justify-content-between mt-3 borderbot'>
                            <p className='grayclr'>Days</p>
                            <p className='whtsclr'>{pooldays || 0} days</p>
                        </div>
                        <div className='d-flex justify-content-between mt-3 borderbot'>
                        <p className='grayclr'>Total staked amount</p>
                            <p className='whtsclr'>{(userStakedAmount && (parseInt(userStakedAmount)/10**parseInt(tokenInfo && tokenInfo?.decimals)).toFixed(2)) || 0}</p>
                        </div>
                        {/* <p className='grayclr'>Time</p>  */}
                    </div>

                    <Link to="/staking"><Button className='themesbtn mt-3 mb-4 mx-auto'><span>Discover</span></Button></Link>

                </div>
                </div>
                {/* )} */}
            {/* </div> */}


        </>

    )
}

export default Poolscard;
import React, { useState, useEffect } from 'react';
import { Container, Button, Tab, Nav, Col, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//sonia
import { useWeb3 } from "../Hooks/UseWeb3";
import { getAccount } from "../Hooks/useAccounts";
import { style, iconTheme, position } from '../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import { addWhitelistMembers } from '../Hooks/useContract';


function Whitemodals(props) {

    const [whitelistmodal, setWhitelistmodal] = useState(true);

    const [address , setAddress] = useState("");
    const [amount , setAmount] = useState(0);


    

    const handleAddUserList = async() => {
        if(getAccount() == props?.saledata?.owner){
            await addWhitelistMembers(address , amount ,window.location.href.split("/")[4],getAccount());
            props.onDismiss()
        }
    }



    return (

        <div className='walletmodal'>

            <Modal show={whitelistmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className='mt-5' onClick={() => props.onDismiss()}></Modal.Header>
                <Modal.Body className='px-3 px-sm-5'>
                    <h1 className='mainhead text-center alltext mb-4'>White <span className='mainsub'>List</span></h1>
                    <div className='docback back_img pt-2 pb-3'>
                    <div className='mt-3'>
                        <p className='fname mb-1'>Owner Address</p>
                    <input type='text' className='form-control' placeholder='Owner Address' onChange={(e) => setAddress(e?.target?.value)}/>
                    </div>
                    <div className='mt-3'>
                        <p className='fname mb-1'>Amount</p>
                    <input type='number' className='form-control' min={0} placeholder='amount' onChange={(e) => setAmount(e?.target?.value)}/>
                    </div>
                    <div className='d-flex justify-content-center pt-4'><button className='btn themesbtn' 
                    disabled = {!address || !amount}
                    onClick={handleAddUserList}>Submit</button></div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Whitemodals;
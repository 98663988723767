import toast from "react-hot-toast";
// import { NATIVE_PROXY, NATIVE_PROXY_ABI, NATIVE_SALE_ABI } from "../Config/abi/NativeProxy";
// import { getWalletAddress } from "../Lib/localStorage";
import { UseTokenInfo, checkChainId, toFixedNumber } from "./useContract";
// import { useWeb3 } from "./useWeb3";
import { NATIVE_PROXY_ABI , NATIVE_SALE_ABI , NATIVE_PROXY} from "../ABI/NativeProxy";
import { useWeb3 } from "./UseWeb3";
import { ERC20_ABI } from "../ABI/erc20";
import { getAccount } from "./useAccounts";
import { iconTheme, position, style } from "./useToast";

export const UseProxySale = async () => {
    try {
        const web3 = await useWeb3();
        const chainId = await web3.eth.getChainId();
        checkChainId(chainId);
        const proxysale = NATIVE_PROXY;

        const contract = new web3.eth.Contract(NATIVE_PROXY_ABI, proxysale);
        console.log("proxysale", contract);
        return contract;
    }
    catch (err) {
        console.log("UseProxySale", err.code, err)
    }
}


export const getSaleAddress = async (tokenAddress) => {
    try {
        const proxyContract = await UseProxySale();
        const sale = await proxyContract.methods.getSale(tokenAddress).call()
        return sale;
    }
    catch (err) {
        console.log("getSaleAddress", err.code, err)
    }
}

export const getProxyOwner = async () => {
    try {
        const proxy = await UseProxySale();
        const owner = await proxy.methods.owner().call();

        return owner;
    }
    catch (err) {
        console.log("getProxyOwner", err.code, err)
    }
}

export const createNativePresale = async (token, values, setters, details, account, data, id) => {
    try {
        console.log("createsale", token, values, setters, details, account, data, id);
        let presale = await InitiatePresale(token, values, setters, details, account);
        console.log("presale", presale)
        console.log("token[0] in create presale", token[0], token, values, setters, details, account);
        let sale = await getSaleAddress(token[0]);
        console.log("sale in createpresale", sale);
        if (sale == "0x0000000000000000000000000000000000000000") {
            sale = await getSaleAddress(token[0]);
            return sale;
        }
        else {
            return sale;
        }
    }
    catch (err) {
        console.log("createPresale", err.code, err)
    }
}

export const InitiatePresale = async (token, values, setters, details, account) => {
    try {
        // debugger
        console.log("initialpresale_token", token);
        console.log("initialpresale_values", values);

        console.log("initialpresale_setters", setters);

        console.log("initialpresale_details", details);

        console.log("initialpresale_account", account);

        const proxyContract = await UseProxySale();
        let deploymentFee
        const owner = await getProxyOwner()
        if (owner !== account) {
            deploymentFee = await proxyContract.methods.getDeploymentFee().call();
        }
        else {
            deploymentFee = 0
        }
        const data = proxyContract.methods.createPresale(token, values, setters, details).send({ from: account, value: deploymentFee })
        console.log("data in initiate presale", data);
        await toast.promise(data, {
            loading: 'Creating New Presale ...',
            success: 'Presale Created Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
    }
    catch (err) {
        console.log("InitiatePresale", err.code, err)
    }
}

export const getTotalNativeSalesInfo = async () => {
    try {
        const proxy = await UseProxySale();

        let data = await proxy.methods.getTotalSales().call();
        console.log("proxy inside getTotalSales info:", data);
        const reverseArray = [...data].reverse();
        return (reverseArray);
    }
    catch (e) {
        console.log("getTotalSalesInfo", e);
    }
}

export const UseNativeSale = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(NATIVE_SALE_ABI, VALUE);
        console.log("contract", contract.methods)
        return contract;
    }
    catch (err) {
        console.log("UseSale", err)
    }
}

export const getSaleInfoCard = async (saleAddress) => {
    try {
        if (saleAddress) {
            console.log(saleAddress, 'saleAddress')
            const saleContract = await UseNativeSale(saleAddress);
            const data = await saleContract.methods.getSaleInfo().call();
            console.log("new", data);
            return data;
        }
        else {
            return {}
        }
    }
    catch (e) {
        console.log("getSaleInfoCard_err", e)
    }

}


export const getnativelaunchpaddatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0 && cards.length > start) {
            for (var i = start; i < end; i++) {
                console.log("forlop", i);
                if (cards.length > i) {
                    console.log("card[i]", cards[i], i);
                    if (cards[i]?.isdb == true || cards[i]?.isdb == "true") {
                        SaleInfoCards.push(cards[i]);

                    }
                    else {
                        var saleInfo = await getSaleInfoCard(cards[i]?._sale ? cards[i]?._sale : cards[i]?.saleaddress);

                        console.log("saleInfo2", saleInfo)
                        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                            saleInfo = { ...saleInfo, ...{ coin: "BNB" } };
                            console.log("saleInfo_saleInfo", saleInfo)

                            SaleInfoCards.push(saleInfo);
                            console.log("bnb", SaleInfoCards);
                        }
                        else {
                            const newdata = await UseTokenInfo(saleInfo.useWithToken);
                            saleInfo = { ...saleInfo, ...{ coin: newdata?.symbol } };
                            SaleInfoCards.push(saleInfo);
                        }
                    }
                }
                else {
                    console.log("other", SaleInfoCards);
                    return SaleInfoCards;
                }
            }
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    } catch (err) {
        console.log(err, "getlaunchpaddatalimit__err")
    }
}


export const GetSalePerAccount = async (account, saleAddress) => {
    console.log("User_data " ,account, saleAddress )
    try {
        const saleContract = await UseNativeSale(saleAddress);
        const userData = await saleContract.methods.getUserInfo(account).call();
        return userData;
    }
    catch (err) {
        console.log("GetSalePerAccount", err.code)
    }
}

export const claimToken = async(saleAddress,account) => {
    const saleContact = await UseNativeSale(saleAddress);
    const data = saleContact.methods.claimTokens().send({ from: account });
    await toast.promise(data, {
        loading: 'Requesting for Claim Tokens...',
        success: 'Tokens Claimed Successfully',
        error: 'Error ! When Claiming Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
    window.location.reload();
}


export const BuyNativeToken = async(saleAddress,useramount,isMax,maxNumber,account,decimal , buytoken )=>{
    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal);
    // const web3 = await useWeb3();
    // const saleaddress = NATIVETOKEN_SALE;
    // const amount = !isMax? toFixedNumber(useramount * 10**18): maxNumber ;
    // const data = web3.eth.sendTransaction({ from: account, to: saleaddress, value: amount.toString() })
    
    // await toast.promise(data, {
    //     loading: 'Making a Buy Token Request...',
    //     success: 'Bougt Token Successfully',
    //     error: 'Error ! When Buying Token',
    // }, {
    //     position:position.position,
    // style:style,
    // iconTheme: iconTheme
    // }
    // );


    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal);
    const web3 = await useWeb3();
    const saleContact = await UseNativeSale(saleAddress);
    const amount = !isMax? toFixedNumber(parseFloat(useramount)*10**18 ): maxNumber ;
    if(buytoken == "BNB")
        var data =  saleContact.methods.contribute(amount.toString() , "0x0000000000000000000000000000000000000000").send({ from: account,value: amount.toString() })
    else 
        var data =  saleContact.methods.contribute(amount.toString() , "0x0000000000000000000000000000000000000000").send({ from: account})
    // const data = web3.eth.sendTransaction({ from: account, to: saleAddress, value: amount.toString() })
    await toast.promise(data, {
        loading: 'Making a Buy Token Request...',
        success: 'Bougt Token Successfully',
        error: 'Error ! When Buying Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
    window.location.reload();
}


export const RefundUSDT = async(saleAddress) => {
    const saleContact = await UseNativeSale(saleAddress);
    const data = saleContact.methods.refund().send({ from: getAccount() });
    await toast.promise(data, {
        loading: 'Requesting for Refund Tokens...',
        success: 'Tokens Refunded Successfully',
        error: 'Error ! When Refunded Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
    window.location.reload();
}
// export const Checkaddressnative = async (address , saleaddress) => {
//     try {
//         const web3 = await useWeb3();
//         const account = getAccount();

//         let errors = {}

//         const data = web3.utils.isAddress(address)
//         console.log("checkaddress", data)
//         if (data) {
//             console.log("checkaddress")
//             let tokeninfo = await UseTokenInfo(address);
//             console.log("tokeninfo", tokeninfo);
//             const newad = web3.utils.toChecksumAddress(address);
//             const contract = new web3.eth.Contract(ERC20_ABI, newad);
//             console.log("tokeninfo", contract.methods, account, CHAINS[getChainId()].USDTADDRESS);
//             const allowance = await contract.methods.allowance(account, CHAINS[getChainId()].USDTADDRESS).call();
//             console.log("tokeninfo1", allowance);
//             const approvebutton = allowance > 0 ? true : false
//             console.log("checkaddress", tokeninfo);
//             return {
//                 isValid: true,
//                 tokeninfo: tokeninfo,
//                 allowance: approvebutton
//             }
//         }
//         else {
//             errors.tokenaddress = "Invalid address !"
//             return {
//                 isValid: false,
//                 errors: errors
//             };
//         }
//     }
//     catch (e) {
//         console.log("Checkaddress", e)
//     }
// }
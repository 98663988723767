import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import nav1 from "../images/spropad/nav1.png";
import nav2 from "../images/spropad/nav2.png";
import nav3 from "../images/spropad/nav3.png";
import { NavLink } from 'react-router-dom';

function Profilenav() {

    return (


            <div className='allnavs'>
               <NavLink to="/profile"><img src={nav1} className='img-fluid navs me-1 me-sm-2'/><span>My Profile</span></NavLink>
               <NavLink to="/mytier"><img src={nav2} className='img-fluid navs me-1 me-sm-2'/><span>My Tier</span></NavLink>
               <NavLink to="/mypools"><img src={nav3} className='img-fluid navs me-1 me-sm-2'/><span>My Pools</span></NavLink>
           </div>
         
    )
}

export default Profilenav;
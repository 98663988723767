import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Tokencard from "../Components/tokencard";
import Subheaders from "../Layouts/homesubheader";
import { IoSearchOutline } from "react-icons/io5";
import { GetLaunchpadLimit, getTotalSalesInfo, getlaunchpaddatalimit } from '../Hooks/useContract';
import Spinner from 'react-bootstrap/Spinner';


function Launchpadpage() {

    const [tokencards, setTokencards] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }
    ]);
    console.log("tokencards",tokencards);
    const [Salecard,setSalecard] = useState([])
    console.log("Salecard",Salecard);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

 const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value == 'number' && value === 0)||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0); 

    //dev muruga
    // const[count , setCount] = useState(0);
    const [proxy , setProxy] = useState([]);
    const [inititalloading , setInitialLoading] = useState(false);
    const [loading , setLoading] = useState(false);
    const [search , setSearch] = useState("");
    const [issearch , setIssearch] = useState(false);
    const [searchproxy , setSearchproxy] = useState([]);
    const [searchsale , setSearchsale] = useState([]);
    
    



useEffect(()=>{
    if(isEmpty(Salecard)){
        saledata()
    }
},[])

const saledata = async () =>{
    setInitialLoading(true)
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    setProxy(Proxydata);
    let limitproxydata = await GetLaunchpadLimit(Proxydata , 0 , Proxydata?.length  > 10 ? 10 : Proxydata?.length);
    setSalecard(limitproxydata)
    setInitialLoading(false)
}

const loadmoredata = async() => {
    try{
        setLoading(true);
        if(proxy?.length > Salecard?.length){
            let proxydata = await GetLaunchpadLimit(proxy , Salecard?.length , (proxy?.length > Salecard?.length +10) ?  (Salecard?.length+10) : proxy?.length);
           console.log("loadmore data" , proxydata);
            setSalecard(Salecard?.concat(proxydata));
        }
        setLoading(false);
    }
    catch(e){
        console.log("Error on load more data" , e);
    }
}

const searchCards = async(SaleInfoCards, search) => {
    console.log("SaleInfoCards, search" , SaleInfoCards, search);
    const filteredData = await SaleInfoCards.filter(value => ((value._name).toUpperCase()).includes(search.toUpperCase()));
    console.log("filtered data" , filteredData);
    return filteredData;
}

const  handleChange =  async(e)=>{
    // debugger
    console.log("e" , e?.target?.value);
    setInitialLoading(true);
  if((e.target.value).length > 0){
        if (e.key === 'Enter') {
        console.log("Search : ",e.target.value , proxy)
        // this.showLoader();
        const searchS = await searchCards(proxy,e.target.value);
        // this.setState({ searchProxy : searchS});
        setSearchproxy(searchS);
        const data = await GetLaunchpadLimit(searchS,0,10);
       
        console.log("Search sale : ",searchS)
        // this.setState({ searchSale: data, isSearch: true})
        setSearchsale(data);
        setIssearch(true);
        // this.hideLoader();
        }
  }else{
    setIssearch(false);
  }
  setInitialLoading(false);
}

const searchloadmoredata = async() => {
    try{
        setLoading(true);
        if(searchproxy?.length > searchsale?.length){
            let proxydata = await GetLaunchpadLimit(searchproxy , searchsale?.length , (searchproxy?.length > searchsale?.length +10) ?  (searchsale?.length+10) : searchproxy?.length);
            // setSalecard(Salecard?.concat(proxydata));
            setSearchsale(searchsale.concat(proxydata));
        }
        setLoading(false);
    }
    catch(e){
        console.log("Error on load more data" , e);
    }
}
    
    return (

        <div className='all'>

            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>

            <div className='container pb-2'>
                <div className='tokensale pt-5 pb-5'>
                    <h1 className='mainhead text-center alltext mb-4'>Project <span className='mainsub'> List</span></h1>
                    <div className='row mt-3'>
                        <div className='col-md-4 col-xl-3 mx-auto pos mt-5'>
                            <IoSearchOutline className='searchicon' />
                            <input type='search' className='form-control' placeholder='Search by pool name' 
                            // value={search}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={(e)=> handleChange(e)}
                            />
                            {/* <h5 className='proj text-center mt-4'>UPCOMING POOL IDO</h5> */}
                        </div>
                    </div>

                    <div className='row mt-2'>
                        {!issearch && (Salecard && Salecard.length > 0) ? Salecard?.map((val,index) => ( <>
                        {console.log("daattaasss",val,index)}

                            <div className='allcard col-md-12 col-lg-6 col-xl-6 col-xxl-4 mt-5'>
                                <Tokencard saleData={index}  datas={val} />
                            </div>
                          
                            </> )) : <p className='no_found'>No Pools Found</p>}       

                             {issearch && (searchsale && searchsale.length > 0) && searchsale?.map((val,index) => ( <>
                        {console.log("daattaasss",val,index)}

                            <div className='allcard col-md-12 col-lg-6 col-xl-6 col-xxl-4 mt-5'>
                                <Tokencard saleData={index}  datas={val} />
                            </div>
                          
                            </> ))}                     
                    </div>   

                      {inititalloading && <div className='d-flex justify-content-center'>
                    <span class="loader"></span>
                    </div>}

                        {proxy?.length > Salecard?.length && !inititalloading && <div className='d-flex justify-content-center'>
                            <Button className='themesbtn' variant="primary" disabled = {loading}
                            onClick={loadmoredata}
                            >
                                {loading && <Spinner
                                    className='mt-1'
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                                {loading ?  "Loading..." : "Loadmore"}
                            </Button></div>}

                            {searchproxy?.length > searchsale?.length &&   !inititalloading && <div className='d-flex justify-content-center'>
                            <Button className='themesbtn' variant="primary" disabled = {loading}
                            onClick={searchloadmoredata}
                            >
                                {loading && <Spinner
                                    className='mt-1'
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                                {loading ? "Loading..." : "Loadmore"}
                            </Button></div>}
                        </div>
                    
              
            </div>
            </div>
            <Footer />
        </div>


    )
}

export default Launchpadpage
import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Schedule from './schedule'
import { useLocation } from 'react-router';
import { BuyToken, CheckRegisterInterest, GetErc20bal, GetSalePerAccount, GetWhitelistedAmount, RefundUSDT, UseTokenInfo, approveContract, calculateBuy, checkIsApproved, claimToken, getAllocationInfo, getPresaleAllocationInfo, getSaleInfoCard, toFixedNumber } from '../Hooks/useContract';
import { getAccount } from '../Hooks/useAccounts';
import toast from 'react-hot-toast';
import { GetTotalPoints } from '../Hooks/useStake';
import { GetAllocation } from '../Hooks/UseBackenduser';

function Claiminfo() {
    const location = useLocation()
    console.log("locatedata", location);
    const records = location?.state
    console.log("recordsrecords", records);

    const [isApproved, setIsApproved] = useState(false)
    // console.log("isapproval", isapproval);
    const [userdata, setUserdata] = useState([])
    console.log("userdata", userdata);
    // const [rec, setRec] = useState([])
    // console.log("recrec", rec);
    const [buytoken, setBuytoken] = useState('')
    console.log("buytoken", buytoken);

    const [decimal, setDecimal] = useState('')
    console.log("decimal", decimal);
    const [allocatedata, setAllocatedata] = useState([])
    console.log("userclaimables", allocatedata);
    const accountinfo = localStorage.getItem("accountInfo")
    const [Max, setMax] = useState(0)

    const [saledetail , setSaledetail] = useState([]);
    const [claim , setClaim] = useState(0);
    const [approvebutton , setApprovebutton] = useState(false);
    const [ismax , setIsmax] = useState(false);
    const [userpoint , setUserpoint] = useState(0);
const [allocation , setAllocation] = useState()
const [usertiers , setUsertier] = useState();
const [usdtbal , setUsdtbal] = useState(0);
const [whitelistamount , setWhitelistamount] = useState(0);

    const [valerror , setValerror] = useState("");
    // const [userdata, setUserdata] = useState([])
    useEffect(() => {
        claimabledata();
        tokenInfo()
        if (localStorage.getItem("accountInfo")) {
            approveFunction()

        }
        fetchdata()
    }, [isApproved, accountinfo])

    // setRec(records)
    const approve = async () => {
        if (localStorage.getItem("accountInfo")) {

            let approving = await approveContract(accountinfo, records?.useWithToken,  window.location.href?.split("/")[4]);
            console.log("sdfsdfsdfr", localStorage.getItem("accountInfo"), records?.useWithToken, records?.saleAddress);

            console.log("approving", approving);
            setApprovebutton(true);
        }
    }

    const approveFunction = async () => {
        console.log("approveFunction", accountinfo, records?.useWithToken, window.location.href?.split("/")[4]);
        const approval = await checkIsApproved(accountinfo, records?.useWithToken, window.location.href?.split("/")[4]);
        console.log("approval", approval);
        setApprovebutton(approval)

    }
    const claimabledata = async () => {
        if (localStorage.getItem("accountInfo")) {
            console.log("localStorage", localStorage.getItem("accountInfo"));
            const users = await GetSalePerAccount(localStorage.getItem("accountInfo"), records?.saleAddress);
            console.log("users", users);
            let bal = await GetErc20bal();
            setUsdtbal(bal);
            if(getAccount()){
                let whitelistamt = await GetWhitelistedAmount(window.location.href?.split("/")[4])
                setWhitelistamount(whitelistamt)
            }
            
            setUserdata(users);
            console.log("record in sale" , records);

            if (records?.isClaimable && parseFloat(users?.userClaimbale)>0){
                console.log("isClaimable", records?.isClaimable);
                // await getAllocationInfo(users?.actualBalance, users?.userClaimbale, props?.salerecords?.vestingInterval, props?.salerecords?.vestingPercent, users?.initialClaim);

                const allocated = await getPresaleAllocationInfo(users?.actualBalance, users?.userClaimbale, records?.vestingInterval, records?.vestingPercent, users?.initialClaim , records?.firstClaimPercent);
                console.log("allocatedddd", allocated);
                if(allocatedata?.length <= 0){
                    setAllocatedata(allocated);
                }

            }
            if (!records?.buytype) {
                console.log("buytype", !records?.buytype);

                approveFunction(localStorage.getItem("accountInfo"), records?.useWithToken, records?.saleAddress)
                console.log("sdfsdfsdfr", localStorage.getItem("accountInfo"), records?.useWithToken, records?.saleAddress);

            }

        }
    }

    const fetchdata = async() => {
        if(getAccount()){
            let points = await GetTotalPoints(getAccount());
            setUserpoint(parseFloat(points)/10**18);
            const Proxydata = await GetAllocation();
            console.log("Proxydata", Proxydata);
            setAllocation(Proxydata?.data?.record);
            Proxydata?.data?.record?.map((data , i) => {
                if(parseFloat(data?.min) <= (parseFloat(points)/10**18)){
                    setUsertier(data?.name)
                }
            })
        }
    }

    //get BNB name
    const tokenInfo = async () => {
        let usetokenaddress = await UseTokenInfo(records?.useWithToken)
        console.log("usetokenaddress", usetokenaddress);
        GetBuytokeninfo(records?.useWithToken)
        let result = await getSaleInfoCard(window.location.href?.split("/")[4])
        setSaledetail(result);

    }


    const GetBuytokeninfo = async (value) => {
        console.log("value", value);

        if (value === "0x0000000000000000000000000000000000000000") {
            console.log("kxcnvjxch");
            setBuytoken('BNB')
            setDecimal('18')

        } else {
            const token = await UseTokenInfo(records && records?.useWithToken);
            console.log("UseTokenInfo", token);
            setBuytoken(token?.symbol)
            setDecimal(token?.decimals)


        }
    }


    const MaximumBnb = async () => {
        setMax((userdata?.bnbBalance / 10 ** 18).toFixed(4));
        setIsmax(true);
    }

    const UpcomingDiffernce = (start) => {
        console.log("starttss", start);
        try {
            console.log("returnvaluess", start, (start * 1000) - Date.now());

            return ((start * 1000) - Date.now());
        }
        catch (e) {
            console.log("UpcomingDiffernce", e);
        }
    }

    const isSaleLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }

    const Salediffernce = (end) => {
        try {
            return ((end * 1000) - Date.now());
        }
        catch (e) {
            console.log("Salediffernce", e);
        }
    }

    const calculateTokem = async(value) => {
        if(parseFloat(value) > 0){
            const tokenValue = await calculateBuy(saledetail?.saleAddress,value);
            setClaim(toFixedNumber(tokenValue/10**decimal));
            setValerror("");
        }
        else{
            // toast.error("Invalid Value")
            setValerror("Invalid Value")
        }
    }

     const isUpcoming = (start,end) =>{
        return (Date.now() < (start*1000));
    }

    const  buy = async() => {
        // debugger
        let reguser = await CheckRegisterInterest(window.location.href?.split("/")[4]);
        // setRegisteruser(reguser);
        if(!reguser){
            toast.error("Register user only can buy!");
            return
        }
        
        if(getAccount()){
            var points = await GetTotalPoints(getAccount());
            const Proxydata = await GetAllocation();
            var usertier = ""
            console.log("Proxydata", Proxydata);
            Proxydata?.data?.record?.map((data , i) => {
                if(parseFloat(data?.min) <= parseFloat(points)){
                    usertier = data?.name
                }
            })
            var payload = {
                saleaddress : saledetail?.saleAddress,
                buyamount : Max,
                walletaddress : getAccount(),
                saletype : ""
            }

            //Started sale
            if((parseFloat(saledetail?.startTime) * 1000) < Date.now()){
                //multivendor
                if (((parseFloat(saledetail?.startTime) * 1000) + 86400000)  > Date.now()) { 
                    if(usertiers == "Premier Pro" || usertiers == "Elite Pro" || usertiers == "Master Pro" || usertiers == "Ultimate Pro"){
                        if(parseFloat(Max) < (parseFloat(saledetail?.whitelistUserAmt)/10**18)){
                            payload.saletype = "Multivendor Sale"
                            await BuyToken(saledetail?.saleAddress, Max, ismax, Max, getAccount(), decimal, buytoken , payload);
                            claimabledata();
                            tokenInfo();
                        }
                        else{
                            toast.error("Exceed the limit of Multivendor sale")
                        }
                    }
                    else{
                        toast.error("You are not eligible for Multivendor Sale")
                    }
                }
                else{
                    toast.error("Multivendor sale is not Open")
                }

                //public sale
                if (((parseFloat(saledetail?.startTime) * 1000) + 86400000)  < Date.now()) { 
                    payload.saletype = "Public Sale"
                    await BuyToken(saledetail?.saleAddress, Max, ismax, Max, getAccount(), decimal, buytoken , payload);
                    claimabledata();
                    tokenInfo();
                }
                // else{
                //     toast.error("FCFS sale is not Open")
                // }
            }
            else if((((parseFloat(saledetail?.startTime) * 1000) - (86400000*7))  < Date.now()) && ((parseFloat(saledetail?.startTime) * 1000) > Date.now())){
                //private sale
                if(usertier == "Ultimate Pro" || usertier == "Master Pro"){
                    if(parseFloat(Max) < (parseFloat(saledetail?.whitelistUserAmt)/10**18)){
                        payload.saletype = "Private Sale"
                        await BuyToken(saledetail?.saleAddress, Max, ismax, Max, getAccount(), decimal, buytoken , payload);
                        claimabledata();
                        tokenInfo();
                    }
                    else{
                        toast.error("Exceed the limit of PrivateSale")
                    }
                }
                else{
                    toast.error("You are not eligible for PrivateSale")
                }
            }
            else{
                toast.error("PrivateSale is not Open")
            }
        }
    }

    const  Claimtoken = async()=>{
        await claimToken(saledetail?.saleAddress,getAccount());
        claimabledata();
        tokenInfo()
        if (localStorage.getItem("accountInfo")) {
            approveFunction()

        }
        fetchdata()
    }
    const refund = async() => {
        await RefundUSDT(window.location.href?.split("/")[4]);
    }

    return (

        <div>
            <div className='mt-2'>
                {/* <h6 className='fname ms-3'>Please wait until IDO Date & Time at 17:30, 09 Apr 2024 (GMT +05:30)</h6> */}
                <div className='row botline'>
                    <div className='col-xl-6 claimcard mt-4'>
                        <h5 className='fname mb-3'>Swap Tokens</h5>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Softcap</p><p className='whtsclr'>{saledetail && (parseInt(saledetail.softCap)/10**decimal).toFixed(2)} {buytoken}</p></div> */}
                        <div className='d-flex justify-content-between'><p className='grayclr'>Hardcap</p><p className='whtsclr'>{saledetail && (parseInt(saledetail.hardCap)/10**decimal).toFixed(2)} {buytoken}</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Pancakeswap Listing</p><p className='whtsclr'>{saledetail?.firstClaimPercent ? parseFloat(saledetail?.firstClaimPercent)/100 : 0}</p></div> */}
                        {saledetail.isPancake && <div className='d-flex justify-content-between'><p className='grayclr'>Swap Rate</p><p className='whtsclr'>{saledetail && parseFloat(saledetail?.pancakeRate)/10**18}</p></div>}
                        <div className='d-flex justify-content-between'><p className='grayclr'>Refundable Amount</p><p className='whtsclr'>{parseFloat(userdata?.userInvested)/10**18} {"  "}{buytoken}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Refund</p><p className='whtsclr'>
                            <button  className='maxbtn btn btn-primary'
                            disabled = {parseFloat(userdata?.claimEligible)*1000 < Date.now() || parseFloat(userdata?.userInvested) <=  0 || saledetail?.isClaimable}
                            onClick={() => refund()}>Request To Refund</button>
                            </p></div>

                            <div className='d-flex justify-content-between'><p className='grayclr'>Vesting Schedule</p><p className='whtsclr'>{saledetail?.firstClaimPercent ? parseFloat(saledetail?.firstClaimPercent)/100 : 0} % TGE</p></div>
                            <div className='d-flex justify-content-between'><p className='grayclr'>You can buy only</p><p className='whtsclr'>{whitelistamount ? whitelistamount : 0} {"  "}{saledetail?.symbol}</p></div>
                    </div>
                    <div className='col-xl-6 mt-4'>
                    {/* (userdata?.bnbBalance / 10 ** 18).toFixed(4) */}
                        <div className='swapbalance px-3 py-3'>
                            <div className='d-flex justify-content-between mb-3'><p className='fname mb-0'>Your Wallet Balance</p><p className='grayclr f-16 mb-0'>{usdtbal}{" "}{buytoken}</p></div>
                            <div className='pos docback'>
                                <p className='fname max max1 mb-0'>{buytoken}
                                 {/* <Button className='ms-2 maxbtn' onClick={() => MaximumBnb()}>MAX</Button> */}
                                 </p>
                                <input type='number' value={Max} className='form-control pr120' 
                                onChange={(e) => {
                                    setMax(e?.target?.value)
                                    calculateTokem(e?.target?.value)
                                }}
                                
                                />
                            </div>
                            <div className='d-flex justify-content-between mt-3'>
                                <div><p className='fname mb-2'>You will get approximately</p><h5 className='grnclr'>$SMART PRO PAD</h5></div>
                                <p className='grayclr f-16'>{claim} {saledetail?.name} </p>
                            </div>
                            <p className='text-danger'>{valerror}</p>
                            <p className='fname f-16 mt-3'>You need to Approve first before purchasing. Please set a sufficient spending cap or use the default value.</p>
                            <div className='d-flex align-items-center justify-content-center pt-2'>
                                
                                {saledetail?.buytype ? 
                                        <> 
                                        { saledetail && isSaleLive(saledetail.startTime,saledetail.endTime,saledetail.isPresaleOpen) || isUpcoming(saledetail.startTime,saledetail.endTime)? 

                                        <Button className='themesbtn'
                                         disabled={isSaleLive(saledetail.startTime,saledetail.endTime,saledetail.isPresaleOpen) }
                                         onClick={() => buy()}>BUY</Button> :
                                          ((!isSaleLive(saledetail.startTime,saledetail.endTime) ?<Button disabled={true} className="themesbtn" > Sale InActive </Button>:<Button onClick={buy} className="themesbtn" > Buy </Button>))
                                          }
                                          </> : 

<>
{!approvebutton ?
  <Button className="themesbtn" onClick={()=>{approve()}}>
     Approve
  </Button>  : 
  <>
  { saledetail && isSaleLive(saledetail.startTime,saledetail.endTime,saledetail.isPresaleOpen) || isUpcoming(saledetail.startTime,saledetail.endTime)? 
    <Button onClick={buy}
    // disabled={ saledetail && saledetail.isWhitelisted ? userdata && !(userdata.userWhitelistedAmount > 0) : false } 
    className="themesbtn">{"Buy"} with {buytoken} </Button>:
    // (isUpcoming(saleInfo.startTime,saleInfo.endTime) ? <button disabled={true} className="get-started-btn mr-2" > Upcoming </button> :
    ((!isSaleLive(saledetail.startTime,saledetail.endTime) ?<Button disabled={true} className="themesbtn" > Sale InActive </Button>:<Button onClick={buy} className="themesbtn" > Buy </Button>))
    }
    </>
  }</>
  }
                                
                                
                                
                                {/* {isApproved == true ?
                                    <Button className='themesbtn' onClick={() => approve()}><span>Approved</span></Button> :
                                    <Button className='themesbtn ms-3' ><span>Swap</span></Button>
                                } */}
                            </div>

                        </div>
                    </div>
                </div>


                <div className='row mt-3'>
                    <div className='col-xl-6 claimcard mt-4'>
                        <h5 className='fname mb-3'>Claim Token</h5>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Claim Type</p><p className='whtsclr'>{saledetail?.vestingInterval && saledetail?.vestingInterval > 0 ? "Vesting" : "Claim"}</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Vesting Schedule</p><p className='whtsclr'>{saledetail?.vestingInterval}</p></div> */}
                        <div className='d-flex justify-content-between'><p className='grayclr'>Claimable Amount</p><p className='whtsclr'>{userdata?.userClaimbale && userdata?.userClaimbale > 0 ? userdata?.userClaimbale/10**18 : 0}</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Vesting Schedule</p><p className='whtsclr'>{saledetail?.firstClaimPercent ? parseFloat(saledetail?.firstClaimPercent)/100 : 0}</p></div> */}
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Total Bought Tokens</p><p className='whtsclr'>{{userdata?.userClaimbale && userdata?.userClaimbale > 0 ? userdata?.userClaimbale : 0}}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Have Bought</p><p className='whtsclr'>6.12 USDT</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>You have Claimed</p><p className='whtsclr'>12.33/22.44</p></div> */}
                        {/* <Button className='themesbtn mx-auto'><span>Claim Token</span></Button> */}
                    
                        {/* {saledetail && saledetail.isVested ?
                    <Button  className="themesbtn mx-auto" onClick={() => Claimtoken()} disabled = {!saledetail?.isClaimable}>Vested  {saledetail?.symbol} </Button>:
                    (saledetail && saledetail.isClaimable ? 
                    <Button  onClick={() => Claimtoken()} disabled = {!saledetail?.isClaimable} className="themesbtn mx-auto"> Claim  {saledetail?.symbol}  </Button> :
                    <Button onClick={() => Claimtoken()} disabled = {!saledetail?.isClaimable} className="themesbtn mx-auto"> Claim  {saledetail?.symbol}  </Button>)} */}
                    </div>
                    <div className='col-xl-6 mt-4'>
                        <div>
                            <Schedule data = {allocatedata} onclaim = {() => Claimtoken()}/>
                        </div>
                    </div>
                </div>



            </div>

        </div>

    )
}

export default Claiminfo
const BASE_URL = "user"

export const GetAllocationApi = BASE_URL + "/get-allocation";

export const EditLaunchpadApi = BASE_URL + "/edit-launchpad";

export const GetSingleLaunchpadApi = BASE_URL + "/get-single-launchpad";

export const AddInterestApi = BASE_URL + "/add-interest";

export const UserBuyApi = BASE_URL + "/user-buy";

export const GetUserPoolsApi = BASE_URL + "/get-user-pools";

export const EditNativeLaunchpadApi = BASE_URL + "/edit-native-launchpad";

export const GetNativeSaleApi = BASE_URL + "/get-native-launchpad";
import axios from "axios";
import { AddInterestApi, EditLaunchpadApi, EditNativeLaunchpadApi, GetAllocationApi, GetNativeSaleApi, GetSingleLaunchpadApi, GetUserPoolsApi, UserBuyApi } from "../Routes/Userroute";
import { API_URL } from "../Config/env";

export const GetAllocation = async() => {
    try{
        let result = await axios.get(API_URL + GetAllocationApi);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e);
    }
}

export const EditLaunchpad = async(data) => {
    try{
        let result = await axios.post(API_URL + EditLaunchpadApi , data);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e);
    }
}

export const GetSingleSaleHooks = async(data) => {
    try{
        let result = await axios.get(API_URL + GetSingleLaunchpadApi , {params : data});
        return result;
    }
    catch(e){
        console.log("erroron single sale hooks" , e);
    }
}

export const AddInterestHooks = async(data) => {
    try{
        let result = await axios.post(API_URL + AddInterestApi , data);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e);
    }
}

export const UserBuyHooks = async(data) => {
    try{
        let result = await axios.post(API_URL + UserBuyApi , data);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e);
    }
}

export const GetUserPoolsHooks = async(data) => {
    try{
        let result = await axios.get(API_URL + GetUserPoolsApi , {params : data});
        return result;
    }
    catch(e){
        console.log("erroron single sale hooks" , e);
    }
}


export const EditNativeLaunchpad = async(data) => {
    try{
        let result = await axios.post(API_URL + EditNativeLaunchpadApi , data);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e);
    }
}

export const GetSingleNativeSaleHooks = async(data) => {
    try{
        let result = await axios.get(API_URL + GetNativeSaleApi , {params : data});
        return result;
    }
    catch(e){
        console.log("erroron single sale hooks" , e);
    }
}
import React, { useState, useEffect } from 'react';
import { Container, Button, Tab, Nav, Col, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import binances from "../images/spropad/binances.png";
import metamask from "../images/spropad/metamask.png";
import walletcon from "../images/spropad/walletcon.png";
//sonia
import { useWeb3 } from "../Hooks/UseWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../Hooks/useWallet';
import { getAccount, getChainId } from "../Hooks/useAccounts";
import { sleep } from "../Hooks/useContract";
import {style,iconTheme,position} from '../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import { CHAINS } from '../Config/env';

function Walletmodal(props) {

    const [walletmodal, setWalletmodal] = useState(true);
    const [error , setError] = useState("");
    const [walletConnect , setWalletConnect] = useState();


    //sonia
const [accountInfo, setAccountInfo] = useState("");
const [accept , setAcctpt] = useState(false);

const MetaMask = async () => {
    // debugger
    await MetamaskWallet()
    await sleep(1000)
    await useWeb3();
    setAccountInfo(localStorage.getItem("accountInfo"))
    if (localStorage?.accountInfo) {

       
        toast.success("Wallet Connected successfully", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        })
        setTimeout(() => {
            
            window.location.reload()
        }, 1000);
       
    }
}

const enableWallet = async () =>{
    props.onDismiss() 
    const provider = await WalletConnect()
   
    //  this.setState({walletConnect: provider})
    setWalletConnect(provider);
   
    setAccountInfo(localStorage.getItem("accountInfo"))
    //  this.setState({accountInfo: localStorage.getItem("accountInfo")});
    //  this.setState({ walletModal : false})
    setWalletmodal(false);  
    
    // window.location.reload() 
    //  if(localStorage.getItem("accountInfo")){
    //      this.setState({ walletModal : false})
         window.location.reload()
    //  }
  
 }

    return (

        <div className='walletmodal'>

            <Modal show={walletmodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className='mt-5' onClick={() => props.onDismiss()}></Modal.Header>
                <Modal.Body className='px-3 px-sm-5'>
                    <h1 className='mainhead text-center alltext mb-4'>Connect <span className='mainsub'> Wallet</span></h1>
                    <br/>
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) =>{ setAcctpt(e?.target?.checked)
                            if(e?.target?.checked){
                                setError("")
                            }
                        }} type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" for="flexCheckDefault">
                            I read and accept the <Link to="/">terms of service </Link> and
                            <Link to="/"> privacy policy</Link>
                            <p className='text-danger'>{error}</p>
                        </label>
                    </div>
                    <div className='row justify-content-center wallets mb-5' onClick={()=>{localStorage.setItem("metamask",true);}}>
                    <div className='col-11 col-sm-9 col-md-9 col-lg-5 mt-4'>
                        <h4 className='fname'>Network</h4>
                        <div className='networks d-flex align-items-center py-3 py-sm-4 px-3'>
                        <img src={binances} className='img-fluid binances' />
                        <h4 className='fname mb-0 ms-3'>{CHAINS[getChainId()].NAME}</h4>
                        </div>
                    </div>
                    <div className='col-11 col-sm-9 col-md-9 col-lg-6 mt-4'>
                        <h4 className='fname'>Wallet</h4>
                        <div className='networks d-flex align-items-center py-3 py-sm-4 px-3' onClick={()=>{
                            if(accept)
                            MetaMask()
                        else
                        setError("please Accept the terms and condition")
                            }}>
                        <img src={metamask} className='img-fluid binances' />
                        <h4 className='fname mb-0 ms-3'>Metamask</h4>
                        </div>

                        <div className='networks d-flex align-items-center py-3 py-sm-4 px-3 mt-3' onClick={()=>{
                            if(accept)
                                enableWallet()
                        else
                        setError("please Accept the terms and condition")
                            }}>
                        <img src={walletcon} className='img-fluid binances' />
                        <h4 className='fname mb-0 ms-3' 
                        // onClick={()=>{
                        //     if(accept)
                        //         enableWallet()
                        //     else
                        //     setError("please Accept the terms and condition")
                        // }}
                        >Wallet Connect</h4>
                        </div>
                    </div>
                    </div>
                   
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Walletmodal;
import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import binance from "../images/spropad/binance.png"
import ScrollspyNav from "react-scrollspy-nav";
import grayline from "../images/spropad/grayline.png";
import introimg from "../images/spropad/introimg.png";
import team1 from "../images/spropad/team1.png";
import team2 from "../images/spropad/team1.png";
import roadmap from "../images/spropad/roadmap.png";
import cryptoimg from "../images/spropad/cryptoimg.png";
import pngwing1 from "../images/spropad/pngwing 1.png";
import pngwing2 from "../images/spropad/pngwing 2.png";
import pngwing3 from "../images/spropad/pngwing 3.png";
import pngwing4 from "../images/spropad/pngwing 4.png";
import { GetSalePerAccount } from '../Hooks/UseNativesale';
import { GetSingleNativeSaleHooks } from '../Hooks/UseBackenduser';

function Projectinfo(props) {


    const [partner, setPartner] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }, { id: 11 }, { id: 12 }
    ]);

    const [userdata , setUserdata] = useState({});
    const [proinfo , setProinfo] = useState({});

    useEffect(() => {
        claimabledata();
    } , [])


    const claimabledata = async () => {
        if (localStorage.getItem("accountInfo")) {
            console.log("localStorage", localStorage.getItem("accountInfo"));
            const users = await GetSalePerAccount(localStorage.getItem("accountInfo"), window.location.href?.split("/")[4]);
            console.log("users", users);

            setUserdata(users);

            let saleres = await GetSingleNativeSaleHooks({saleaddress : window.location.href?.split("/")[4]})
            setProinfo(saleres?.data?.record);
        }
    }

    const isSaleLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }
    const isUpcoming = (start, end) => {
        try {
            return (Date.now() < (start * 1000));
        }
        catch (e) {
            console.log("isUpcoming", e);
        }
      }


    return (

        <div className='mt-4'>

            <div className='row'>
                <div className='col-lg-12 col-xl-6 mt-3'>
                    <div className='projectback pt-4 pb-3 px-3 px-xxl-4'>

                        <div className='d-flex justify-content-between'><p className='grayclr'>Price per {props?.salerecords?.symbol} token</p><p className='whtsclr'>{props?.salerecords && (1/(parseFloat(props?.salerecords.presaleRate)/10**18)).toFixed(4)}{" "}{props?.buytoken}</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>User Invested</p><p className='whtsclr'>{parseFloat(userdata?.userInvested)/10**18} {"  "}{props?.buytoken}</p></div> */}
                        <div className='d-flex justify-content-between'><p className='grayclr'>Total Participants</p><p className='grnclr'>{props?.salerecords?.participants}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Total Raise</p><p className='grnclr'>{(props?.salerecords?.earnedCap)/10**18}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Sale Type</p><p className='grnclr'>{props?.salerecords?.name}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Refund Terms</p><p className='grnclr'>7 days</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Sale Status</p><p className='whtsclr'>{props?.salerecords && isSaleLive(props?.salerecords?.startTime, props?.salerecords?.endTime, props?.salerecords?.isPresaleOpen) ?
                        


                            <span className="badge badge_live badge_finish">

                                <span className='stats_txt'><h5>Sales Live</h5></span>
                            </span> :
                            (props?.salerecords && isUpcoming(props?.salerecords.startTime) ?
                                <span className="badge  badge_finish">


                                    <span className='stats_txt'><h5>Upcoming</h5></span>
                                </span> :


                                <span className="badge badge_finish">

                                    <span className='stats_txt'><h5>Sales End</h5></span>
                                </span>
                            )
                        }</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Refund</p><p className='grnclr'>---</p></div> */}
                    </div>
                </div>
                <div className='col-lg-12 col-xl-6 mt-3'>
                    <div className='projectback pt-4 pb-3 px-3 px-xxl-4'>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Accepted Currency</p><p className='whtsclr'>USDT</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Network</p><p className='whtsclr'><img src={binance} className='bnbimg' /> Binance Smart Chain</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Token Claim Network</p><p className='whtsclr'><img src={binance} className='bnbimg' /> Binance Smart Chain</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Vesting Schedule</p><p className='whtsclr'>{parseFloat(props?.salerecords?.vestingPercent)/100} % TGE</p></div>
                    </div>
                </div>
            </div>

            <div className='mt-5 pb-3'>
                <ScrollspyNav
                    scrollTargetIds={["section_0", "section_1", "section_2", "section_3", "section_4"]}
                    offset={-100}
                    activeNavClass="is-active"
                    scrollDuration="1000"
                >
                    <ul className='infonav'>
                        <li><a href="#section_0" className='active'><span>Intro</span></a></li>
                        <li><a href="#section_1"><span>Roadmap</span></a></li>
                        <li><a href="#section_2"><span>Team</span></a></li>
                        <li><a href="#section_3"><span>Partner & Investors</span></a></li>
                        <li><a href="#section_4"><span>Token metrics</span></a></li>
                    </ul>
                </ScrollspyNav>
            </div>

            <div className='infodetail'>
                <div>
                <div id="section_0" className='section pb-3'>
                        {proinfo?.intro && <h3 className='fname mt-3'>Intro</h3>}
                   { ReactHtmlParser(proinfo?.intro)}
                    </div>

                    <div id="section_1" className='section pb-3 pb-3'>
                        {proinfo?.roadmap && <h3 className='fname mt-3'>Roadmap</h3>}
                   { ReactHtmlParser(proinfo?.roadmap)}
                    </div>

                    <div id="section_2" className='section pb-3 pb-3'>
                        {proinfo?.team && <h3 className='fname mt-3'>Team</h3>}
                   { ReactHtmlParser(proinfo?.team)}
                    </div>

                    <div id="section_3" className='section pb-3 pb-3'>
                        {proinfo?.partnerandinvestor && <h3 className='fname mt-3'>Partner & Investors</h3>}
                   { ReactHtmlParser(proinfo?.partnerandinvestor)}
                    </div>

                    <div id="section_4" className='section pb-3 pb-3'>
                    {proinfo?.tokenmetric && <h3 className='fname mt-3'>Token Metrics </h3>}
                   { ReactHtmlParser(proinfo?.tokenmetric)}
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Projectinfo;
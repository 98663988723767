import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import Hometablee from '../Components/hometable';
import { IoSearchOutline } from "react-icons/io5";
import { GetLaunchpadLimit, getTotalSalesInfo } from '../Hooks/useContract';
import Saletable from "../Components/saletable";

function Allpoolstable() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        saledata();
    }, []);

    const [initialloading , setInitialLoading] = useState([]);
    const [proxy , setProxy] = useState([]);
    const [Salecard , setSalecard] = useState([]);
    const [loading , setLoading] = useState([]);

    const [search , setSearch] = useState("");
    const [issearch , setIssearch] = useState(false);
    const [searchproxy , setSearchproxy] = useState([]);
    const [searchsale , setSearchsale] = useState([]);




    const saledata = async () =>{
        setInitialLoading(true)
        const Proxydata = await getTotalSalesInfo();
        console.log("Proxydata", Proxydata);
        setProxy(Proxydata);
        let limitproxydata = await GetLaunchpadLimit(Proxydata , 0 , 10);
        setSalecard(limitproxydata)
        setInitialLoading(false)
    }

    const loadmoredata = async() => {
        try{
            setLoading(true);
            if(proxy?.length > Salecard?.length){
                let proxydata = await GetLaunchpadLimit(proxy , Salecard?.length , (proxy?.length > Salecard?.length +10) ?  (Salecard?.length+10) : proxy?.length);
               console.log("loadmore data" , proxydata);
                setSalecard(Salecard?.concat(proxydata));
            }
            setLoading(false);
        }
        catch(e){
            console.log("Error on load more data" , e);
        }
    }
    
    const searchCards = async(SaleInfoCards, search) => {
        console.log("SaleInfoCards, search" , SaleInfoCards, search);
        const filteredData = await SaleInfoCards.filter(value => ((value._name).toUpperCase()).includes(search.toUpperCase()));
        console.log("filtered data" , filteredData);
        return filteredData;
    }
    
    const  handleChange =  async(e)=>{
        // debugger
        console.log("e" , e?.target?.value);
        setInitialLoading(true);
      if((e.target.value).length > 0){
            if (e.key === 'Enter') {
            console.log("Search : ",e.target.value , proxy)
            // this.showLoader();
            const searchS = await searchCards(proxy,e.target.value);
            // this.setState({ searchProxy : searchS});
            setSearchproxy(searchS);
            const data = await GetLaunchpadLimit(searchS,0,10);
           
            console.log("Search sale : ",searchS)
            // this.setState({ searchSale: data, isSearch: true})
            setSearchsale(data);
            setIssearch(true);
            // this.hideLoader();
            }
      }else{
        setIssearch(false);
      }
      setInitialLoading(false);
    }
    
    const searchloadmoredata = async() => {
        try{
            setLoading(true);
            if(searchproxy?.length > searchsale?.length){
                let proxydata = await GetLaunchpadLimit(searchproxy , searchsale?.length , (searchproxy?.length > searchsale?.length +10) ?  (searchsale?.length+10) : searchproxy?.length);
                // setSalecard(Salecard?.concat(proxydata));
                setSearchsale(searchsale.concat(proxydata));
            }
            setLoading(false);
        }
        catch(e){
            console.log("Error on load more data" , e);
        }
    }
      


console.log("alecerd" , Salecard);
    return (
        <div className='all'>
            <Header />
            <Subheaders />
            <div className='innerpages pb-5'>
                <div className='container'>
                    <div className='poolback headtext'>
                        <h1 className='mainhead text-left alltext mb-4'>List <span className='mainsub'> View</span></h1>
                        <div className='row'>
                            <div className='col-sm-9 col-md-7 col-lg-5 col-xl-4 col-xxl-3 ms-auto mt-3'>
                            <div className='tokensale'>
                            <div className='pos'>
                                <IoSearchOutline className='searchicon' />
                                <input type='search' className='form-control' placeholder='Search by pool name' 
                                onChange={(e) => handleChange(e)}
                                onKeyDown={(e)=> handleChange(e)}
                                />
                            </div>
                        </div>
                            </div>
                        </div>
                       
                        {/* <Hometablee /> */}
                        {!issearch && (Salecard && Salecard.length > 0) && 
                        <Saletable data = {Salecard} proxy = {proxy} loadmore = {() => loadmoredata()} loading = {initialloading || loading}/>}

                    {issearch && (searchsale && searchsale.length > 0)  && 
                        <Saletable data = {searchsale} proxy = {searchproxy} loadmore = {() => searchloadmoredata()} loading = {initialloading || loading}/>}
                    </div>

                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Allpoolstable;
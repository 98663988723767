import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import { Link } from 'react-router-dom';
import Upcoming from '../Components/nativeupcoming';
import addressimg from "../images/spropad/addressicon.png";
import insta from '../images/spropad/insta.png';
import fb from '../images/spropad/fb.png';
import linked from '../images/spropad/linked.png';
import tele from '../images/spropad/tele.png';
import twitter from "../images/spropad/twitter.png"
import whatsapp from '../images/spropad/whatsapp.png';
import grayline from "../images/spropad/grayline.png";
import Projectinfo from "../Components/nativeprojectinfo";
import Claiminfo from "../Components/nativeclaiminfo";
import { getSaleInfoCard } from '../Hooks/UseNativesale';
import { UseTokenInfo, checkbalnceTokens } from '../Hooks/useContract';
import { getAccount } from '../Hooks/useAccounts';
import { OWNER_ADDRESS } from '../Config/env';
// import Calculator from '../Components/calculator';

function NativeCardinfo() {

    const [activeTab, setActiveTab] = useState('project');
    const [saledetail , setSaledetail] = useState({});
    const [checkbalance , setCheckbalance] = useState();
    const [buytoken , setBuytoken] = useState();
    const [decimal , setDecimal] = useState(0);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        refreshdata();
    }, []);


    const refreshdata = async () => {
        try{
            // debugger
        let result = await getSaleInfoCard(window.location.href?.split("/")[4])
        setSaledetail(result);
        let usetokenaddress = await UseTokenInfo(result?.useWithToken)
        console.log("usetokenaddress", usetokenaddress);
        GetBuytokeninfo(result?.useWithToken)


        const balance = await checkbalnceTokens(result?.tokenAddress, result?.saleAddress);
        console.log(" >>>>", balance / 10 ** parseInt(result?.decimals));
        setCheckbalance(balance / 10 ** 18);

        // setInterval(() => {
        //     handlecheckfinalize()
        // } , 3000);

        clearInterval();
        }
        catch(e){
            console.log("Error on card ingo" , e);
        }
    }

    const GetBuytokeninfo = async (value) => {
        console.log("value", value);

        if (value === "0x0000000000000000000000000000000000000000") {
            console.log("kxcnvjxch");
            setBuytoken('BNB')
            setDecimal('18')

        } else {
            const token = await UseTokenInfo(value);
            console.log("UseTokenInfo", token);
            setBuytoken(token?.symbol)
            setDecimal(token?.decimals)



        }
    }






    return (

        <div className='all'>

            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>
                <div className='container'>
                    <div
                        className={activeTab === 'claim' ? "infoback tab2back headtext pos" : "infoback headtext pos"}
                    // className='infoback headtext'
                    >
                        <div className='labelname'>
                            <label className='labelback'>{saledetail?.name}</label>
                            <label className='labelback ms-2 ms-sm-3'>SPP Token</label>
                        </div>

                        <h1 className='mainhead text-left alltext mb-4'>Smart pro pad <span className='mainsub'> Tiers</span></h1>
                        <div className='row flexreverse'>

                            <div className='col-sm-11 col-md-9 col-lg-5 col-xl-4 mt-4 mx-auto'>
                                <Upcoming salerecords = {saledetail}/>
                                <div className='infowalletback py-3 px-3 mt-4 d-flex align-items-center'>
                                    <img src={saledetail?.logo} className='img-fluid walletsimg salessimg me-3' />
                                    <div>
                                        <h5 className='whtsclr'>Sale Address :</h5>
                                        <h5 className='whtsclr smtxt mb-0'>{saledetail?.saleAddress}</h5>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-11 col-lg-7 col-xl-8 mt-4 mx-auto'>

                            <div className='socialicons text-end mb-3 mb-md-0'>
                                    {/* <a href={saledetail?.social && saledetail?.social[7]} target='_blank'> <img src={fb} className='img-fluid' alt='images' /></a> */}
                                    {/* <a href={saledetail?.social && saledetail?.social[9]} target='_blank'> <img src={linked} className='img-fluid' alt='images' /></a> */}
                                    {/* tele */}
                                    <a href={saledetail?.social && saledetail?.social[2]} target='_blank'> <img src={tele} className='img-fluid' alt='images' /></a>
                                    {/* twitter */}
                                    <a href={saledetail?.social && saledetail?.social[1]} target='_blank'> <img src={twitter} className='img-fluid' alt='images' /></a>
                                    {/* <a href={saledetail?.social && saledetail?.social[10]} target='_blank'> <img src={whatsapp} className='img-fluid' alt='images' /></a> */}
                                </div>

                                <div className='d-flex align-items-center'>
                                    <div className='profssclip'>
                                        <div className='profclip'>
                                            <img src={saledetail?.logo} className='img-fluid addimg' />
                                        </div>
                                    </div>
                                    <div className='ms-3'><h4 className='fname'>{saledetail?.symbol}</h4><p className='grayclr smtxt mb-0'>
                                       {saledetail?.description}</p></div>
                                </div>

                               <div className='d-block d-sm-flex align-items-center justify-content-between pt-4'>
                                <ul className="tab-list pt-0 mb-0">
                                    <li className={activeTab === 'project' ? 'active' : 'tabtext'} onClick={() => handleTabClick('project')}><span>Project Info</span></li>
                                    <li className={activeTab === 'claim' ? 'active' : 'tabtext'} onClick={() => handleTabClick('claim')}><span>Swap & Claim</span></li>
                                    {/* <li className={activeTab === 'calculator' ? 'active' : 'tabtext'} onClick={() => handleTabClick('calculator')}><span>Allocation Calculator</span></li> */}
                                    {/* getAccount() == OWNER_ADDRESS && */}
                                </ul>
                                {getAccount() == OWNER_ADDRESS &&<div className='mt-3 mt-sm-0 text-end text-xl-start'><Link to={"/infoedit/" + window.location.href.split("/")[4]+"/nativesale"}><button className='btn nbtn'>Edit Info</button></Link></div>}
                                </div>

                                <img src={grayline} className='img-fluid' />

                                {activeTab === 'project' &&
                                    <div>
                                        <Projectinfo salerecords = {saledetail} buytoken = {buytoken}  decimal = {decimal}/>
                                    </div>
                                }

                                {activeTab === 'claim' &&
                                    <div>
                                        <Claiminfo salerecords = {saledetail} buytoken = {buytoken}  decimal = {decimal}/>
                                    </div>
                                }
                                {/* {activeTab === 'calculator' &&
                                   <div>
                                   <Calculator />
                               </div>
                                } */}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default NativeCardinfo
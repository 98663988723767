


let { 
    EnvName = "local", 
    chainId = "", 
    MinStakeAmt = "0.01", 
    Front_URL = "", 
    Back_URL = "", 
    v1Url = "", 
    Users_URL = "", 
    Contract = "", 
    Provider = "",
    networkVersion=""
  } = {};
if (EnvName === "production") {
    Front_URL = "";
    Back_URL = "";
    v1Url = "";
    Users_URL = "";
    Contract = "";
    Provider = "";
} else if (EnvName === "demo") {
    Front_URL = "";
    Back_URL = "";
    Contract = "".toLowerCase();
    Provider = "https://public.stackup.sh/api/v1/node/bsc-testnet";
    chainId= '0x97'
    networkVersion='97'
} else {
    Front_URL = "http://localhost:3000";
    Back_URL = "http://localhost:7001";
    Contract = "".toLowerCase();
    Provider = "https://public.stackup.sh/api/v1/node/bsc-testnet";
    chainId= '0x97'
    networkVersion='97'
}
let key = {
    Front_URL: Front_URL,
    Back_URL: Back_URL,
    ChainID: chainId,
    networkVersion:networkVersion,
    MinStakeAmt:MinStakeAmt
};

export default key;

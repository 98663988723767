import React, { useState } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = (props) => {
  console.log("propsDatavalue",props);
  const [selectedDate, setSelectedDate] = useState(new Date());
console.log("prossss",({...props,...{['selectedDate']:selectedDate}}));
  const years = Array.from(new Array(100), (val, index) => new Date().getFullYear() - index);
  const months = [
    '1', '2', '3', '4', '5',
    '6', '7', '8', '9', '10', '11','12'
  ];

  const handleYearChange = (year) => {
    const newDate = new Date(selectedDate.setFullYear(year));
    // props.onchage({...props,...{['Date']:newDate}})
    props.onChange(newDate)
    setSelectedDate(newDate);
  };

  const handleMonthChange = (month) => {
    const newDate = new Date(selectedDate.setMonth(month));
    console.log("newDate",newDate);
    setSelectedDate(newDate);
    props.onChange(newDate)
  };

  const handleDayChange = (date) => {
    setSelectedDate(date);
    props.onChange(date)
  };

  return (
    <div className='d-flex'>
        <div className='w-10 me-4 text-center'>
        <DatePicker
        selected={selectedDate}
        onChange={handleDayChange}
        dateFormat="d"
      />
       <p className='grayclr'>DD</p>
        </div>
        <div className='select1 w-10 me-4 text-center'>
            {console.log('selectedDate',selectedDate)}
       {selectedDate && <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      {selectedDate.getMonth()+1}
      </Dropdown.Toggle>

      <Dropdown.Menu >
      {months.map((month, index) => (
        <Dropdown.Item key={index} value={month} onClick={()=>handleMonthChange(Number(month)-1)}> {month}</Dropdown.Item>
    ))}
      </Dropdown.Menu>
    </Dropdown>} 
      {/* <select value={selectedDate.getMonth()} onChange={(e) => handleMonthChange(e.target.value)}>
        {months.map((month, index) => (
          <option key={index} value={index}>
            {month}
          </option>
        ))}
      </select> */}
      <p className='grayclr'>MM</p>
      </div>
      <div className='select1 year text-center'>

      <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      {selectedDate.getFullYear()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      {years.map((year, index) => (
        <Dropdown.Item key={index} value={year} onClick={()=>handleYearChange(year)}>{year}</Dropdown.Item>
    ))}
      </Dropdown.Menu>
    </Dropdown>

      {/* <select value={selectedDate.getFullYear()} onChange={(e) => handleYearChange(e.target.value)}>
        {years.map((year, index) => (
          <option key={index} value={year}>
            {year}
          </option>
        ))}
      </select> */}
      <p className='grayclr'>YYYY</p>
      </div>
    </div>
  );
};

export default CustomDatePicker;

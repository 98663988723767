import { API_URL } from "../Config/env";
import * as api from "../routes/userroutes";
import axios from 'axios';


export const createuserhook = async (data) => {
    console.log("login data", data);
    var formdata = new FormData();
    try {
      if (data) {
        // formdata.append("saleaddress" , data?.saleaddress);
        formdata.append("walletaddress", data);
        // formdata.append("whitelist" , data?.whitelist);
        // formdata.append("amount" , data?.amount);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.createuserApi,
          'credentials': true,
          'headers': {
            'content-Type': 'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response", respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        console.log("createuserhook__error", console)
        return {
          error: err
        }
      }
    }
    catch (e) {
      console.log("createuserhook__error", e);
    }
  }

  export const getuserdatahook = async (data) => {
    console.log("login data", data);
    var formdata = new FormData();
    try {
      if (data) {
        // formdata.append("saleaddress" , data?.saleaddress);
        formdata.append("walletaddress", data);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.getuserdata,
          'credentials': true,
          'headers': {
            'content-Type': 'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response", respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch (e) {
      console.log("error", e);
    }
  }
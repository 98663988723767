import React, { useState } from 'react';
import {Table} from "react-bootstrap";
import tableprof from '../images/spropad/tableprof.png';
import tick from "../images/spropad/tick.png";

const DataTable = ({ data, rowsPerPage , allocate , claim}) => {
    console.log("allocated in tabl" , allocate);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(allocate.length / rowsPerPage);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const handlePageClick = (pageNumber) => {console.log("page" , pageNumber , totalPages);
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * rowsPerPage;
    const selectedData = allocate.slice(startIndex, startIndex + rowsPerPage);
console.log("allocated page" , startIndex , selectedData);
    return (
        <div className='scheduleinfo'>

            <div className='d-flex justify-content-between align-items-center mb-3'>
            <h5 className='fname'>Schedule</h5>
            <div className="pagination">
                <button
                    className='pauseleftbtn me-2'
                    disabled={currentPage == 1}
                    onClick={() => handlePageClick(currentPage - 1)}
                >
                </button>
                <button
                    className='pauserightbtn'
                    disabled={currentPage >= totalPages}
                    onClick={() => handlePageClick(currentPage + 1)}
                >
                </button>
            </div>    
            </div>

           <div className='overs_auto'>
            <Table responsive>
            <thead>
                <tr>
                    <th> <p className='grayclr mb-0'>No.</p></th>
                    <th> <p className='grayclr mb-0'>amount</p></th>
                    <th><p className='grayclr mb-0'>Unlock On</p></th>
                    <th> <p className='grayclr mb-0'>Status</p></th>
                </tr>
              </thead>
              <tbody>
              {allocate?.length > 0 ? selectedData.map((index , i) => (
                <tr key={i}>
                    <td> <p className='whtsclr mb-0'>{index?.no}</p></td>
                    <td> <p className='whtsclr mb-0'>{index?.allocation/10**18}</p></td>
                    <td>                             <p className='whtsclr mb-0'>{new Date(index?.unlockon*1000).toLocaleDateString("en-US", options)}</p>
                    </td>
                    <td> <button onClick={claim} 
                            disabled={!index?.isClaimed} 
                            className="btn nbtn">{index?.isDid ? 'Claimed' : 'Claim'}</button></td>
                </tr>
                 )) :   <td colSpan={4} className='whtclr text-center pt-4'>No data found</td>}
                 </tbody>
            </Table></div>
         
            {/* <div className='alltableoverflow mx-1 mx-sm-0'>
                <div className='row mb-3'>
                    <div className='col-2 col-sm-2 col-md-2'>
                        <p className='grayclr mb-0'>No.</p>
                    </div>
                    <div className='col-2 col-sm-3 col-md-2'>
                        <p className='grayclr mb-0'>Amount</p>
                    </div>
                    <div className='col-4 col-sm-4 col-md-4'>
                        <p className='grayclr mb-0'>Unlock On</p>
                    </div>
                    <div className='col-4 col-sm-3 col-md-4'>
                        <p className='grayclr mb-0'>Status</p>
                    </div>
                </div>
                {allocate?.length > 0 ? selectedData.map((index , i) => (
                    <div className='row scheduletabel px-2 mb-2'  key={i}>
                        <div className='col-2 col-sm-2 col-md-2'>
                            <p className='whtsclr mb-0'>{index?.no}</p>
                        </div>
                        <div className='col-2 col-sm-3 col-md-2'>
                            <p className='whtsclr mb-0'>{index?.allocation/10**18}</p>
                        </div>
                        <div className='col-4 col-sm-4 col-md-4'>
                            <p className='whtsclr mb-0'>{new Date(index?.unlockon*1000).toLocaleDateString("en-US", options)}</p>
                        </div>
                        <div className='col-4 col-sm-3 col-md-4'>
                            <button onClick={claim} 
                            disabled={!index?.isClaimed} 
                            className="btn nbtn">{index?.isDid ? 'Claimed' : 'Claim'}</button>
                        </div>
                    </div>
                )) :   <p className='grayclr text-center mt-3 mb-0'>No data found</p>}
            </div> */}





        </div>
    );
};

function App(props) {console.log("allocated in p" , props?.data);
    const data = Array.from({ length: 25 }, (_, index) => ({
        id: index + 1,
        name: `Item ${index + 1}`,
    }));

    return (
        <div className="App">
            <DataTable data={data} rowsPerPage={5}   claim = {() => props?.onclaim()}allocate = {props?.data}/>
        </div>
    );
}

export default App;




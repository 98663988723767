import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import { Link } from 'react-router-dom';
import Upcoming from '../Components/upcoming';
import addressimg from "../images/spropad/addressicon.png";
import insta from '../images/spropad/insta.png';
import fb from '../images/spropad/fb.png';
import linked from '../images/spropad/linked.png';
import tele from '../images/spropad/tele.png';
import twitter from "../images/spropad/twitter.png"
import website from "../images/spropad/website.png" 
import whatsapp from '../images/spropad/whatsapp.png';
import grayline from "../images/spropad/grayline.png";
import Projectinfo from "../Components/projectinfo";
import Claiminfo from "../Components/claiminfo";
import Calculator from '../Components/calculator';
import { useLocation } from 'react-router';
import { AddRegisterInterest, Finalise, UseTokenInfo, checkbalnceTokens, getSaleInfoCard, getUnsold, updateStopEvent, withdrawBNB } from '../Hooks/useContract';
import Whitemodals from '../Components/whitelistmodal';
import { getAccount } from '../Hooks/useAccounts';
import { OWNER_ADDRESS } from '../Config/env';
import { GetSingleSaleHooks } from '../Hooks/UseBackenduser';

function Cardinfo() {
    const location = useLocation()
    console.log("locationssssss", location);
    // const salerecords = location?.state
    const [salerecords , setSalerecords] = useState();
    console.log("salerecords", salerecords);
    const [buytoken, setBuytoken] = useState('')
    console.log("buytoken", buytoken);

    const [decimal, setDecimal] = useState('');
    const [saledetail , setSaledetail] = useState({});
    console.log("decimal", decimal,saledetail);

    const [whitelistmodal, setWhitelistmodal] = useState(false);
    const [activeTab, setActiveTab] = useState('project');
    const [checkbalance , setCheckbalance] = useState(0);
    const [checkdeposittoken , setCheckdeposittoken] = useState(0);
    const [proinfo , setProinfo] = useState({})

    const [saletype , setSaletype] = useState("");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    console.log("saletyoe" , saletype);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        tokenInfo()
    }, [])
    const tokenInfo = async () => {
        try{
            // debugger
        let result = await getSaleInfoCard(window.location.href?.split("/")[4])
        setSaledetail(result);
        setSalerecords(result)

        let usetokenaddress = await UseTokenInfo(result?.useWithToken)
        console.log("usetokenaddress", usetokenaddress);
        GetBuytokeninfo(result?.useWithToken)


        const balance = await checkbalnceTokens(result?.tokenAddress, result?.saleAddress);
        console.log(" >>>>", balance / 10 ** parseInt(result?.decimals));
        setCheckbalance(balance / 10 ** 18);

        setInterval(() => {
            handlecheckfinalize()
        } , 3000);

        clearInterval();


        if((parseFloat(result?.startTime) * 1000) < Date.now()){
            if (((parseFloat(result?.startTime) * 1000) + 86400000)  > Date.now()){
                setSaletype("MultiVendor Round")
            }
            else if (((parseFloat(result?.startTime) * 1000) + 86400000)  < Date.now()){
                if(isSaleLive(result?.startTime,result?.endTime,result?.isPresaleOpen)){
                    setSaletype("FCFS Round")
                }
                else{
                    setSaletype("Ended")
                }
            }
        }
        else{
            setSaletype("Guaranteed Round")
        }

        let saleres = await GetSingleSaleHooks({saleaddress : window.location.href?.split("/")[4]})
        setProinfo(saleres?.data?.record);
        }
        catch(e){
            console.log("Error on card ingo" , e);
        }
    }


    const GetBuytokeninfo = async (value) => {
        console.log("value", value);

        if (value === "0x0000000000000000000000000000000000000000") {
            console.log("kxcnvjxch");
            setBuytoken('BNB')
            setDecimal('18')

        } else {
            const token = await UseTokenInfo(salerecords && salerecords?.useWithToken);
            console.log("UseTokenInfo", token);
            setBuytoken(token?.symbol)
            setDecimal(token?.decimals)



        }
    }

    const stopSale = async()=>{
        await updateStopEvent(saledetail?.saleAddress,getAccount());
        tokenInfo()
    }
    const isSaleLive = (start,end,isOpen) => {
        return (Date.now() >= (start*1000) && Date.now()<= (end*1000)) && isOpen;
    }

    const handlecheckfinalize = async() => {
        let tokenAmount = 0;
        let amountineth = parseFloat(saledetail?.earnedCap / 10 ** 18)*parseFloat(saledetail?.liquidityPercent) / 100;
        var pricePerToken = parseFloat((saledetail?.earnedCap/ 10 ** 18) * 10 ** 8) / parseFloat(saledetail?.hardCap / 10 ** 18) ; // pricePerToken
        var valuePerBNB = 1 * 10 ** 8 / pricePerToken ; // valuePerBNB
            tokenAmount = valuePerBNB;
           
      
        //  tokenAmount = parseFloat(this.state.saleInfo?.earnedCap / 10 ** 18) /parseFloat(this.state.saleInfo?.hardCap / 10 ** 18);
        if(saledetail?.decimals != "18"){
          var price = (tokenAmount * 1) / 10 ** (18 - parseInt(saledetail?.decimals) + 0) ;
          tokenAmount = price;
        }
        
        let total = amountineth*tokenAmount;
        setCheckdeposittoken(total)
        
      }

    const Finalisesale =  async() => {
        
        await Finalise(saledetail?.saleAddress,getAccount());
        tokenInfo();
      }

    const  withdrawToken = async() => {console.log("withdraw token");
        await getUnsold(window.location.href?.split("/")[4],getAccount());
        tokenInfo();
    }
      
    const BNBwithdraw = async()=>{console.log("withdraw bnb");
        await withdrawBNB(window.location.href?.split("/")[4],getAccount());
        tokenInfo();
    }

    

    return (

        <>
        {whitelistmodal && <Whitemodals saledata = {saledetail} onDismiss={()=>setWhitelistmodal(false)}/>}    
        <div className='all'>

            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>
                <div className='container'>
                    <div
                        className={activeTab === 'claim' ? "infoback tab2back headtext pos" : "infoback headtext pos"}
                    >
                        <div className='labelname'>
                            <label className='labelback'>{saletype}</label>
                            <label className='labelback ms-2 ms-sm-3'>{saletype == "MultiVendor Round" ? "Elite Pro at Min tier"  
                           : saletype == "FCFS Round" ? "No tier eligible" : saletype ==  "Guaranteed Round" && "Master Pro at Min tier"    
                        } </label>
                        </div>

                        <h1 className='mainhead text-left alltext mb-4'>Smart pro pad <span className='mainsub'> Tiers</span></h1>
                        <div className='row flexreverse'>

                            <div className='col-sm-11 col-md-9 col-lg-5 col-xl-4 mt-4 mx-auto'>
                                <Upcoming />
                                <div className='infowalletback py-3 px-3 mt-4 d-flex align-items-center'>
                                    <img src={salerecords?.logo} className='img-fluid me-3 walletsimg salessimg' />
                                    <div>
                                        <h5 className='whtsclr'>Sale Address :</h5>
                                        <h5 className='whtsclr smtxt tok_add mb-0'>{salerecords?.saleAddress}</h5>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-11 col-lg-7 col-xl-8 mt-4 mx-auto'>

                                <div className='socialicons text-end mb-3 mb-md-0'>
                                    {/* <a href={saledetail?.social && saledetail?.social[7]} target='_blank'> <img src={fb} className='img-fluid' alt='images' /></a> */}
                                    {/* <a href={saledetail?.social && saledetail?.social[9]} target='_blank'> <img src={linked} className='img-fluid' alt='images' /></a> */}
                                  {/* tele */}
                                    <a href={saledetail?.social && saledetail?.social[2]} target='_blank'> <img src={tele} className='img-fluid' alt='images' /></a>
                                  {/* twitter */}
                                    <a href={saledetail?.social && saledetail?.social[1]} target='_blank'> <img src={twitter} className='img-fluid' alt='images' /></a>
                                  {/* website */}
                                  <a href={saledetail?.social && saledetail?.social[0]} target='_blank'> <img src={website} className='img-fluid' alt='images' /></a>
                                    {/* <a href={saledetail?.social && saledetail?.social[10]} target='_blank'> <img src={whatsapp} className='img-fluid' alt='images' /></a> */}
                                </div>

                                <div className='d-flex align-items-center'>
                                    <div className='profssclip'>
                                        <div className='profclip'>
                                            <img src={salerecords?.logo} className='img-fluid addimg' />
                                        </div>
                                    </div>
                                    <div className='ms-3'><h4 className='fname'>{salerecords?.symbol}</h4><p className='grayclr smtxt mb-0'>{salerecords?.[5]}</p></div>
                                </div>

                                <div className='d-block d-xl-flex justify-content-between align-items-center pt-5 mb-3'>
                                    <ul className="tab-list mb-0 mt-3">
                                        <li className={activeTab === 'project' ? 'active' : 'tabtext'} onClick={() => handleTabClick('project')}><span>Project Info</span></li>
                                        <li className={activeTab === 'claim' ? 'active' : 'tabtext'} onClick={() => handleTabClick('claim')}><span>Swap & Claim</span></li>
                                        <li className={activeTab === 'calculator' ? 'active' : 'tabtext'} onClick={() => handleTabClick('calculator')}><span>Allocation Calculator</span></li>
                                        { getAccount() == saledetail?.owner && <li className={activeTab === 'admins' ? 'active' : 'tabtext'} onClick={() => handleTabClick('admins')}><span>Admin</span></li>}
                                    </ul>
                                    {getAccount() == OWNER_ADDRESS &&<div className='mt-3 text-end text-xl-start'><Link to={"/infoedit/" + window.location.href.split("/")[4]+"/sale"}><button className='btn nbtn'>Edit Info</button></Link></div>}
                                </div>



                                <img src={grayline} className='img-fluid'/>

                                {activeTab === 'project' &&
                                    <div>
                                        <Projectinfo />
                                    </div>
                                }

                                {activeTab === 'claim' &&
                                    <div>
                                        <Claiminfo />
                                    </div>
                                }
                                {activeTab === 'calculator' &&
                                    <div>
                                        <Calculator saledetail = {salerecords}/>
                                    </div>

                                }
                                {saledetail && activeTab === 'admins' &&
                                    <div>
                                      <h3 className='fname mt-4 ms-2'>Owner</h3>
                                      {/* <button className='btn nbtn w-100 mt-2 mb-3' onClick={()=>setWhitelistmodal(true)}>Add Whitelist</button> */}
                                      <p className='fname'>Pool Action</p>
                                      <div className='d-flex wraps'>
                                        {console.log("slive " , isSaleLive(saledetail?.startTime,saledetail?.endTime,saledetail?.isPresaleOpen) , saledetail,saledetail?.endTime,saledetail?.isPresaleOpen)}
                                      <button className='btn nbtn mt-2 mb-3' 
                                    //   disabled = {!isSaleLive(saledetail?.startTime,saledetail?.endTime,saledetail?.isPresaleOpen)}
                                    disabled = {!saledetail.isPresaleOpen}
                                      onClick={stopSale}>Stop Sale</button>
                                      <button className='btn nbtn mt-2 mb-3' 
                                      disabled={saledetail.isPresaleOpen || saledetail?.isClaimable}
                                      onClick={Finalisesale}
                                      >Finalize</button>
                                      <button className='btn nbtn mt-2 mb-3' 
                                      disabled={!saledetail?.isClaimable}
                                      onClick={withdrawToken}
                                      >Get Unsold</button>
                                      <button className='btn nbtn mt-2 mb-3' 
                                      disabled={!saledetail?.isClaimable}
                                      onClick={BNBwithdraw}
                                      >Withdraw USDT</button>
                                      </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
        </>
    )
}

export default Cardinfo
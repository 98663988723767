import axios from 'axios';
// import Cookies from 'universal-cookie';
import { API_URL } from '../Config/env';
import * as Homepage from "../routes/landingroutes";
// import { setAuthToken } from '../Lib/localStorage';
// const cookies = new Cookies();




export const getfaqlist = async (data) => {
    console.log("getfaqlist", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + Homepage.cmslists,
           'params':data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
        }
    
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const getregisteraccount = async (data) => {
    console.log("getfaqlist", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + Homepage.RegisterAccountListFront,
           data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
        }
        
    }
    catch (e) {
      console.log("error", e);
    }
  }

  
  export const sprofeatureList = async (data) => {
    console.log("getfaqlist", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + Homepage.sprofeatureListFront,
           data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
        }
    
    }
    catch (e) {
      console.log("error", e);
    }
  }

export const Faqtablist = async (data) => {
    console.log("getfaqlistddd", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + Homepage.Faqtablist,
          'params':data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
        }
    
    }
    catch (e) {
      console.log("error", e);
    }
  }

export const FaqhideList = async (data) => {
    console.log("getfaqlist", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + Homepage.FaqhideList,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
        }
    
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const getsociallinks = async (data) => {
    console.log("getsociallinks", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + Homepage.getSocialLinksFront,
          "params":data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const addtokenrequest = async (data) => {
    console.log("addtokenrequest", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + Homepage.addtokenrequest,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  //allocation
  export const getAllocation = async (data) => {
    console.log("getAllocation", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + Homepage.getallocation,
          'params':data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
        }
    
    }
    catch (e) {
      console.log("error", e);
    }
  }
import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import Profilenav from '../Layouts/profilenav';
import { IoSearchOutline } from "react-icons/io5";
import grayline from "../images/spropad/grayline.png";
import { GetUserPoolsHooks } from '../Hooks/UseBackenduser';
import { getAccount } from '../Hooks/useAccounts';

function Mypools() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        fetchdata();
    }, []);

    const [tables, settables] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 },
    ]);
    const [skip , setSkip] = useState(0);
    const [limit , setLimit] = useState(10);
    const [pools , setPools] = useState([]);
    const [count , setCount] = useState(0);
    const [loading , setLoading] = useState(false);
    const [initialloading , setInitialloading] = useState(false);

    const fetchdata = async() => {
        try{
            setInitialloading(true);
            let payload = {
                useraddress : getAccount(),
                skip : 0,
                limit : limit
            };
            let result = await GetUserPoolsHooks(payload);
            if(result?.data?.type == "Success"){
                setSkip(skip + 10);
                setPools(result?.data?.record);
                setCount(result?.data?.count);
            }
            setInitialloading(false);
        }
        catch(e){
            console.log("Error on fetchdataa" , e);
        }
    }

    const loadmore = async() => {
        setLoading(true);
        let payload = {
            useraddress : getAccount(),
            skip : skip,
            limit : limit
        };
        let result = await GetUserPoolsHooks(payload);
            if(result?.data?.type == "Success"){
                setSkip(skip + 10);
                setPools(pools?.concat(result?.data?.type))
            }
            setLoading(false);
    }

    return (

        <div className='all'>

            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>
                <div className='container'>
                    <div className='profileback headtext pos'>
                        <Profilenav />
                        <h1 className='mainhead text-left alltext mb-4'>My <span className='mainsub'> Pools</span></h1>
                        <p className='fname'>Here are all pools that you have participated in.</p>

                        {/* <div className='tokensale mt-4'>
                            <div className='row mt-2'>
                                <div className='col-md-6 col-lg-4 col-xl-3 me-auto pos'>
                                    <IoSearchOutline className='searchicon' />
                                    <input type='search' className='form-control' placeholder='Search by pool name, token symbol...' />
                                </div>
                            </div>
                        </div> */}

                        <div className='poolstabel mt-5 py-4 py-sm-5 px-3 px-md-4 px-xl-5'>
                            <div className='row bb rows-col-5 rows-col-lg-4'>
                                <div className='col'> <p>Sale Address</p> </div>
                                <div className='col'> <p>Type</p> </div>
                                {/* <div className='col'> <p>Purchased</p> </div> */}
                                <div className='col'> <p>Invested</p> </div>
                            </div>
                            <img src={grayline} className='img-fluid d-none d-sm-block' />

                            {pools?.length > 0 ? pools.map((e, i) =>
                                <div className='row rows-col-5 rows-col-lg-4 mt-3'>
                                    <div className='col'> <p className='grayclr'>{e?.saleaddress}</p> </div>
                                    <div className='col'> <p className='grayclr'>{e?.saletype}</p> </div>
                                    {/* <div className='col'> <p className='grayclr'>{e?.buysymbol}</p> </div> */}
                                    <div className='col'> <p className='grayclr'>{e?.buyamount}</p> </div>
                                </div>
                            ) : <h5 className='d-flex justify-content-center whtclr mb-0 py-5'> No Data Found</h5> }

                            {pools?.length > 10 && pools?.length >= count && !initialloading && 
                                <div className='d-flex justify-content-center'><button className='btn themesbtn'
                                disabled={loading}
                                onClick={loadmore}
                                >Load More</button></div>
                            }

                        </div>

                    </div>
                </div>
            </div>

            <Footer />

        </div>

    )
}

export default Mypools;
import React from 'react'

function Names() {
    return (
        <div className='d-block d-md-none'>
        <div className='row mt-4 mb-2'>
            <div className='col-3 col-sm-3'>
                <h5 className='grayclr mb-0'>Elite</h5>
            </div>
            <div className='col-3 col-sm-3'>
                <h5 className='grayclr mb-0'>Premier</h5>
            </div>
            <div className='col-3 col-sm-3'>
                <h5 className='grayclr mb-0'>Master</h5>
            </div>
            <div className='col-3 col-sm-3'>
                <h5 className='grayclr mb-0'>Ultimate</h5>
            </div>

        </div>
        </div>
    )
}

export default Names
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import cardsimg from "../images/spropad/cardsimg.png"
import strokeimg from "../images/spropad/cardimgvector.png"
import binance from "../images/spropad/binance.png";
import topvector from "../images/spropad/topvector.png";
import linecircle from "../images/spropad/linecircle.png"
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getSaleInfo, getSaleInfoCard } from '../Hooks/useContract';
import { useEffect } from 'react';

function Tokencard(props) {
  console.log("paropssss", props);
  // console.log("paropssss22", props?.datas[0]?._sale);


  const navigate = useNavigate()
  const [saledata, setSaledata] = useState([])
  console.log("saledata", saledata);

  useEffect(() => {
    Getallsaledata()
    lockDaysCal()
  }, [])

  const Getallsaledata = async () => {
    console.log("props?.datas" , props?.datas);
    setSaledata(props?.datas)
  }

  

  const lockDaysCal = () => {
    if (parseFloat(saledata?.lpUnlockon) == 0) {
      return 0
    }
    let diffTime = Math.abs(new Date(parseFloat(saledata?.endTime) * 1000).getTime() - new Date(parseFloat(saledata?.lpUnlockon) * 1000).getTime());
    let days = diffTime / (24 * 60 * 60 * 1000);
    console.log("days", days)
    return isNaN(days) ? 0 : days.toFixed(0)
  }


   const isSaleLive = (start, end, isOpen) => {
    try {
        return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
    }
    catch (e) {
        console.log("isSaleLive", e);
    }
  }

 const isUpcoming = (start, end) => {
  try {
      return (Date.now() < (start * 1000));
  }
  catch (e) {
      console.log("isUpcoming", e);
  }
}




  return (

    <>

      {
        // saledata.map((items) => (
        // <>
        //  {console.log("itemmsss",items)}
        <div className='tokencard pt-3 pb-4'>

          <div className='filterdrops'>
            <div className='clippathimg'>
              <img src={saledata?.banner && saledata?.banner} alt={cardsimg} className='img-fluid cardsimg' />
              <div> <img src={topvector} className='img-fluid topvector' />
                <p className='standard whtclr mb-0'>Standard IDO</p>
              </div>
            </div>
          </div>

          <div className='padlr'>
            <div className='d-flex justify-content-between pb-3'>
              <h4 className='whtclr'>{saledata?.name}</h4>
              <img src={binance} className='img-fluid binance' />
            </div>
            <div className='d-flex justify-content-between mt-3 borderbot'>
            </div>
            <div className='d-flex justify-content-between mt-3 borderbot'>
              <p className='grayclr'>Token amount</p>
              <p className='whtsclr'>{saledata?.[8] / 10 ** 18}{" "}{saledata?.symbol}</p>
            </div>
            <div className='d-flex justify-content-between mt-3 borderbot'>
              <p className='grayclr'>Status</p>
              <p className='whtsclr'>                
              {saledata && isSaleLive(saledata.startTime, saledata.endTime, saledata.isPresaleOpen) ?

                <span className="badge badge_live badge_finish">

                  <span className='stats_txt'>Sales Live</span>
                </span> :
                (saledata && isUpcoming(saledata.startTime) ?
                  <span className="badge  badge_finish">


                    <span className='stats_txt'>Upcoming</span>
                  </span> :


                  <span className="badge badge_finish">

                    <span className='stats_txt'>Sales End</span>
                  </span>
                )
              }</p>
            </div>
            <div className='d-flex justify-content-between mt-3 borderbot'>
              <p className='grayclr'>Type</p>
              <p className='whtsclr'>standard IDO</p>
            </div>
            {/* <div className='d-flex justify-content-between mt-3'>
              <p className='grayclr'>Market maker</p>
              <p className='whtsclr'>lorem ipsum</p>
            </div> */}
          </div>
          <img src={linecircle} className='img-fluid linecircle' />
            <Button className='themesbtn mt-3 mb-4 mx-auto' onClick={()=>navigate('/info/'+ saledata?.saleAddress,{ state:saledata })}><span>Explore</span></Button>
        </div>  
      }


    </>

  )
}

export default Tokencard
import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown } from 'react-bootstrap';
import footerlogo from '../images/spropad/footerlogo.png';
import insta from '../images/spropad/insta.png';
import fb from '../images/spropad/fb.png';
import linked from '../images/spropad/linked.png';
import tele from '../images/spropad/tele.png';
import whatsapp from '../images/spropad/whatsapp.png';
import { Link, NavLink } from 'react-router-dom';
import { getsociallinks } from '../landingaction/landing';
import { IMAGE_URL } from '../Config/env';
function Footer() {
    const [links, setlinks] = useState([])
    useEffect(() => {
        getSociallink()
    }, [])
    const getSociallink = async () => {

        var resp = await getsociallinks();
        console.log("setlinks", resp?.data?.data?.data)
        if (resp?.data?.status)
            setlinks(resp?.data?.data?.data);
    }
    return (


        <Container fluid={true}>
            <div className='footers py-2 py-sm-5 mb-2 mb-sm-0'>

                <div className='text-center mt-3 mt-sm-5'>
                    <img src={footerlogo} className='img-fluid footerlogo' alt='images' />
                </div>

                <div className='row mt-2 mt-sm-5 mb-1'>
                    <div className='col-xl-5 col-xxl-4 text-center mx-auto'>
                        <div className='socialicons'>
                        {links.map((sociallink)=>(  
                                       
                                       <>
                                       {
                                          <a href={sociallink.link} target="blank">
                                           
                                          <img src={`${IMAGE_URL}/socialimg/${sociallink?.img}`} alt="dd"/>
                                          </a>
                                       }
                                       </>
                                      ))}
                        </div>
                        <div className='sociallist mt-3 mt-sm-4 mb-1 mb-sm-3 mx-4 mx-sm-5'>
                            <NavLink to="/launchpad">Launchpad</NavLink>
                            <NavLink to="/staking">Staking</NavLink>
                            <NavLink target = "_blank" to="https://spropad.gitbook.io/smart-pro-pad-whitepaper">Whitepaper</NavLink>
                        </div>
                    </div>
                </div>

                <div className='footlast d-block d-sm-flex justify-content-between mx-3 mx-md-5 mx-lg-0 mb-2 mb-sm-5'>
                    <div>
                        <Link to="/privacy" className='graya privacya'>Privacy Policy</Link>
                        <Link to="/terms" className='graya termsa'>Terms & Conditions</Link></div>
                    <Link className='graya copyrighta'>Copyright © 2024 . All rights reserved.




</Link>
                </div>

            </div>
        </Container>
    )
}

export default Footer;
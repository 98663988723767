import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import addressicon from "../images/spropad/addressicon.png";
import shareimg from "../images/spropad/shareimg.png";
import Profilenav from '../Layouts/profilenav';
import { getAccount } from '../Hooks/useAccounts';
import { useNavigate } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';

function Profilepage() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const [sharestep, setSharestep] = useState([
        { id: 1 }, { id: 2 }, { id: 2 },
    ])


    return (

        <div className='all'>
            <Header />
            <Subheaders />

            <div className='innerpages pb-5'>
                <div className='container'>
                    <div className='profileback headtext pos'>
                        <Profilenav />
                        <h1 className='mainhead text-left alltext mb-4'>My <span className='mainsub'> Profile</span></h1>

                        <div className='row pt-4 justify-content-between'>
                            <div className='col-lg-6 d-block d-sm-flex mt-4'>
                                <h4 className='fname w-100 me-3'>Wallet Address</h4>
                                <div>
                                    <p className='subpara mb-4'>Your wallets linked to Smart Pro Pad.</p>
                                    <div className='addressback w300 d-flex align-items-center'>
                                        <img src={addressicon} className='addressicon me-3' />
                                        <div>
                                            <p className='grayclr mb-1' >Main Wallet Address</p>
                                            {/* <p className='whtsclr mb-0'>{`${getAccount()?.substring(0, 5)}...${getAccount()?.substring(38, 42)}`}</p> */}
                                          
                                            <CopyToClipboard text={`${getAccount()}`}>
                                             <div onClick={() => toast.success("Copied!")} className='d-flex cursor'> <p className='whtsclr mb-0'>{`${getAccount()?.substring(0, 5)}...${getAccount()?.substring(38, 42)}`}</p> <FaRegCopy className='copyicon ms-2' /></div> 
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-lg-5 mt-4'>
                                <h4 className='fname w-100 me-3'>Account Information</h4>
                                <div className='d-flex align-items-center mt-3'><p className='grayclr mb-0 me-4'>KYC Status</p><p className='redclr mb-0'>Unverified</p></div>
                                <p className='proj mt-4'>You must stake to achieve Spro Pad Tier before KYC.</p>
                            </div> */}
                        </div>

                        <div className='row mt-5'>
                            <div className='col-md-5'>
                                <h4 className='fname w-100 me-3'>Getting Started</h4>
                                <p className='subpara'>Here are 2 steps for you to start on Smart Pro Pad. </p>
                            </div>
                        </div>

                        <div className='row'>
                            {/* {sharestep.map((e, i) =>
                                
                            )} */}

                            <div className='col-md-9 col-lg-6 col-xl-5 col-xxl-4 mt-4 mx-auto'>
                                <div className='sharecard px-4 px-sm-5 py-4 py-sm-5'>
                                    <img src={shareimg} className='img-fluid shareimg mt-4 mb-3' />
                                    <h5 className='texted mb-4'>step 1</h5>
                                    <h5 className='fname mb-4'>Stake $SPP  for a tier</h5>
                                    <p className='fname fw-300'>Stake $SPP to achieve a tier (Elite Pro, Premier Pro, Master Pro, Ultimate Pro)</p>
                                    <div className='pt-3 d-flex justify-content-center'><Button className='themesbtn' onClick={() => navigate("/staking")}><span>Stake Now</span></Button></div>
                                </div>
                            </div>

                            {/* <div className='col-md-9 col-lg-6 col-xl-5 col-xxl-4 mt-4 mx-auto'>
                                    <div className='sharecard px-4 px-sm-5 py-4 py-sm-5'>
                                        <img src={shareimg} className='img-fluid shareimg mt-4 mb-3'/>
                                        <h5 className='texted mb-4'>step 2</h5>
                                        <h5 className='fname mb-4'>Complete a KYC</h5>
                                        <p className='fname fw-300'>Complete a KYC with ChainGPT Pad via Blockpass to access IDOs</p>
                                        <div className='pt-3 d-flex justify-content-center'><Button className='themesbtn'  ><span>Enter KYC</span></Button></div>
                                    </div>
                                </div> */}

                            <div className='col-md-9 col-lg-6 col-xl-5 col-xxl-4 mt-4 mx-auto'>
                                <div className='sharecard px-4 px-sm-5 py-4 py-sm-5'>
                                    <img src={shareimg} className='img-fluid shareimg mt-4 mb-3' />
                                    <h5 className='texted mb-4'>step 2</h5>
                                    <h5 className='fname mb-4'>Register Interest For IDOs</h5>
                                    <p className='fname fw-300'>To participate in the first round (guaranteed) of any IDO you have to Register Interest, if you have a bronze tier you must participate in a Galxe event to earn allocation in a guaranteed round.</p>
                                    <div className='pt-3 d-flex justify-content-center'><Button className='themesbtn'  ><span>How to join</span></Button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <p className='fname my-5 mx-4 fw-300'>Disclaimer :  the information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. we accept no liability for any loss accessioned  to any person acting     or refraining from action as a result of any material provided or published. </p>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Profilepage;
import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CHAINS, PROJECT_ID } from "../Config/env";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import toast from 'react-hot-toast';
import { sleep } from './useContract'
import { style, iconTheme, position } from './useToast'
var checkToast = true

export const useWeb3 = async () => {
  // console.log("useWeb3", useWeb3);
  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  const NAME = CURRENT_NAME_ID()
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

  let web3;

  if (localStorage.getItem("walletconnect") != null) {
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const ethereum = new WalletConnectProvider({
      rpc: RPC_DATA,
      network: 'binance',
      chainId: CHAIN_ID,
      name: NAME
    }
    );

    await ethereum.enable()
    web3 = new Web3(ethereum);

    //  return web3;
  }
  else if (typeof window.BinanceChain !== 'undefined') {

    web3 = new Web3(window.BinanceChain);
    // return web3;
  }

  else if (localStorage.getItem("accountInfo") != null) {
    web3 = new Web3(window.ethereum);
    //   return web3;
  } else {
    web3 = new Web3(httpProvider);
    //   return web3;
  }

  const chainIds = await web3.eth.getChainId();
  if (parseInt(chainIds) != parseInt(CHAIN_ID)) {
    if (checkToast) {
      checkToast = false
      const data = await SwitchChain();
      if (data) {
        window.location.reload();
      }
    }
  }

  return web3;

}

// export const useWeb3 = async () => {

//   const RPC_URL = CURRENT_RPC_URL();
//   const CHAIN_ID = CURRENT_CHAIN_ID();
//   const httpProvider = new Web3.providers.HttpProvider(RPC_URL)

//   let web3;


//   if (localStorage.getItem("walletConnect") != null) {
//     console.log(localStorage.getItem("walletConnect"), "sessionStorage.getItem")
//     // alert("wallet connect call")
//     const provider = await EthereumProvider.init({
//       projectId: PROJECT_ID, // required
//       chains: [CHAIN_ID], // required
//       showQrModal: true // requires @walletconnect/modal
//     })
//     await provider.enable()
//     web3 = new Web3(provider);

//   }
//   else if (typeof window.BinanceChain !== 'undefined') {
//     console.log(typeof window.BinanceChain, "sessionStorage.getItem1")
//     web3 = new Web3(window.BinanceChain);
//     // return web3;
//   }

//   else if (sessionStorage.getItem("accountInfo") != null) {
//     console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem2")
//     web3 = new Web3(window.ethereum);
//     //   return web3;
//   } else {
//     console.log(sessionStorage.getItem("accountInfo"), "sessionStorage.getItem3")
//     web3 = new Web3(httpProvider);
//     //   return web3;
//   }
//   const chainIds = await web3.eth.getChainId();
//   console.log('SwitchChain___web3',parseInt(chainIds) != parseInt(CHAIN_ID),chainIds,CHAIN_ID)
//   if (parseInt(chainIds) != parseInt(CHAIN_ID)) {
//     console.log('SwitchChain___web3')
//     if (checkToast) {
//       checkToast = false
//       toast.error(`Connected to Wrong Network !`,
//         {
//           // position: position.position,
//           // style: {
//           //   minWidth: '300px',
//           //   minHeight: '55px'
//           // }
//           // iconTheme: iconTheme
//         })

//       const data = await SwitchChain();
//       if (data) {
//         checkToast = true
//         // window.location.reload();
//       }else if(!data){
//         checkToast = true
//       }
//     }

//     // const data = await SwitchChain();
//     // if (data) {
//     //   window.location.reload();
//     // }
//   }

//   return web3;

// }

export const GetChainIndex = () => {

  return localStorage.getItem("CHAIN") ? localStorage.getItem("CHAIN") : "0";
}

export const CURRENT_RPC_URL = () => {
  const index = GetChainIndex()
  return CHAINS[index].RPC_URL;
}

export const CURRENT_CHAIN_ID = () => {
  const index = GetChainIndex()
  return CHAINS[index].CHAIN_ID;
}

export const CURRENT_NAME_ID = () => {
  const index = GetChainIndex()
  return CHAINS[index].NAME;
}

// const SwitchChain = async () => {
//   const RPC_URL = CURRENT_RPC_URL();
//   const CHAIN_ID = CURRENT_CHAIN_ID();
//   // Check if MetaMask is installed
//   // MetaMask injects the global API into window.ethereum
//   const hexString = CHAIN_ID.toString(16);
//   if (window.ethereum) {
//     try {
//       // check if the chain to connect to is installed
//       const data = window.ethereum.request({
//         method: 'wallet_switchEthereumChain',
//         params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
//       });
//       await toast.promise(data, {
//         loading: 'Switching Network ...',
//         success: 'Network Switched Successfully',
//         error: 'Error ! When Switching Network',
//       }, {
//         position: position.position,
//         style: style,
//         iconTheme: iconTheme,


//       },
//         await sleep(1000));
//       return true;
//     } catch (error) {
//         try {
//           const data = window.ethereum.request({
//             method: 'wallet_addEthereumChain',
//             params: [
//               {
//                 chainId: "0x" + hexString,
//                 rpcUrls: [RPC_URL],
//                 chainName: "BSC Testnet",
//                 nativeCurrency: {
//                   name: "BNB",
//                   symbol: "BNB",
//                   decimals: 18
//                 },
//               },
//             ],
//           });
//           await toast.promise(data, {
//             loading: 'Switching Network ...',
//             success: 'Network Switched Successfully',
//             error: 'Error ! When Switching Network',
//           }, {
//             position: position.position,
//             style: style,
//             iconTheme: iconTheme,

//           });
//           return true;
//         } catch (addError) {
//           console.error(addError);
//           toast.error(`Error : ${addError}`,
//             {
//               position: position.position,
//               style: style,
//               iconTheme: iconTheme,
//             });
//         }
//     }
//   } else {
//     // if no window.ethereum then MetaMask is not installed

//     return false;

//   }
// }


const SwitchChain = async () => {
  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = CHAIN_ID.toString(16);
  if (window.ethereum) {
    try {
      // if (checkToast == true){   
      // check if the chain to connect to is installed
      console.log("SwitchChain211")
      const data = window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      // console.log(await data,"SwitchChain211")
      // let CheckToast = localStorage.getItem("SwitchNetwork")
      // console.log(CheckToast,typeof CheckToast,"SwitchNetwork",await data)
      // if(CheckToast !== 'true'){
      // let Toast = localStorage.setItem("SwitchNetwork",true)
      // console.log("Toast",Toast)
      await toast.promise(data, {
        loading: 'Switching Network ...',
        success: 'Network Switched Successfully',
        error: 'Error ! When Switching Network',
      }, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
        success:{
          icon: <i class="fa fa-check"/>,
        }
      });
      // checkToast = false
      return true;
      // }
      // }


    } catch (error) {
      console.log(error,"SwitchChain__err")
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          const data = window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrls: [RPC_URL],
                chainName: "BSC Testnet",
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18
                },
              },
            ],
          });
          await toast.promise(data, {
            loading: 'Switching Network ...',
            success: 'Network Switched Successfully',
            error: 'Error ! When Switching Network',
          }, {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
            success:{
              icon: <i class="fa fa-check"/>,
            }
          });
          return true;
        } catch (addError) {
          console.error(addError);
          toast.error(`Error : ${addError}`,
            {
              position: position.position,
              style: style,
              iconTheme: iconTheme
            });
        }
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    return false;

  }
}


export const UseProvider = async () => {
  const RPC_URL = CURRENT_RPC_URL();
  const CHAIN_ID = CURRENT_CHAIN_ID();
  var provider = window.BinanceChain;

  if (localStorage.getItem("walletconnect") != null) {
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const ethereum = new WalletConnectProvider({
      rpc: RPC_DATA,
      network: 'binance',
      chainId: CHAIN_ID,

    }
    );

    await ethereum.enable()


    return ethereum;
  }
  else if (typeof window.BinanceChain !== 'undefined') {

    return window.BinanceChain;
  }
  else if (localStorage.getItem("accountInfo") != null) {
    return window.ethereum;
  }
}

export const getChainId = () => {
  try {
    const index = localStorage.getItem("CHAIN");
    return index != null ? index : "0";
  }
  catch (err) {
    console.log("getChainId", err)
  }
}
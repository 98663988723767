import React, { useState, useEffect } from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import ellite from "../images/spropad/Elite Pro.png";
import premium from "../images/spropad/Premier Pro.png";
import master from "../images/spropad/Master Pro.png";
import ultimate from "../images/spropad/Ultimate Pro.png";
import { GetAllocation } from "../Hooks/UseBackenduser";
import { API_URL } from '../Config/env';

function Calculator(props) {

   const [allocation , setAllocation] = useState([]);
   const [amount , setAmount] = useState(0);


   useEffect(() => {
      fetchdata();
   } , [])
   const fetchdata = async() => {
      setAmount(parseFloat(props?.saledetail?.whitelistUserAmt)/10**18);
      const Proxydata = await GetAllocation();
      console.log("Proxydata", Proxydata);
      setAllocation(Proxydata?.data?.record);
  }

    return (

        <div>

            <h3 className='fname mt-4 ms-2'>Allocation Calculator</h3>
            <p className='grayclr ms-2 f-16'>The IDO allocation calculator illustrates the distribution for each SP level participating in the IDO. Since the allocation varies between each IDO, we suggest referring to this tab for precise allocation per SP.</p>

            <div className='px-2 ps-sm-2 calculations'>
               
            {/* <div className='row mt-5 mb-3'>
                <div className='col-2 col-sm-3 col-md-3 col-lg-2 col-xl-2'>
                   <h5 className='fname'>Type</h5>  
                </div>
                <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2'>
                   <h5 className='fname'>Multiplier</h5>  
                </div>
                <div className='col-4 col-sm-3 col-md-3 col-lg-4 col-xl-4'>
                   <h5 className='fname'>Percentage</h5>  
                </div>

                <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4'>
                   <h5 className='fname'>Allocation</h5>  
                </div>
            </div>

            {allocation && allocation?.length > 0 && allocation?.map((data , i) => 
            <div className='row mt-2 align-items-center'>
            <div className='col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2'>
               <h5 className='fname'> <img src={API_URL+data?.image} className='img-fluid prosimg' /></h5>  
               <p className='grayclr'>{data?.name}</p>
            </div>
            <div className='col-4 col-sm-3 col-md-3 col-lg-3 col-xl-2'>
           
               <p className='grayclr'>{data?.multiplier}X</p>
            </div>
            <div className='col-4 col-sm-3 col-md-3 col-lg-4 col-xl-4'> 
               <p className='grayclr'>{parseFloat(data?.percentage).toFixed(4)} %</p>
            </div>
            <div className='col-4 col-sm-3 col-md-3 col-lg-3 col-xl-4'> 
               <p className='grayclr'>$  {(((amount*parseFloat(data?.percentage))/100)/(parseFloat(props?.saledetail?.presaleRate)/10**18)).toFixed(4)} </p>
            </div>
        </div>
         )} */}

          <Table responsive>
            <thead>
               <tr>
                  <th>   <h5 className='fname'>Type</h5>  </th>
                  <th>   <h5 className='fname'>Multiplier</h5>  </th>
                  <th> <h5 className='fname'>Percentage</h5>  </th>
                  <th>   <h5 className='fname'>Allocation</h5>  </th>
               </tr>
            </thead>
            <tbody>
            {allocation && allocation?.length > 0 && allocation?.map((data , i) => 
               <tr>
                  <td>
                     <div>
                     <h5 className='fname'> <img src={API_URL+data?.image} className='img-fluid prosimg' /></h5>  
                     <p className='grayclr'>{data?.name}</p>
                     </div>
                  </td>
                  <td>     <p className='grayclr'>{data?.multiplier}X</p></td>
                  <td> <p className='grayclr'>{parseFloat(data?.percentage).toFixed(4)} %</p></td>
                  <td>    <p className='grayclr'>$  {(((amount*parseFloat(data?.percentage))/100)/(parseFloat(props?.saledetail?.presaleRate)/10**18)).toFixed(4)} </p></td>
               </tr> )}
            </tbody>
          </Table>









            {/* <div className='row mt-2 align-items-center'>
                <div className='col-4 col-sm-4 col-md-4'>
                   <h5 className='fname'> <img src={premium} className='img-fluid prosimg' /></h5>  
                   <p className='grayclr'>Premier Pro</p>
                </div>
                <div className='col-4 col-sm-4 col-md-4'>
               
                   <p className='grayclr'>4x</p>
                </div>
                <div className='col-4 col-sm-4 col-md-4'> 
                   <p className='grayclr'>$2.97</p>
                </div>
            </div>
            <div className='row mt-2 align-items-center'>
                <div className='col-4 col-sm-4 col-md-4'>
                   <h5 className='fname'> <img src={master} className='img-fluid prosimg' /></h5>  
                   <p className='grayclr'>Master Pro</p>
                </div>
                <div className='col-4 col-sm-4 col-md-4'>
               
                   <p className='grayclr'>10x</p>
                </div>
                <div className='col-4 col-sm-4 col-md-4'> 
                   <p className='grayclr'>$4.97</p>
                </div>
            </div>
            <div className='row mt-2 align-items-center'>
                <div className='col-4 col-sm-4 col-md-4'>
                   <h5 className='fname'> <img src={ultimate} className='img-fluid prosimg' /></h5>  
                   <p className='grayclr'>Ultimate Pro</p>
                </div>
                <div className='col-4 col-sm-4 col-md-4'>
               
                   <p className='grayclr'>40x</p>
                </div>
                <div className='col-4 col-sm-4 col-md-4'> 
                   <p className='grayclr'>$10.97</p>
                </div>
            </div> */}
            </div>

        </div>
    )
}

export default Calculator;
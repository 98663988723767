import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Container, Button, Tab, Nav, Col, Row, Modal } from 'react-bootstrap';
import { BuyNativeToken, approveContractbuynative, calculateBuy, calculateNativeBuy, checkIsApproved, toFixedNumber } from '../Hooks/useContract';
import { CHAINS, NATIVETOKEN_SALE } from '../Config/env';
import { getAccount, getChainId } from '../Hooks/useAccounts';
import { Checkaddressnative } from '../Hooks/UseNativesale';



function Buyflowmodal(props) {

    const[buysflow,setBuysflow] = useState(true);
    const [Max, setMax] = useState(0);
    const [ismax , setIsmax] = useState(false);
    const [claim , setClaim] = useState(0);
    const [error , setError] = useState("");
    const [approvebutton , setApprovebutton] = useState(false);
    





    // const MaximumBnb = async () => {
    //     setMax((userdata?.bnbBalance / 10 ** 18).toFixed(4));
    //     setIsmax(true);
    // }

    useEffect(() => {
        settokenaddress()
    } , [])

    const settokenaddress = async (value) => {
        // setTokenaddress(value);
        let approv = await checkIsApproved(getAccount() , CHAINS[getChainId()].USDTADDRESS , props?.saledetail?.saleAddress)
        setApprovebutton(approv);
    }

    const calculateTokem = async(value) => {
        if(parseFloat(value) > 0){
            const tokenValue = await calculateNativeBuy(props?.saledetail?.saleAddress,value);
            setClaim(toFixedNumber(parseFloat(tokenValue)/10**18));
            setError("")
        }
        else{
            // toast.error("Invalid Value")
            setError("Invalid Value")
        }
    }

    const Buy = async() => {
        console.log("max" , Max , props?.saledetail?.minEthLimit);
        if((parseFloat(props?.saledetail?.minEthLimit) /10**18)<= parseFloat(Max)  && (parseFloat(props?.saledetail?.maxEthLimit)/10**18) >= parseFloat(Max)){
            await BuyNativeToken(props?.saledetail?.saleAddress, Max, false, Max, getAccount(), 18 , "USDT");
            
        }
        else{
            toast.error("You can't buy this limit");
        }
    }

    const approvetoken = async() => {
        await approveContractbuynative(localStorage.getItem("accountInfo"),CHAINS[getChainId()].USDTADDRESS,props?.saledetail?.saleAddress);
        setApprovebutton(true);
      }

    return (
                     
        <div className='walletmodal'>

            <Modal show={buysflow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>




            <Modal.Header closeButton className='mt-5' onClick={() => props.onDismiss()}></Modal.Header>

<Modal.Body className='px-3 px-sm-5 stakemodals'>
<h1 className='mainhead text-center alltext mb-4'>Native<span className='mainsub'>Sale</span></h1>

<div className='row'>
    <div className='col-lg-10 col-xl-10 col-xxl-10 mx-auto my-4'>
    <div className='swapbalance px-3 py-3'>
                            <div className='pos docback'>
                                <p className='fname max mb-0'>USDT </p>
                                <input type='number' value={Max && Max} className='form-control pr120' onChange={(e) => {
                                    setMax(e?.target?.value);
                                    calculateTokem(e?.target?.value)
                                }}/>
                            </div>
                            <p className='text-danger'>{error}</p>
                            <div className='d-flex justify-content-between mt-3'>
                                <div><p className='fname mb-2'>You will get approximately</p></div>
                                <p className='grayclr f-16'>{claim} SPP</p>
                            </div>
                            <p className='fname f-16 mt-3'>You need to Approve first before purchasing. Please set a sufficient spending cap or use the default value.</p>
                            <div className='d-flex align-items-center justify-content-center pt-2'>
                                    {/* <Button className='themesbtn' disabled><span>Approved</span></Button> */}
                                    { approvebutton ? <Button className='themesbtn ms-3' disabled = {!Max}
                                    onClick={Buy}
                                    ><span>BUY</span></Button>
                                 : <Button className='themesbtn ms-3' disabled = {!Max}
                                 onClick={approvetoken}
                                 ><span>Approve</span></Button>
                                }
                                </div>
                    
                        </div>
                        </div>
                        </div>
</Modal.Body>
            </Modal>


        </div>

    )
}

export default Buyflowmodal;



export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '5px solid #9F4DDA',
    padding: '16px',
    color: '#000',  
    fontWeight:'700',
}

export const iconTheme = {
    primary: '#000',
    secondary: '#fff',
}

export const  position= {
    position: "top-center"
}
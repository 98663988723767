const BASE_URL = 'cms';
const launch_URL = 'launchpad'
const user = "user"


export const cmslists = BASE_URL + "/cmslists";

export const RegisterAccountListFront = BASE_URL + "/RegisterAccountListFront";

export const sprofeatureListFront = BASE_URL + "/sprofeatureListFront";
export const Faqtablist = BASE_URL + "/Faqtablist";

export const FaqhideList = BASE_URL + "/FaqhideList";

export const getSocialLinksFront = BASE_URL + "/getSocialLinksFront";
// addtokenrequest
export const addtokenrequest = BASE_URL + "/addtokenrequest";
// get-allocation
export const getallocation = user + "/get-allocation";
